import React from "react";
import CustomHeader from "../../containers/header";
import CustomFooter from "../../containers/footer";
import $ from "jquery";
import MenuLeft from "../../containers/menu-left";

class UserBilling extends React.Component {

    constructor(props) {

        super(props);

        this.handleInput = this.handleInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.props.actionUserBillingGet(this.props.user.token);

        let initialFormState = this.props.userBilling.payload;

        this.state = {
            form: initialFormState || {}
        };

    }

    handleInput(e) {
        const key = e.target.name;
        const value = e.target.value;
        let newState = JSON.parse(JSON.stringify(this.state));
        newState.form[key] = value;
        this.setState(newState);
    }

    handleSubmit(e) {
        e.preventDefault();
        const formData = JSON.stringify(this.state.form);
        this.props.actionUserBillingUpdate(formData);
    }

    renderChapter() {

        let { userBilling } = this.props;

        return (
            <div className="wrapper-chapter-grey">
                <div className="icon-heading">
                    <h2>Mitt konto</h2>
                </div>
                <div className="white-canvas">
                    {userBilling && userBilling.isFetched ?
                        <Content userBilling={userBilling}
                                 handleSubmit={this.handleSubmit}
                                 handleInput={this.handleInput}
                                 values={this.state.form}
                        /> :
                        <ContentLoading/>
                    }
                </div>
            </div>
        )

    }

    render() {

        return (
            <div className="wrapper-main">
                <CustomHeader />
                <div className="wrapper-content">
                    <MenuLeft />
                    {this.renderChapter()}
                </div>
                <CustomFooter />
            </div>
        );

    }

}


class ContentLoading extends React.Component {

    render() {
        return (
            <div className="user-profile-content">
                <h2 className="user-profile-header">Loading...</h2>
            </div>
        )
    }

}

class Content extends React.Component {

    componentDidMount() {
        $('[data-toggle="tooltip"]').tooltip({
            trigger: "hover"
        });
    }

    render() {

        let { userBilling, handleSubmit, handleInput, values } = this.props;

        return (
            <div className="user-profile-content">

                <h2 className="user-profile-header">
                    Faktureringsuppgifter
                </h2>

                <form className="form-profile" onSubmit={handleSubmit}>
                    <table className="form-profile-table">
                        <tbody>

                            <TableRowField title="Förnamn" id="firstName"
                                           values={values}
                                           handleInput={handleInput}/>
                            <TableRowField title="Efternamn" id="lastName"
                                           values={values}
                                           handleInput={handleInput}/>
                            <TableRowField title="Organisationsnummer" id="organizationNumber"
                                           values={values}
                                           handleInput={handleInput}/>
                            <TableRowField title="Organisationsnamn" id="organizationName"
                                           values={values}
                                           handleInput={handleInput}/>
                            <TableRowField title="Fakturareferens" id="invoiceReference"
                                           values={values}
                                           handleInput={handleInput}/>
                            <TableRowField title="Address" id="address"
                                           values={values}
                                           handleInput={handleInput}/>
                            <TableRowField title="Postnummer" id="postalCode"
                                           values={values}
                                           handleInput={handleInput}/>
                            <TableRowField title="Ort" id="city"
                                           values={values}
                                           handleInput={handleInput}/>
                            <TableRowField title="Land" id="country"
                                           values={values}
                                           handleInput={handleInput}/>
                        </tbody>
                    </table>
                    <button className="btn btn-sm btn-green form-btn">
                        Spara
                    </button>
                    {userBilling.isSaved && (
                        <div className="alert alert-success alert-dismissable" style={alwaysVisible}>
                            Dina uppgifter har sparats
                        </div>
                    )}
                    {userBilling.error && (
                        <div className="alert alert-danger alert-dismissable" style={alwaysVisible}>
                            Ett fel inträffade
                        </div>
                    )}
                </form>

            </div>
        )

    }

}

class TableRowField extends React.Component {

    render() {
        let {title, id, values, handleInput} = this.props;

        return (
            <tr>
                <td>{title}</td>
                <td>
                    <input name={id}
                           onChange={handleInput}
                           value={values[id]}
                           className="form-control"/>
                </td>
            </tr>
        )
    }

}

const alwaysVisible = {display: "block"};

export default UserBilling;