import { connect } from 'react-redux'
import * as actions from '../../../controller/actions/user-billing'
import UserBilling from '../../components/user/user-billing'

const mapStateToProps = state => ({
    user: state.reducerUser,
    userBilling: state.reducerUserBilling
});

const mapDispatchToProps = {
    actionUserBillingGet: actions.actionUserBillingGet,
    actionUserBillingUpdate: actions.actionUserBillingUpdate,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserBilling)
