import * as constants from "../constants";
import api from "../api";

export function actionUserSubscriptionGet() {

    return dispatch => {

        dispatch({
            type: constants.USER_SUBSCRIPTION_GET_REQUEST
        });

        return api.userSubscriptionGet().then(response => {
            if (response.ok) {
                dispatch({
                    type: constants.USER_SUBSCRIPTION_GET_SUCCESS,
                    payload: response.data
                })
            } else {
                dispatch({
                    type: constants.USER_SUBSCRIPTION_GET_FAILURE,
                    payload: response.data
                })
            }
        })

    }

}

export function actionUserSubscriptionUpdate(data) {

    return dispatch => {

        dispatch({
            type: constants.USER_SUBSCRIPTION_UPDATE_REQUEST
        });

        return api.userSubscriptionUpdate(data).then(response => {
            if (response.ok) {
                dispatch({
                    type: constants.USER_SUBSCRIPTION_UPDATE_SUCCESS,
                    payload: response.data
                });
                dispatch(actionUserSubscriptionGet());
            } else {
                dispatch({
                    type: constants.USER_SUBSCRIPTION_UPDATE_FAILURE,
                    payload: response.data
                })
            }
        })

    }

}

export function actionUserSubscriptionTerminate(data) {

    return dispatch => {

        dispatch({
            type: constants.USER_SUBSCRIPTION_TERMINATE_REQUEST
        });

        return api.userSubscriptionTerminate(data).then(response => {
            if (response.ok) {
                dispatch({
                    type: constants.USER_SUBSCRIPTION_TERMINATE_SUCCESS,
                    payload: response.data
                })
            } else {
                dispatch({
                    type: constants.USER_SUBSCRIPTION_TERMINATE_FAILURE,
                    payload: response.data
                })
            }
        })

    }

}

export function actionUserSubscriptionOrderGet() {

    return dispatch => {

        dispatch({
            type: constants.USER_SUBSCRIPTION_ORDER_GET_REQUEST
        });

        return api.userSubscriptionOrderGet().then(response => {
            if (response.ok) {
                dispatch({
                    type: constants.USER_SUBSCRIPTION_ORDER_GET_SUCCESS,
                    payload: response.data
                })
            } else {
                dispatch({
                    type: constants.USER_SUBSCRIPTION_ORDER_GET_FAILURE,
                    payload: response.data
                })
            }
        })

    }

}