import React from "react";
import CustomHeader from "../../containers/header";
import CustomFooter from "../../containers/footer";
import MenuLeft from "../../containers/menu-left";

class UserProfile extends React.Component {

    constructor(props) {

        super(props);

        this.handleInput = this.handleInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.props.actionUserProfileGet(this.props.user.token);

        console.log(this.props.userProfile);

        let initialFormState = this.props.userProfile.payload;

        this.state = {
            form: initialFormState || {}
        };

    }

    componentDidMount() {

    }

    handleInput(e) {
        const key = e.target.name;
        const value = e.target.value;
        let newState = JSON.parse(JSON.stringify(this.state));
        newState.form[key] = value;
        this.setState(newState);
    }

    handleSubmit(e) {
        e.preventDefault();
        /*const formData = JSON.stringify(this.state.form);
        this.props.actionUserProfileUpdate(formData);*/
    }

    renderChapter() {

        let { userProfile } = this.props;

        return (
            <div className="wrapper-chapter-grey">
                <div className="icon-heading">
                    <h2>Mitt konto</h2>
                </div>
                <div className="white-canvas">
                    {userProfile && userProfile.isFetched ?
                        <Content userProfile={userProfile}
                                 handleSubmit={this.handleSubmit}
                                 handleInput={this.handleInput}
                                 values={this.state.form}
                        /> :
                        <ContentLoading/>
                    }
                </div>
            </div>
        )
    }

    render() {

        return (
            <div className="wrapper-main">
                <CustomHeader />
                <div className="wrapper-content">
                    <MenuLeft />
                    {this.renderChapter()}
                </div>
                <CustomFooter />
            </div>
        );

    }

}


class ContentLoading extends React.Component {

    render() {
        return (
            <div className="user-profile-content">
                <h2 className="user-profile-header">Loading...</h2>
            </div>
        )
    }

}

class Content extends React.Component {

    render() {

        let { handleSubmit, handleInput, values } = this.props;

        return (
            <div className="user-profile-content">

                <h2 className="user-profile-header">Kontouppgifter</h2>

                <form className="form-profile" onSubmit={handleSubmit}>
                    <table className="form-profile-table">
                        <tbody>

                        <TableRowField title="Email" id="email"
                                       values={values}
                                       handleInput={handleInput}
                                       disabled={true} />
                        <TableRowField title="Registrerad sedan" id="date_joined"
                                       values={values}
                                       handleInput={handleInput}
                                       disabled={true}
                                       isDateFormat={true} />
                        <TableRowField title="Senaste inloggning" id="last_login"
                                       values={values}
                                       handleInput={handleInput}
                                       disabled={true}
                                       isDateFormat={true} />
                        </tbody>
                    </table>
                    {/*<button className="btn btn-sm btn-green form-btn">
                        Spara
                    </button>
                    {userProfile.isSaved && (
                        <div className="alert alert-success alert-dismissable" style={alwaysVisible}>
                            Dina uppgifter har sparats
                        </div>
                    )}
                    {userProfile.error && (
                        <div className="alert alert-danger alert-dismissable" style={alwaysVisible}>
                            Ett fel inträffade
                        </div>
                    )}*/}
                </form>

            </div>
        )

    }

}

class TableRowField extends React.Component {

    render() {

        let {title, id, values, handleInput, isDateFormat} = this.props;

        let value = "";

        if(isDateFormat) {
            value = new Date(values[id]).toLocaleDateString()
        } else {
            value = values[id];
        }

        return (
            <tr>
                <td>{title}</td>
                <td>
                    <input name={id}
                           onChange={handleInput}
                           value={value}
                           className="form-control"
                           disabled={this.props.disabled || false} />
                </td>
            </tr>
        )
    }

}

export default UserProfile;