import React from "react";
import CustomHeader from "../../containers/header";
import CustomFooter from "../../containers/footer";
import { Link, NavLink } from "react-router-dom";
import $ from "jquery";
import MenuLeft from "../../containers/menu-left";

class Companies extends React.Component {

  constructor(props) {
    super(props);
    this.handleCreateCompany = this.handleCreateCompany.bind(this);
    this.props.actionCompaniesGet();
  }

  componentDidMount() {

    $('[data-toggle="tooltip"]').tooltip({
      trigger: "hover"
    });

  }

  handleCreateCompany(e) {
    e.preventDefault();
  }

  renderChapter() {

    let { companies, accountings } = this.props;

    return (
      <div className="wrapper-chapter-grey">
        <div className="company-wrapper">
          <div className="icon-heading">
            <h2>
              Bokslut
              <img
                  className="action-icon"
                  src={process.env.PUBLIC_URL + "/images/icons/info-button.svg"}
                  data-toggle="tooltip"
                  data-placement="bottom"
                  data-html="true"
                  title={" Lägg till ett företag och ladda upp data (SIE-fil). <br /> Här kan du se och ändra direkt i tabellen, utan att din bokföring påverkas."}
                  alt=""
              />
            </h2>
          </div>
          {companies.isFetched ? (
              <Company
                  actionCompanyDelete={this.props.actionCompanyDelete}
                  actionCompanyEdit={this.props.actionCompanyEdit}
                  actionAccountingDelete={this.props.actionAccountingDelete}
                  companies={companies.payload}
                  accountings={accountings.payload}
              />
          ) : (
              <div className="white-canvas">
                <div className="company-information-holder">
                  <p className="no-company">Det finns inga företag</p>
                </div>
              </div>
          )}
        </div>
        <AddCompany actionCompanyAdd={this.props.actionCompanyAdd} />
      </div>
    )

  }

  render() {

    return (
      <div className="wrapper-main">
        <CustomHeader />
        <div className="wrapper-content">
          <MenuLeft />
          {this.renderChapter()}
        </div>
        <CustomFooter />
      </div>
    );
  }
}

class Company extends React.Component {

  constructor(props) {
    super(props);
    this.handleCompanyDelete = this.handleCompanyDelete.bind(this);
    this.handleCompanyEdit = this.handleCompanyEdit.bind(this);
    this.handleCompanySave = this.handleCompanySave.bind(this);

    let editCompanyTitle = this.getInitialState();

    this.state = {
      editCompanyTitle: editCompanyTitle
    };
  }

  getInitialState() {
    let initialState = {};

    if (this.props.companies === null) {
      return initialState;
    } else {
      for (let key in this.props.companies) {
        initialState[key] = true;
      }
    }

    return initialState;
  }

  handleCompanyDelete(company) {

    let result = window.confirm("Är du säker på att du vill ta bort detta företag?");

    if (result) {
      this.props.actionCompanyDelete({
        pk: company.pk
      });
    }

  }


  handleCompanyEdit(company) {
    let newState = JSON.parse(JSON.stringify(this.state));
    newState.editCompanyTitle[company.pk] = !this.state.editCompanyTitle[
      company.pk
    ];
    this.setState(newState);
  }

  handleCompanySave(e, company) {
    let newCompany = JSON.parse(JSON.stringify(company));

    if (e.key === "Enter") {
      newCompany.name = e.target.value;
      this.props.actionCompanyEdit(newCompany);
    }
  }

  render() {
    let html = [];

    if (this.props.companies === null) {
      return <div>ERROR: Företagen kunde inte laddas in.</div>;
    }

    for (let key in this.props.companies) {
      if (this.props.companies.hasOwnProperty(key)) {
        let company = this.props.companies[key];

        html.push(
          <div key={key} className="white-canvas">
            <div className="company-information-holder">
              <div className="company-title">
                <input
                  placeholder={company.name}
                  name={"company-" + company.pk}
                  type="text"
                  className="formField company-title-field"
                  disabled={this.state.editCompanyTitle[key]}
                  onKeyPress={e => this.handleCompanySave(e, company)}
                />
                <button
                  className="btn btn-icon"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Redigera företag"
                >
                  <img
                    className="action-icon"
                    onClick={() => this.handleCompanyEdit(company)}
                    src={process.env.PUBLIC_URL + "/images/icons/edit-icon.svg"}
                    alt=""
                  />
                </button>
                <button
                  className="btn btn-icon"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Radera företag"
                >
                  <img
                    className="action-icon"
                    onClick={() => this.handleCompanyDelete(company)}
                    src={
                      process.env.PUBLIC_URL + "/images/icons/trash-icon.svg"
                    }
                    alt=""
                  />
                </button>
              </div>
              <div className="accounting-container">
                <table id="accounting-table">
                  <thead>
                    <tr>
                      <th>Bokslutsnamn</th>
                      <th className="period-col">Period startdatum</th>
                      <th className="period-col">Period slutdatum</th>
                    </tr>
                  </thead>
                  <tbody>
                    <Accountings
                      accountings={this.props.accountings}
                      company={company}
                      actionAccountingDelete={this.props.actionAccountingDelete}
                    />
                  </tbody>
                </table>
                <div>
                  <NavLink to={"/accounting/create/" + company.pk}>
                    <button className="btn btn-sm btn-green new-accounting">
                      Nytt bokslut
                    </button>
                  </NavLink>
                  <NavLink to={"/accounting/import/" + company.pk}>
                    <button className="btn btn-sm btn-green new-accounting ml-10">
                      Importera bokslut
                    </button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }

    return html;
  }
}

class Accountings extends React.Component {
  constructor(props) {
    super(props);
    this.handleAccountingDelete = this.handleAccountingDelete.bind(this);
  }

  handleAccountingDelete(accounting) {

    let result = window.confirm("Är du säker på att du vill ta bort detta bokslut?");

    if (result) {
      this.props.actionAccountingDelete(accounting);
    }

  }

  render() {
    if (this.props.accountings === null) {
      return (
        <tr>
          <td colSpan="4">Det här företaget saknar bokslut</td>
        </tr>
      );
    }

    let companyPK = this.props.company.pk;
    let accountings = {};

    // filter out own accountings
    for (let key in this.props.accountings) {
      let accounting = this.props.accountings[key];
      if (accounting.company === companyPK) {
        accountings[key] = accounting;
      }
    }

    let html = [];

    for (let key in accountings) {
      let accounting = accountings[key];

      html.push(
        <tr key={key}>
          <td className="text-col">{accounting.name}</td>
          <td className="period-col">{accounting.periodStart}</td>
          <td className="period-col">{accounting.periodEnd}</td>
          <td className="icon-col">
            <Link to={"/accounting/edit/" + accounting.pk}>
              <button className="btn btn-icon">
                <img className="action-icon" alt=""
                     src={process.env.PUBLIC_URL + "/images/icons/edit-icon.svg"}
                />
              </button>
            </Link>
            <button
              className="btn btn-icon ml-10"
              data-toggle="tooltip"
              data-placement="top"
              title="Radera bokslut"
              onClick={() => this.handleAccountingDelete(accounting)}
            >
              <img className="action-icon" alt=""
                   src={process.env.PUBLIC_URL + "/images/icons/trash-icon.svg"}
              />
            </button>
          </td>
        </tr>
      );
    }

    return html;
  }
}

class AddCompany extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      newCompanyName: ""
    };
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.props.actionCompanyAdd({
      name: this.state.newCompanyName
    });

    this.setState({
      newCompanyName: ""
    });
  }

  render() {
    return (
      <div key="new-company" className="company-container">
        <div className="new-company-container">
          <form onSubmit={this.handleSubmit}>
            <input
              name="newCompanyName"
              type="text"
              placeholder="Ange företagsnamn"
              className="form-control new-company-field"
              value={this.state.newCompanyName}
              onChange={e => this.handleChange(e)}
            />
            <button className="btn btn-sm btn-green ml-10">
              Lägg till företag
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default Companies;
