import React from "react";
import CustomHeader from "../../containers/header";
import CustomFooter from "../../containers/footer";
import $ from "jquery";
import { Redirect } from "react-router-dom";
import MenuLeft from "../../containers/menu-left";

class AccountingImport extends React.Component {
  constructor(props) {
    super(props);

    this.uploadFile = this.uploadFile.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);

    this.state = {
      file: null
    };

    this.props.actionUserSubscriptionGet();

  }

  renderChapter() {

    return (
        <div className="wrapper-chapter">
          <div className="chapter-content import-page-container">
            <div className="icon-heading">
              <h2>Importera bokslut</h2>
            </div>
            <p>Välj en SIE-fil för att importera bokslut till ditt företag</p>
            <div className="upload-container">
              <input
                  className="inputfile"
                  type="file"
                  id="myFile"
                  name="myFile"
                  onChange={this.handleFileChange}
              />
              <label
                  htmlFor="myFile"
                  className="btn btn-default btn-sm btn-upload"
              >
                Välj fil...
              </label>
              <span id="upload-placeholder">Ingen fil har valts</span>
            </div>
            <div className="upload-btn-container">
              <button
                  className="btn btn-green btn-sm"
                  onClick={this.uploadFile}
              >
                Ladda upp
              </button>
              {/*<button className="btn btn-default btn-sm btn-cancel">
                Avbryt
              </button>*/}
              <div className="alert alert-danger alert-dismissable">
                Filuppladdningen misslyckades. Var vänlig välj en fil.
              </div>
            </div>
          </div>
        </div>
    )

  }

  render() {
    let { accountings } = this.props;

    if (accountings.redirect === true) {
      this.props.actionsAccountingReset();
      return <Redirect to="/accounting" />;
    }

    return (
      <div className="wrapper-main">
        <CustomHeader />
        <div className="wrapper-content">
          <MenuLeft />
          {this.renderChapter()}
        </div>
        <CustomFooter />
      </div>
    );
  }

  uploadFile() {
    let file = document.getElementById("myFile").files[0];

    if (file) {
      let data = new FormData();
      data.append("file", file);

      delete data.pk;

      this.props.actionAccountingImport({
        data: data,
        companyID: this.props.match.params.companyID
      });
    } else {
      $(".alert").show();
    }
  }

  handleFileChange(e) {
    document.getElementById("upload-placeholder").innerText =
      e.target.files[0].name;

    this.setState({
      file: e.target.files[0]
    });
  }
}

export default AccountingImport;
