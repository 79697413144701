import * as constants from "../constants";
import api from "../api";

/* companies */

export function actionCompaniesGet() {
  return dispatch => {
    dispatch({
      type: constants.COMPANIES_GET_REQUEST
    });

    return api.companiesGet(null).then(response => {
      if (response.ok) {
        // convert data
        let payloadCompanies = {};
        response.data.forEach(function(element) {
          payloadCompanies[element.pk] = element;
        });

        dispatch({
          type: constants.COMPANIES_GET_SUCCESS,
          payload: payloadCompanies
        });
        let companies = response.data;
        companies.map((company, i) => {
          return dispatch(actionAccountingsGet(company));
        });
      } else {
        dispatch({
          type: constants.COMPANIES_GET_FAILURE,
          payload: response.data
        });
      }
    });
  };
}

export function actionCompanyAdd(data) {
  return dispatch => {
    dispatch({
      type: constants.COMPANY_ADD_REQUEST
    });

    return api.companyAdd(data).then(response => {
      if (response.ok) {
        let company = response.data;
        dispatch({
          type: constants.COMPANY_ADD_SUCCESS,
          payload: {
            [company.pk]: company
          }
        });
      } else {
        dispatch({
          type: constants.COMPANY_ADD_FAILURE,
          payload: response.data
        });
      }
    });
  };
}

export function actionCompanyDelete(company) {
  return dispatch => {
    dispatch({
      type: constants.COMPANY_DELETE_REQUEST
    });

    return api.companyDelete(company).then(response => {
      if (response.ok) {
        dispatch({
          type: constants.COMPANY_DELETE_SUCCESS,
          payload: company
        });
      } else {
        dispatch({
          type: constants.COMPANY_DELETE_FAILURE,
          payload: response.data
        });
      }
    });
  };
}

export function actionCompanyEdit(company) {
  return dispatch => {
    dispatch({
      type: constants.COMPANY_EDIT_REQUEST
    });

    return api.companyEdit(company).then(response => {
      if (response.ok) {
        dispatch({
          type: constants.COMPANY_EDIT_SUCCESS,
          payload: company
        });
      } else {
        dispatch({
          type: constants.COMPANY_EDIT_FAILURE,
          payload: response.data
        });
      }
    });
  };
}

/* accountings */

export function actionAccountingsGet(company) {
  return dispatch => {
    dispatch({
      type: constants.ACCOUNTINGS_GET_REQUEST
    });

    return api.accountingsGet(company.pk).then(response => {
      if (response.ok) {
        dispatch({
          type: constants.ACCOUNTINGS_GET_SUCCESS,
          payload: response.data
        });
        dispatch(actionAccountingDataGet(response));
      } else {
        dispatch({
          type: constants.ACCOUNTINGS_GET_FAILURE,
          payload: response.data
        });
      }
    });
  };
}

export function actionAccountingCreate(data) {
  return dispatch => {
    dispatch({
      type: constants.ACCOUNTING_CREATE_REQUEST
    });

    return api.accountingCreate(data).then(response => {
      if (response.ok) {
        dispatch({
          type: constants.ACCOUNTING_CREATE_SUCCESS,
          payload: response.data
        });
      } else {
        dispatch({
          type: constants.ACCOUNTING_CREATE_FAILURE,
          payload: response.data
        });
      }
    });
  };
}

export function actionAccountingReset() {
  return dispatch => {
    dispatch({
      type: constants.ACCOUNTING_RESET
    });
  };
}

export function actionAccountingImport(data) {
  return dispatch => {
    dispatch({
      type: constants.ACCOUNTING_IMPORT_REQUEST
    });

    return api.accountingImport(data).then(response => {
      if (response.ok) {
        dispatch({
          type: constants.ACCOUNTING_IMPORT_SUCCESS,
          payload: response.data
        });
      } else {
        dispatch({
          type: constants.ACCOUNTING_IMPORT_FAILURE,
          payload: response.data
        });
      }
    });
  };
}

export function actionAccountingDelete(accounting) {
  return dispatch => {
    dispatch({
      type: constants.ACCOUNTING_DELETE_REQUEST
    });

    return api.accountingDelete(accounting).then(response => {
      if (response.ok) {
        dispatch({
          type: constants.ACCOUNTING_DELETE_SUCCESS,
          payload: accounting
        });
        dispatch({
          type: constants.ACCOUNTING_DATA_DELETE_SUCCESS,
          payload: accounting
        });
      } else {
        dispatch({
          type: constants.ACCOUNTING_DELETE_FAILURE,
          payload: response.data
        });
      }
    });
  };
}

export function actionAccountingEdit(data) {
  return dispatch => {
    dispatch({
      type: constants.ACCOUNTING_EDIT_REQUEST
    });

    return api.accountingEdit(data).then(response => {
      if (response.ok) {
        dispatch({
          type: constants.ACCOUNTING_EDIT_SUCCESS,
          payload: response.data
        });
      } else {
        dispatch({
          type: constants.ACCOUNTING_EDIT_FAILURE,
          payload: response.data
        });
      }
    });
  };
}

export function actionAccountingDataGet(response) {
  return dispatch => {
    dispatch({
      type: constants.ACCOUNTING_DATA_GET_REQUEST
    });

    let accountings = response.data;

    return accountings.map((accounting, i) => {
      return api.accountingDataGet(accounting).then(response => {
        if (response.ok) {
          dispatch({
            type: constants.ACCOUNTING_DATA_GET_SUCCESS,
            payload: {
              [accounting.pk]: response.data
            }
          });
        } else {
          dispatch({
            type: constants.ACCOUNTING_DATA_GET_FAILURE,
            payload: response.data
          });
        }
      });
    });
  };
}
