import React from "react";
import CustomHeader from "../header";
import CustomFooter from "../footer";
import MenuLeft from "../menu-left";

class Contact extends React.Component {
  render() {
    return (
      <div className="wrapper-main">
        <CustomHeader />
        <div className="wrapper-content">
          <MenuLeft />
          {this.renderChapter()}
        </div>
        <CustomFooter />
      </div>
    );
  }

  renderChapter() {
    return (
        <div className="page-chapters">
          <div className="wrapper-chapter">
            <div className="chapter-content">

              <h2>Kontakta oss</h2>
              <p className="h2-subheader">
                Låter någon av våra tjänster intressant och du vill veta mer?
                Kontakta oss gärna!
              </p>

              <div className="contact-container">

                <div className="contact-item">

                  <div>
                    <img className="contact-icon"
                         src={process.env.PUBLIC_URL + "/images/icons/phone-circle.svg"}
                         alt="" />
                    <h4>Telefon</h4>
                  </div>

                  <div>
                    <a href="tel:+46705435031">070-5435031</a>
                  </div>

                </div>


                <div className="contact-item">

                  <div>
                    <img className="contact-icon"
                         src={process.env.PUBLIC_URL + "/images/icons/mail-circle.svg"}
                         alt="" />
                    <h4>Mail</h4>
                  </div>

                  <div>
                    <a href="mailto:support@umesee.se">support@umesee.se</a>
                    <a href="mailto:info@umesee.se">info@umesee.se</a>
                  </div>

                </div>

                <div className="contact-item">

                  <div>
                    <img className="contact-icon"
                         src={process.env.PUBLIC_URL + "/images/icons/mail-circle.svg"}
                         alt="" />
                    <h4>Adress</h4>
                  </div>

                  <div>
                    <p>UMeSee AB</p>
                    <p>C/O Lars Wikman</p>
                    <p>Metargränd 1</p>
                    <p>904 21 Umeå</p>
                    <p>Org nr. 559148-4372</p>
                  </div>

                  {/*<form>
                      <label>Epost*</label><br/>
                      <input className="contact-input"/>
                      <label>Telefonnummer</label>
                      <input className="contact-input"/>
                      <label>Meddelande*</label>
                      <textarea className="contact-textarea"/>
                      <button className="btn btn-green btn-lg contact-button">Skicka</button>
                  </form>*/}


                </div>

              </div>

            </div>
          </div>
        </div>
    )
  }
}

export default Contact;
