import React from "react";
import CustomHeader from "../../../containers/header";
import CustomFooter from "../../../containers/footer";
import $ from "jquery";
import KeyValuesTree from "./key-values-tree";
import MenuLeft from "../../../containers/menu-left";

class KeyValues extends React.Component {
  constructor(props) {
    super(props);

    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.handleAccountingChange = this.handleAccountingChange.bind(this);

    this.state = {
      companyID: null,
      accountingID: null,
      dropdownCompanyLabel: "Välj företag",
      dropdownAccountingLabel: "Välj bokslut"
    };
  }

  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip({
      trigger: "hover"
    });
    this.props.actionCompaniesGet();
  }

  renderDropdownCompanies() {
    let html = [];
    let companiesState = this.props.companies.payload;
    let companies = [];

    for (let key in companiesState) {
      companies.push(companiesState[key]);
    }

    companies.map((company, i) => {
      return html.push(
        <li
          className="dropdown-item"
          key={i}
          onClick={() => {
            this.handleCompanyChange(company);
          }}
        >
          {company.name}
        </li>
      );
    });

    return html;
  }

  renderDropdownAccountings() {
    let html = [];
    let accountings = [];
    let accountingState = this.props.accountings.payload;

    for (let key in accountingState) {
      if (accountingState[key].company === this.state.companyID) {
        accountings.push(accountingState[key]);
      }
    }

    accountings.map((accounting, i) => {
      let title = accounting.periodStart + " - " + accounting.periodEnd;
      return html.push(
        <li
          className="dropdown-item"
          key={i}
          onClick={() => {
            this.handleAccountingChange(accounting, title);
          }}
        >
          {title}
        </li>
      );
    });

    return html;
  }

  handleCompanyChange(company) {
    let state = Object.assign({}, this.state);
    state.companyID = company.pk;
    state.dropdownCompanyLabel = company.name;
    this.setState(state);
    $("#dropdown-accounting-button").prop("disabled", false);
  }

  handleAccountingChange(accounting, title) {
    let state = Object.assign({}, this.state);
    state.accountingID = accounting.pk;
    state.dropdownAccountingLabel = title;
    this.setState(state);
  }

  renderChapter() {
    return (
        <div className="wrapper-chapter-grey">
          <div className="key-wrapper">
            <div className="icon-heading">
              <h2>
                Lönsamhetsträd
                <img
                    className="action-icon"
                    src={process.env.PUBLIC_URL + "/images/icons/info-button.svg"}
                    data-toggle="tooltip"
                    data-placement="bottom"
                    data-html="true"
                    title={"Här ser du viktiga nyckeltal (DuPont-modellen och soliditet) samt hur de beräknas. <br/> Testa att ändra ingående värden för att se hur nyckeltalen påverkas!"}
                    alt=""
                />
              </h2>
            </div>
            <div className="key-dropdown-container">
              <div className="dropdown tree-dropdown">
                <button
                    id="dropdown-company-button"
                    className="dropdown-button dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                  {this.state.dropdownCompanyLabel}
                </button>
                <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                >
                  {this.renderDropdownCompanies()}
                </div>
              </div>

              <div className="dropdown tree-dropdown">
                <button
                    id="dropdown-accounting-button"
                    className="dropdown-button dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    disabled
                >
                  {this.state.dropdownAccountingLabel}
                </button>
                <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                >
                  {this.renderDropdownAccountings()}
                </div>
              </div>
            </div>
            <div className="white-canvas">
              {this.state.accountingID != null ? (
                  <KeyValuesTree
                      key={this.state.accountingID}
                      companyID={this.state.companyID}
                      accountingID={this.state.accountingID}
                      accounting={
                        this.props.accountings.payload[this.state.accountingID]
                      }
                      accountingData={
                        this.props.accountingData.payload[this.state.accountingID]
                      }
                  />
              ) : (
                  "Välj ett företag och ett bokslut för att visa lönsamhetsträdet"
              )}
            </div>
          </div>
        </div>
    )
  }

  render() {
    return (
      <div className="wrapper-main">
        <CustomHeader />
        <div className="wrapper-content">
          <MenuLeft />
          {this.renderChapter()}
        </div>
        <CustomFooter />
      </div>
    );
  }
}

export default KeyValues;
