import React from "react";
import CustomHeader from "../../containers/header";
import CustomFooter from "../../containers/footer";
import {getSubscriptionFromID} from "./user-subscription-order";
import {convertTime} from "../utils";
import MenuLeft from "../../containers/menu-left";

class UserSubscriptionHistory extends React.Component {

    constructor(props) {

        super(props);

        this.props.actionUserSubscriptionOrderGet();
        console.log(this.props.userSubscription);

    }

    renderChapter() {
        let { userSubscription } = this.props;
        return (
            <div className="wrapper-chapter-grey">
                <div className="icon-heading">
                    <h2>Mitt konto</h2>
                </div>
                <div className="white-canvas">
                    {userSubscription && userSubscription.isFetched ?
                        <Content userSubscription={userSubscription}/>
                        :
                        <ContentLoading/>
                    }
                </div>
            </div>
        )
    }

    render() {

        return (
            <div className="wrapper-main">
                <CustomHeader />
                <div className="wrapper-content">
                    <MenuLeft />
                    {this.renderChapter()}
                </div>
                <CustomFooter />
            </div>
        );

    }

}


class ContentLoading extends React.Component {

    render() {
        return (
            <div className="user-profile-content">
                <h2 className="user-profile-header">Loading...</h2>
            </div>
        )
    }

}

class Content extends React.Component {

    render() {

        let { userSubscription } = this.props;
        let html = [];



        if(userSubscription && userSubscription.orders) {

            let orders = userSubscription.orders;

            orders.map((order, i) => {

                let subscription  = getSubscriptionFromID(order.subscription.toString());

                return html.push(
                    <tr>
                        <td key={i}>
                            {order.pk}
                        </td>
                        <td key={i}>
                            {convertTime(order.created)}
                        </td>
                        <td key={i}>
                            {subscription.type + ", " + subscription.desc}
                        </td>
                    </tr>
                );
            });

        }



        return (
            <div className="user-profile-content">

                <h2 className="user-profile-header">Mina beställningar</h2>

                <table className="user-table-orders">
                    <tbody>
                        <tr>
                            <th>
                                ID
                            </th>
                            <th>
                                Datum
                            </th>
                            <th>
                                Typ
                            </th>
                        </tr>
                        {html}
                    </tbody>
                </table>

            </div>
        )

    }

}

export default UserSubscriptionHistory;