import React from "react";
import CustomHeader from "../header";
import CustomFooter from "../footer";
import MenuLeft from "../menu-left";

class Model extends React.Component {
  render() {
    return (
      <div className="wrapper-main">
        <CustomHeader />
        <div className="wrapper-content">
          <MenuLeft />
          <Chapter />
        </div>
        <CustomFooter />
      </div>
    );
  }
}

const Chapter = () => {
  return (
    <div className="page-chapters">
      <div className="wrapper-chapter">
        <div className="chapter-content">

          <h2>Vår förklaringsmodell</h2>

          <h4>Målsättning</h4>
          <p>
            Visualiserad information ger bättre beslut och vi hoppas att
            Bokslutsträdet kan öka förståelsen av ett företags ekonomi för alla
            intressenter. Vi tror att stort värde kan skapas om avståndet mellan
            ekonomisk fakta och insikt kan minska. Tjänsten ska fungera som ett
            komplement till siffrorna i företagets resultat- och balansräkning,
            genom att förmedla information via färg och form. Detta gör det
            enklare att förstå det ekonomiska nuläget, samt gör det möjligt att
            skapa framtida målbilder. Vi vill få fler att förstå mer!
            <br />
            Vårt mål är att alla årsredovisningar ska innehålla en
            grafisk visning av bokslutet år 2025 och vi är glada om ni vill
            vara med på den resan!
          </p>
          <div className="color-scheme-container">
            <img
                className="color-wheel"
                src={process.env.PUBLIC_URL + "/images/logos/explanation.svg"}
                alt=""
            />
          </div>
          <br />
          <h4>Vår förklaringsmodell</h4>
          <p>
            Modellen har fått sitt namn och sin form genom att likna ett företag
            vid ett växande träd, som går från frö till ett fruktbärande träd.
            Med hjälp av denna liknelse konkretiseras ekonomimodellen i något
            som vi alla kan relatera till och därmed lättare förstå. Drivkraften
            för ett företag och ett träd är i grunden liknande - att växa och
            generera avkastning. I vår modell presenteras bokslutet i form av
            ett träd genom att låta tillgångar och skulder visas som rektanglar
            i trädets stam, samtidigt som resultaträkningen visas som trädets
            krona med hjälp av två halvcirklar. Vinsten presenteras i kronan och
            representerar trädets frukt.
          </p>
          <div className="color-scheme-container">
            <img
                className="compare-tree-img"
                src={
                  process.env.PUBLIC_URL +
                  "/images/icons/bokslutsträd-compare.svg"
                }
                alt=""
            />
          </div>
          <br />
          <h4>Form</h4>
          <p>
            Ögonen och hjärnan är snabbare att bedöma storlekar än den är att
            läsa och tolka siffror. Därför använder sig modellen av
            proportionerliga ytor för visualisering av värden, vilket gör att
            man snabbt ser skillnad på stora och små poster i bokslutet.
            Balansräkningen presenteras i modellen som stammen, där dess bredd
            avgörs av nyckeltalet soliditet. Detta görs för att ge en indikation
            och intuitiv känsla av företagets ekonomiska stabilitet. En bred
            stam indikerar ett stabilt företag, medan en smal stam indikerar att
            företaget inte är lika stabilt. Resultaträkningen presenteras i form
            av en rund trädkrona där den vänstra sidan visar intäkter plus
            eventuell förlust, medan den högra sidan visar kostnader och
            eventuell vinst. Finansiella poster kan visas i båda sidor, beroende
            på om de är intäkter eller kostnader.
          </p>
          <br />
          <h4>Färg</h4>
          <p>
            Användningen av färger i en modell kan vara svårt att få till då
            tolkningen av färgens betydelse inte är universell. Däremot kan
            användandet av färger förenkla förståelsen och förtydliga skillnader
            mellan olika poster om det används på rätt sätt. I bokslutsträdet
            används en färgskala inspirerad av regnbågen där färgskalan går från
            rött till lila. I modellen representeras bokslutets olika delar med
            hjälp av dessa färger enligt följande:
          </p>
          <div className="color-scheme-container">
            <img
              className="color-rainbow-text"
              src={
                process.env.PUBLIC_URL + "/images/logos/logo-rainbow-text.svg"
              }
              alt=""
            />
            {/*<div>
              <ul className="color-list">
                <li>Röd - skulder</li>
                <li>Gul - kostnader</li>
                <li>Blå - tillgångar</li>
                <li>Orange - finasiella kostnader</li>
                <li>Grön - intäkter</li>
                <li>Lila - vinst</li>
                <li>Svart - förlust</li>
              </ul>
            </div>*/}
          </div>
          <br />
          <h4>Värdeskapande</h4>
          <p>
            Genom enkel överblick och uppföljning kan man snabbare se förändringar och
            agera för att styra företaget i rätt riktning. Det är lätt att fastna i detaljerna och svårt
            att få en helhetsbild - man ser helt enkelt inte skogen för alla träd.
            Genom att öka tillgängligheten och förståelsen av företagets ekonomi kan ökad insikt uppnås.
          </p>
          <br />
          <div className="letter-author">
            <img
              className="portrait letter-portrait"
              src={process.env.PUBLIC_URL + "../../../images/staff/lars-diplom2.jpeg"}
              alt=""
            />
            <div>
              <div className="signature">
                Lars Wikman
                <p>Lars Wikman, grundare och VD för UMeSee</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Model;
