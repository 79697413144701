import React, { Component } from "react";
import { Link } from "react-router-dom";
import CustomHeader from "../header";
import CustomFooter from "../footer";
import AnchorLink from "react-anchor-link-smooth-scroll";

class Home extends Component {
  componentDidMount() {}

  render() {
    return (
      <div className="wrapper-main">
        <div className="wrapper-index">
          <CustomHeader />
          <Content />
        </div>
        <Chapter />
        <CustomFooter />
      </div>
    );
  }
}

export function toggleArrowIcon(element, iconPath) {
  let arrow = element.target;

  if (arrow.tagName === "IMG") {
    arrow.setAttribute("src", process.env.PUBLIC_URL + iconPath);
  } else {
    arrow = arrow.getElementsByTagName("img")[0];
    arrow.setAttribute("src", process.env.PUBLIC_URL + iconPath);
  }
}

const Content = () => {
  return (
    <div className="content">
      <div className="content-middle">
        <h1 className="content-title">
          Visualiserad information ger bättre beslut!
        </h1>
        <div className="content-text">
          UMeSee erbjuder enkel åtkomst och överblick av företagets ekonomi
        </div>
        <AnchorLink offset="70" href="#umesee">
          <button className="btn btn-white btn-lg btn-header">
            Kom igång
          </button>
        </AnchorLink>
        <Link to="/services/">
          <button className="btn btn-white btn-lg btn-header">
            Mer info
          </button>
        </Link>
      </div>
      <AnchorLink offset="0" href="#umesee" className="content-text-readmore">
        <img src={process.env.PUBLIC_URL + "/images/icons/arrow.svg"} alt="" />
        <span>Läs mer</span>
      </AnchorLink>
      <img
        id="header-tree"
        src={process.env.PUBLIC_URL + "/images/logos/logo.svg"}
        alt=""
      />
    </div>
  );
};

const Chapter = () => {
  return (
    <div>

      <div className="wrapper-chapter" id="umesee">
        <div className="chapter-content">
          <h2>Såhär kommer du igång</h2>
          <div className="wrapper-steps">
            <div className="step-container">
              <div
                  dangerouslySetInnerHTML={{
                    __html:
                        "<!-- Icon made by GraphicsBay from https://flaticon.com -->"
                  }}
              />
              <img
                  src={process.env.PUBLIC_URL + "/images/icons/add-user-icon.svg"}
                  alt=""
              />
              <div>
                <h4>1. Skapa ett konto</h4>
                <p>Registrera din e-post för att skapa ett gratis bas-konto</p>
              </div>
            </div>
            <div className="step-container">
              <div
                  dangerouslySetInnerHTML={{
                    __html:
                        "<!-- Icon made by Smartline from https://flaticon.com -->"
                  }}
              />
              <img
                  src={process.env.PUBLIC_URL + "/images/icons/import-icon.svg"}
                  alt=""
              />
              <div>
                <h4>2. Importera bokslut</h4>
                <p>
                  Mata in ett bokslut manuellt eller uppgradera till standard-konto för att
                  enkelt importera en SIE-fil.
                </p>
              </div>
            </div>
            <div className="step-container">
              <div
                  dangerouslySetInnerHTML={{
                    __html:
                        "<!-- Icon made by Freepik from https://flaticon.com -->"
                  }}
              />
              <img
                  src={process.env.PUBLIC_URL + "/images/icons/computer.svg"}
                  alt=""
              />
              <div>
                <h4>3. Visualisera din ekonomi</h4>
                <p>
                  Se grafiska presentationer av företagets bokslut. Vi sätter färg på er ekonomi!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div className="wrapper-chapter">
        <div className="chapter-content">

          <h2>Visualisera din ekonomi genom våra tjänster</h2>

          <div className="key-container">

            <div className="key-box key-right">
              <img src={process.env.PUBLIC_URL + "/images/icons/circle-accounting.svg"}
                   alt="" />
              <div className="key-content">
                <h4>Hantera bokslut</h4>
                <p>
                  Genom denna tjänst kan du lägga till, ta bort och redigera företag och bokslut.
                  Bokslut eller budget kan importeras eller manuellt matas in.
                </p>
              </div>
            </div>

            <div className="key-box key-left">
              <img src={process.env.PUBLIC_URL + "/images/icons/circle-tree-accounting.svg"}
                   alt="" />
              <div className="key-content">
                <h4>Bokslutsträd &reg;</h4>
                <p>
                  Bokslutsträdet gör det enkelt och pedagogiskt att presentera
                  och jämföra bokslut mellan år eller varför inte andra företag?
                </p>
              </div>
            </div>

            <div className="key-box key-right">
              <img src={process.env.PUBLIC_URL + "/images/icons/circle-tree-base-accounting.svg"}
                   alt="" />
              <div className="key-content">
                <h4>Kontoträd</h4>
                <p>
                  Nu kan du snabbt se och klicka dig djupare i dina konton och få en oöverträffad överblick.
                </p>
              </div>
            </div>

            <div className="key-box key-left">
              <img src={process.env.PUBLIC_URL + "/images/icons/circle-tree-key-values.svg"}
                   alt="" />
              <div className="key-content">
                <h4>Lönsamhetsträd</h4>
                <p>
                  Viktiga nyckeltal och samband presenteras översiktlig. Det är
                  också enkelt att ändra ingående värden och direkt se utfallet.
                </p>
              </div>
            </div>
          </div>

          <div className="center-key-container">

            <p>Tjänsterna finns alltid tillgängliga på alla dina enheter via vår hemsida.<br/>
            Det enda du behöver är internetuppkoppling.</p>

            <p>
              <Link
                  to="/services/"
                  onMouseOver={e =>
                      toggleArrowIcon(e, "/images/icons/link-arrow-hover-icon.svg")
                  }
                  onMouseLeave={e =>
                      toggleArrowIcon(e, "/images/icons/link-arrow-icon.svg")
                  }
              >
                <img
                    className="link-icon"
                    src={
                      process.env.PUBLIC_URL + "/images/icons/link-arrow-icon.svg"
                    }
                    alt=""
                />
                Läs mer om våra tjänster
              </Link>
            </p>

          </div>
        </div>
      </div>

      <hr />

      <div className="wrapper-chapter">
        <div className="chapter-content">
          <h2>Vi får fler att förstå mer!</h2>

          <p>
            I Sverige finns över en halv miljon aktiebolag som årligen tar fram och
            skickar in bokslutet till Bolagsverket. Utöver kravet att uppfylla
            lagar och regler finns ett stort behov att presentera en rättvisande
            bild av företagets ekonomiska situation för sina intressenter. Men
            människor har olika sätt vi föredrar att ta till oss information.
            Det finns därför ett växande behov att presentera information
            översiktligt och på ett sätt så att fler enkelt förstår den.
            <br />
            <br />
            UMeSee tillhandahåller en digital tjänst och egenutvecklad
            förklaringsmodell (Bokslutsträd&reg;) för att snabbt och enkelt
            presentera finansiell information. Vi kompletterar siffrorna med
            pedagogiska former och färger samt egenutvecklade diagram för att
            öka förståelsen. Genom enkel import av data från bokföringsprogrammet
            presenterar vi informationen på vår hemsida där du sen kan leka med siffrorna
            utan att riskera bokföringen.
            <br />
            <br />
            Målet med vår tjänst är att skapa översikt i djungeln av siffror och
            öka förståelsen i företagen gällande dess ekonomiska situation. Vi
            vill bygga en bro mellan de som har informationen och de som behöver
            den. Enkelt pedagogiskt och översiktligt.
          </p>
        </div>
      </div>

      <hr />

      <div className="wrapper-chapter contact-us">
        <div className="chapter-content">
          <h2>Vill du veta mer?</h2>
          <p className="h2-subheader">
            Om du har någon fråga eller fundering om våra tjänster, kontakta oss gärna!
          </p>
          <Link to="/contact">
            <button className="btn btn-green btn-lg">Kontakta oss</button>
          </Link>
        </div>
      </div>

      <div className="wrapper-chapter call-to-action">
        <Link to="/register">
          <button className="btn btn-white btn-lg">
            Starta här
          </button>
        </Link>
      </div>

    </div>
  );
};

export default Home;
