import React from "react";
import CustomHeader from "../header";
import CustomFooter from "../footer";
import MenuLeft from "../menu-left";

class TermsOfUse extends React.Component {
  render() {
    return (
      <div className="wrapper-main">
        <CustomHeader />
          <div className="wrapper-content">
              <MenuLeft />
              <Chapter />
          </div>
        <CustomFooter />
      </div>
    );
  }
}

const Chapter = () => {
  return (
    <div className="page-chapters">
      <div className="wrapper-chapter">
        <div className="chapter-content">
          <h2>Användarvillkor</h2>
          <Content />
        </div>
      </div>
    </div>
  );
};

const Content = () => {
  return (
    <div class="termsofuse">
      <h2>1. Cookies</h2>
      <h3>1.1. Om Cookies</h3>
      UMeSee AB använder cookies för att optimera användarupplevelsen. Genom att
      acceptera användarvillkor och nyttja tjänsten godkänner användaren
      nyttjandet av cookies. Enligt lagen om elektronisk kommunikation ska alla
      som besöker en webbplats med cookies få tillgång till information om att
      webbplatsen innehåller cookies och ändamålet med användningen. Besökaren
      ska också lämna sitt samtycke till att cookies används. UMeSee.se använder
      cookies för att vi ska kunna se de val du tidigare gjort på våra
      webbplatser och på det sättet förbättra din upplevelse. Cookies används
      även för att mäta trafiken på webbplatsen och för statistik.
      <br />
      <br />
      Det normala är att cookies sparas automatiskt i din webbläsare. Det är
      möjligt för dig att göra inställningar på din dator om du vill tillfrågas
      varje gång webbplatsen vill spara en cookie eller om du vill att inga
      cookies ska sparas. Sådana inställningar kan dock innebära att vissa
      funktioner på denna webbplats inte kan användas. För att kunna använda
      våra digitala tjänster krävs att din webbläsare tillåter cookies.
      <h3>1.2. Så här fungerar cookies</h3>
      En cookie är en liten textfil som lagras på din dator. Denna data skickas
      från en webbplats till din webbläsare och sparas där, på din hårddisk, så
      att webbplatsen kan känna igen din dator. Alla webbplatser kan skicka
      cookies till din webbläsare om dina inställningar i webbläsaren tillåter
      det. För att denna information inte ska missbrukas kan webbplatser bara
      läsa information från cookies sparade av dem själva och inte några cookies
      sparade av andra webbplatser. Informationen som samlas in är anonym och
      innehåller inga personliga uppgifter.
      <h3>1.3. Permanenta och temporära cookies</h3>
      Det finns två typer av cookies, permanenta och temporära (s.k.
      sessionscookies). Permanenta cookies lagras som en fil på din dator under
      en längre period. Sessionscookies är temporärt placerade på din dator när
      du besöker en webbplats, men försvinner när du stänger ner sidan vilket
      innebär att de inte sparas permanent på din dator. De flesta företag
      använder cookies på sina webbplatser för att förbättra användbarheten.
      Cookies kan inte skada dina filer eller öka risken för virus på din dator.
      Vilka cookies använder UMeSee? UMeSee.se använder både permanenta och
      temporära cookies. Permanenta cookies använder vi för att spara
      inställningar i gränssnittet, sessionscookies används bland annat vid
      inloggning. Sessionscookien gör då att du slipper logga in på nytt varje
      gång du följer en länk till en ny sida. Vi använder även några
      tredjepartstjänster som i sin tur sätter egna cookies.
      <h2>2. Allmänna villkor</h2>I och med att användaren nyttjar tjänsten
      godkänns användarvillkoren Bokslutsträd™ Software As A Service License
      Agreement (engelska).
      <h3>2.1. Användarvillkor - svensk sammanfattning</h3>
      <ul className="agreement-list">
        <li className="agreement-list-item">
          användas utan UMeSee ABs copyright och logotyp,
        </li>
        <li className="agreement-list-item">
          användas för att jämföra, samla in eller publicera data om prestanda
          eller utveckla produkter och tjänster som konkurrerar med UMeSee AB.
        </li>
        <li className="agreement-list-item">
          Licensen till Bokslutsträd™ är personlig och avser den period som
          angetts vid registreringen av användarkonto. Licensen får inte utan
          UMeSee ABs skriftliga godkännande
          <ul className="agreement-list">
            <li className="agreement-list-item">
              säljas, uthyras eller överföras till tredje part,
            </li>
            <li className="agreement-list-item">
              dekompilera eller använda programmet som underlag för att ta fram
              egen programkod,
            </li>
            <li className="agreement-list-item">
              integreras eller kopplas till annan mjukvara, vare sig som helhet
              eller i delar,
            </li>
            <li className="agreement-list-item">
              mot tredje part användas som en kommersiell tjänst,
            </li>
            <li className="agreement-list-item">
              användas som samarbetsplattform med tredje part,
            </li>
          </ul>
        </li>
        <li className="agreement-list-item">
          Bokslutsträd™ kan erbjudas inklusive data från UMeSee ABs
          samarbetspartners. Sådan data får endast användas för visualisering,
          analys och simulering av balans- och resultaträkningar i
          Bokslutsträd™. För att förhindra annat syfte godkänner användaren att
          antalet hämtningar från dessa databaser är begränsat till tio
          hämtningar per dygn.
        </li>
        <li className="agreement-list-item">
          Licensen till Bokslutsträd™ är icke-exklusiv och inga rättigheter
          utöver nyttjanderätten övergår till användaren i och med licensen.
        </li>
        <li className="agreement-list-item">
          Det åligger användaren att tillse att dennes säkerhetslösningar för
          användning av internet är ändamålsenliga.
        </li>
        <li className="agreement-list-item">
          Användaren godkänner att UMeSee AB inte har ansvar för andra
          webbplatser än sin egen, i det fall Bokslutsträd™ innehåller länkar
          till andra tjänster.
        </li>
        <li className="agreement-list-item">
          Det åligger inte UMeSee AB att tillhandahålla service eller
          uppdateringar av Bokslutsträd™ utan det sker enligt UMeSee ABs
          gottfinnande.
        </li>
        <li className="agreement-list-item">
          UMeSee AB lämnar inte någon utfästelse eller garanti att: (i)
          Bokslutsträd™ uppfyller användarens krav eller önskemål; (ii)
          Bokslutsträd™ kommer att fungera i kombination med användarens
          hårdvara eller mjukvara; eller (iii) driften av Bokslutsträd™, kommer
          att vara oavbruten eller felfri.
        </li>
        <li className="agreement-list-item">
          UMeSee AB tar inte något ansvar för data som presenteras i
          Bokslutsträd™, oavsett varifrån sådana data kommer. UMeSee AB påtar
          sig inte heller i övrigt något ansvar för kvaliteten eller
          fullständigheten av den information som lämnas genom Bokslutsträd™ och
          ger inte någon garanti att informationen är felfri och aktuell. UMeSee
          AB ansvarar inte för några råd som ges av användaren, handelsbeslut
          som gjorts av användaren eller för eventuella skador eller andra
          förluster som orsakas av användarens användning av tjänsten.
        </li>
        <li className="agreement-list-item">
          Vid uppsägning av licensen återbetalas inte någon licensersättning.
        </li>
        <li className="agreement-list-item">
          Användaren godkänner även eventuella dataleverantörers
          ansvarfriskrivningar.
        </li>
      </ul>
      <h3>2.2. Bokslutsträd™ Software As A Service License Agreement</h3>
      Important: do not use this software service until you have read and agreed
      to the terms of this agreement. By clicking on the “I accept” button or
      using the software service, the User acknowledges and agrees that all use
      is subject to the terms and conditions of this Bokslutsträd™ license
      agreement (“BLA”) as may be updated from time to time and published at
      www.umesee.se. Any such use will constitute acceptance and result in a
      binding and legally enforceable agreement. If the user is accepting these
      terms on behalf of any corporation, partnership or other entity, the user
      represents and warrants that the user is authorized to legally bind such
      entity to this agreement. The software may not be used for purposes of
      benchmarking, collecting and publishing software performance data or
      analysis. The software contains functionality intended to limit the
      duration of its use and is intended to collect certain usage metrics.
      <h4>1. INTRODUCTION</h4>
      This BLA is between the User and UMeSee AB (“Licensor”) and governs the
      use of Bokslutsträd™ software, related software components and
      documentation (all of which are referred to as the “Software”), as
      provided from the Licensor’s, or its suppliers’, server hardware as a
      service, hereinafter called the “Service”.
      <h4> 2. GRANT OF LICENSE</h4>
      2.1 Subject to the terms and conditions of this BLA, Licensor grants to
      the User a limited, non-exclusive, non sub-licensable and non-transferable
      license to access and use the most current version of the Service, for
      such a time period as the Parties have agreed, at the purchased service
      level, including any printed documentation, documentation files or
      multimedia presentations as published by Licensor and accompanying the
      Bokslutsträd™ Software (the “Documentation”). <br />
      <br />
      2.2 Except as otherwise expressly permitted in this BLA, User will not,
      directly or through other users: (i) use, copy, maintain, distribute,
      sell, transfer, market, sublicense, dispose of or rent the Software; (ii)
      reverse assemble, reverse compile, decompile, disassemble or reverse
      engineer (except to the extent any such restriction is expressly
      prohibited by applicable law) or attempt to derive the source code for any
      of the Software; (iii) modify, adapt, create derivative works, translate,
      or port any of the Software or combine or merge any part of the Software
      with or into any other software or documentation; (iv) offer, use,
      sublicense or otherwise commercially exploit any of the Software,
      including without limitation use of the Software as a revenue- generating
      (or value enhancing) product or service to any third party, for third
      party training, or to provide hosting, service bureau, commercial
      time-sharing, rental, or software as a service (saas) services to third
      parties who are not Users for the purposes of providing those persons or
      entities with use of the Software; (v) permit the use of the Software or
      Documentation by third parties or use the Software on any online
      collaboration platform which is not sponsored by Licensor; (vi) reproduce
      the Software or Service without Licensor’s copyright and trademark
      notices, (vii) use the Service for purposes of benchmarking, collecting
      and publishing performance data, or developing a product which is
      competitive with any of the Licensor’s products or services. Without
      limiting any of the foregoing, if a serial number, password, license key
      or other security device is provided to the User for use with the Service,
      the User may not share or transfer such security device with or to any
      other user of the Service or any other third party. User shall not run,
      install or transfer to its own or any third party’s hardware the Software
      without Licensor’s express written consent. Any other use of the Service
      by any third party, except as provided in this BLA, is strictly forbidden;
      (viii) Bokslutsträd™ can be offered including data from Licensor&#39;s
      partners. In such case, this data may only be used for the purpose of
      visualizing, analyzing and simulating financial statements in
      Bokslutsträd™. To prevent other purposes, the user accepts that the number
      of downloads from these databases is limited to fifteen downloads per
      commenced day (24 hours). <br />
      <br />
      2.3 As the Service is accessible through the Internet or similar networked
      environment, User shall maintain adequate technical and procedural access
      controls and system security requirements and devices, in connection with
      the Service, necessary for confidentiality, authorization, authentication
      and virus detection and eradication. <br />
      <br />
      2.4 Licensor and its Affiliates, or their respective suppliers or
      Licensors where applicable, own and retain all right, title and interest
      in and to the Software, and all of Licensor’s and its Affiliates’, or
      their respective suppliers’ or Licensors’, patents, trademarks (registered
      or unregistered), trade names, copyrights, trade secrets and Licensor
      Confidential Information. User does not acquire any right, title or
      interest in or to the Software, except as expressly set forth herein, and
      all such rights are hereby reserved. User will not register, nor attempt
      to register, any patent or copyright which, in whole or in part,
      incorporates any of Licensor’s technology or its intellectual property
      rights. In the event that User makes suggestions, improvements or
      modifications to Licensor regarding new features, functionality or
      performance that Licensor adopts for the Software or any of its other
      products or services, such new features, functionality or performance
      shall be deemed to be automatically assigned under this BLA to, and shall
      become the sole and exclusive property of, Licensor. <br /> <br />
      2.5 The Software and Documentation contain material that is protected by
      copyright laws and international treaty provisions. The User shall
      maintain at all times all copyright notices provided on the Software,
      Documentation and associated packaging and any copies thereof. Copies of
      the Software and Documentation constitute Licensor’s property. All the
      terms and conditions of this BLA shall also apply to such copies. <br />
      <br />
      2.6 The Software may include links that will take the User to websites not
      operated by Licensor. These links are available as a convenience and for
      informational purposes, and do not constitute or imply an endorsement or
      recommendation. User agrees that Licensor is not responsible for the
      availability or contents of any website not operated by Licensor. Third
      party software components, each of which has its own copyright and its own
      applicable license conditions may be distributed, embedded, or bundled
      with the Software. Use of such components must be in accordance with its
      applicable license terms. Licensor makes no representation, warranty or
      other commitment of any kind regarding such software. Licensor offers no
      support for such software and shall, to the maximum extent permitted by
      law, have no liability associated with its use.
      <h4> 3. MAINTENANCE</h4>
      3.1 Licensor shall not be obligated to provide Maintenance and support
      services for the Service, but may elect to provide Software updates at its
      sole discretion.
      <h4>4. DISCLAIMERS</h4>
      4.1 Licensor does not represent, warrant or make any commitment that: (i)
      the Software will meet User’s requirements; (ii) the Software will operate
      in combination with other hardware or software; or (iii) operation of the
      Software, will be uninterrupted or error free. The Service is provided
      &quot;as is&quot;, and Licensor and its affiliates and suppliers disclaim
      all other warranties, conditions and other terms, whether express or
      implied (by statute, common law or otherwise) including, without
      limitation, as to their accuracy, timeliness, completeness, results,
      title, non-infringement, satisfactory quality, merchantability and fitness
      for a particular purpose, even if Licensor has been informed of such
      purpose, and any representations, warranties or other terms arising from
      course of performance, course of dealing, or usage of trade. <br />
      <br />
      4.2 Licensor takes no responsibility for data presented in the Service,
      regardless of whether such data is provided from public databases,
      internal databases, manual feed data or elsewhere. Licensor assumes no
      liability for the accuracy or completeness of the information provided and
      makes no warranties, express or implied that the Information is error
      free, timely, accurate or contains no omissions. Licensor is not
      responsible for any advice given by User, trading decisions made by User
      or for any damages or other losses caused by the User’s use of the
      Service.
      <h4>5. LIMITATION OF LIABILITY</h4>
      5.1 Licensor’s maximum aggregate liability under this BLA (regardless of
      the form of action, whether in contract, tort, or otherwise) shall be
      limited to direct damages not to exceed the equivalent one thousand
      Swedish krona. <br />
      <br />
      5.2 In no event shall Licensor or its respective Licensors or affiliates
      be liable for any loss of profits or revenues, loss of savings, goodwill,
      or data, inaccuracy of any data, the cost of procurement of substitute
      goods, services or software, or for any indirect, incidental, special,
      punitive, exemplary or consequential damages, howsoever arising and
      regardless of the theory of liability, even if the user has been advised
      of the possibility of such damage or loss. <br />
      <br />
      5.3 The foregoing limitations, exclusions and disclaimers set forth in
      this BLA shall apply to the maximum extent permitted by applicable law,
      even if any remedy fails of its essential purpose. Some jurisdictions do
      not allow the limitation or exclusion of liability set forth above.
      Accordingly, some of the limitations may not apply to user. To the extent
      that Licensor may not, as a matter of mandatory applicable law, disclaim
      any implied warranty or limit its liabilities, the scope or duration of
      such warranty and the extent of Licensor’s liability shall be the minimal
      permitted under such applicable law. 5.4 Nothing in this BLA will create
      any rights in favor of any third party including any rights pursuant to
      the UK’s Contracts (Rights of Third Parties) Act 1999 or other applicable
      law. This shall not affect any right or remedy of a third party that
      exists or is available apart from that Act or such other applicable law.
      <h4>6. TERM AND TERMINATION</h4>
      6.1 This BLA is effective for the agreed time period or until terminated.
      The User may terminate this BLA for convenience at any time by
      deactivating the user account. Licensor may terminate this BLA immediately
      upon User’s breach. No fees shall be repaid after the termination.
      <br />
      <br />
      6.2 Upon termination, the license(s) to use the Service hereunder shall
      terminate. All provisions relating to confidentiality, Licensor’s
      ownership and proprietary rights, limitations of liability, disclaimers of
      warranties, waiver, audit and governing law and jurisdiction shall survive
      the termination of this BLA. Termination shall not affect or prejudice
      either party’s rights accrued as at the date of termination.
      <h4>7. CONFIDENTIALITY</h4>
      7.1 User agrees to hold in confidence Confidential Information until User
      receives written notice from Licensor that the Confidential Information
      ceases to be confidential. User further agrees that User shall not use
      Confidential Information except to the extent necessary to exercise the
      license granted to User by Licensor under the BLA. User will protect
      Confidential Information from unauthorized distribution and use with the
      same degree of care that User uses to protect its own like information,
      but in no event less than a reasonable degree of care. User acknowledges
      and agrees that, due to the unique nature of the Confidential Information,
      there can be no adequate remedy at law for breach of this Section 7 and
      that such breach would cause irreparable harm to Licensor; therefore
      Licensor will be entitled to seek immediate injunctive relief, in addition
      to any remedies otherwise available at law or under this BLA. <br />{" "}
      <br />
      7.2 “Confidential Information“ means any confidential or proprietary
      information which relates to Licensor’s trade secrets, Software, source
      code for the Software, the Documentation, services, deliverables, training
      materials, technology, research, development, pricing, product plans,
      marketing plans, business information, proprietary materials including
      visual expressions, screen formats, report formats, design features,
      methods, algorithms, formulae, and concepts used in Bokslutsträd™ and all
      future modifications and enhancements. Confidential Information shall also
      include third party data or information that was disclosed to User under a
      duty of confidentiality. Confidential Information also includes any
      information, in whatever form, disclosed or made available by Licensor to
      User that relates to or is contained within Licensor Confidential
      Information and that is not publicly known. Confidential Information does
      not include information that: (i) enters the public domain through no
      fault of User; (ii) is communicated to User by a third party under no
      obligation of confidentiality; (iii) has been independently developed by
      User without reference to any Confidential Information; (iv) was in User’s
      lawful possession prior to disclosure and had not been obtained either
      directly or indirectly from Licensor; and (v) is required to be disclosed
      by law, provided User has promptly notified Licensor in writing of such
      requirement and allowed Licensor a reasonable time to oppose such
      requirement.
      <h4>8. DATA SERVICES</h4>
      8.1 The Service may include functionality that allows User to access
      certain data from Licensor’s third party data providers (“Data”). User’s
      use of any Data with Bokslutsträd™ is subject to the additional terms and
      conditions set forth in this Section 8. The terms in this Section 8 shall
      take precedence and supersede any conflicting terms in the Agreement.{" "}
      <br />
      <br />
      8.2 Subject to User’s continued compliance with the terms of this BLA,
      User shall have the right to use Data, which User has acquired, accessed
      or created in a legal manner, together with the Service and thus access
      the Data for the sole purpose of displaying such Data within visualization
      generated by the Software during the term of this BLA. To the extent not
      expressly licensed to User hereunder, Licensor and its vendors and
      suppliers reserve and retain all right, title and interest in and to the
      Data and any all intellectual property rights embodied therein.
      <br />
      <br />
      8.3 User shall not permit or authorize anyone to (a) use the Data for any
      purpose except as set forth above, (b) distribute, convey, lend, lease,
      share, sell, charge for the right to use, transfer, market, sublicense,
      rent or otherwise make available any of the Data to any third party,
      including, but not limited to, on a stand-alone basis, as an integrated
      component of any product other than the Service or in a manner that would
      permit a third party to sublicense or distribute the Data on an OEM or
      similar basis, (c) remove any copyright, trademark or other proprietary
      notice from the Data, (d) decompile (including, without limitation, to
      re-identify any personally identifiable information contained therein),
      modify or alter any part of the Data, (e) display, publish or perform any
      of the Data or (f) use the Data in any manner that violates applicable
      law. User acknowledges that, as a condition of publishing, displaying, or
      otherwise disclosing certain Data, User agrees to include notices, to the
      extent required, attributing the source of any Data to the publisher of
      such Data, in accordance with Third Party Terms.
      <br />
      <br />
      8.4 The Data provided in the Service are provided &quot;as is&quot;
      without warranty of any kind. Licensor hereby disclaims all warranties,
      whether express, implied or statutory, regarding the Data, including
      without limitation any and all implied warranties of merchantability,
      accuracy, results of use, reliability, fitness for a particular purpose,
      title, interference with quiet enjoyment, and non-infringement of third-
      party rights. Further, Licensor disclaims any warranty that User&#39;s use
      of the Data will be uninterrupted or error free. Licensor does not warrant
      or guarantee that it will correct any errors or inaccuracies in the Data
      and Licensor will not provide any support or maintenance specifically for
      the Data services. User hereby acknowledges that Licensor retrieves,
      aggregates, normalizes and delivers the Data from third party sources and
      does not generate or create the Data itself.
      <h4>9. GENERAL PROVISIONS</h4>
      9.1 During the term of this BLA and for a period of one (1) year
      thereafter, upon Licensor’s written request, but no more frequently than
      once per year, Licensor or an independent and reputable agent or
      accounting firm chosen by Licensor will be provided reasonable access
      during User’s normal business hours to examine User’s records and computer
      equipment, at Licensor’s expense, for the purpose of auditing User’s
      obligations under this BLA. Licensor’s written request for audit will be
      submitted to User at least fifteen (15) days prior to the specified audit
      date. If User is not in material compliance with the terms of this BLA,
      then notwithstanding any rights or remedies available to Licensor in
      respect of such non-compliance, User shall reimburse Licensor for the
      expenses incurred by Licensor in conducting the audit. <br />
      <br />
      9.2 User may not assign or transfer this BLA or its rights and obligations
      under this BLA to any third party without the prior written consent of
      Licensor. Licensor is free to assign or transfer any or all of its rights
      or obligations under this BLA at its discretion. <br />
      <br />
      9.3 This BLA constitutes the entire BLA between the parties with respect
      to the use of the Software, and supersedes all previous agreements,
      representations, warranties, statements, negotiations, understandings and
      undertakings, whether verbal or written, pertaining to such subject
      matter. If any provision of this Agreement is found by a court of
      competent jurisdiction to be invalid or unenforceable, that provision will
      be limited to the minimum extent necessary so that this Agreement will
      otherwise remain in force and effect. The User hereby represents and
      acknowledges that in entering into this BLA, it did not rely on any
      representations or warranties other than those expressly set forth in this
      BLA.
      <br />
      <br />
      9.4 User agrees at all times to comply with applicable laws and
      regulations in its performance of this BLA, including, without limitation,
      the provisions of the U.S. export control laws, the U.S. Trading With the
      Enemy Act, the U.S. International Emergency Economic Powers Act, the U.S.
      Arms Export Control Act and U.S. Department of the Treasury Office of
      Foreign Assets Control, the United States’ Foreign Corrupt Practices Act
      (“FCPA”) and the United Kingdom’s Bribery Act 2010 (“Bribery Act”). User
      will indemnify, defend and hold harmless Licensor and its respective
      officers, agents and employees from and against any and all losses, costs,
      claims and other liabilities arising out of, relating to or resulting from
      User’s failure to comply with the provisions of applicable laws or the
      FCPA or the Bribery Act.
      <br />
      <br />
      9.5 This BLA is governed by and construed in accordance with the laws of
      Sweden (excluding the U.N. Convention on Contracts for the International
      Sale of Goods) and any dispute, controversy or claim arising out of or in
      connection with this BLA, or the breach, termination or invalidity
      thereof, shall be settled by arbitration at the Arbitration Institute of
      the Stockholm Chamber of Commerce (the “SCC Institute”). Where the amount
      in dispute clearly does not exceed EUR 100,000, the SCC Institute’s Rules
      for Expedited Arbitration shall apply and the arbitral tribunal shall be
      composed of a sole arbitrator. Where the amount in dispute clearly exceeds
      the amount set forth above, the Rules of the SCC Institute shall apply and
      the arbitral tribunal shall be composed of three arbitrators. The place of
      arbitration shall be Stockholm, Sweden. The language to be used in the
      arbitral proceedings shall, unless otherwise agreed, be English. <br />
      <br />
      9.6 All notices or other communications to Licensor shall be addressed to:
      UMeSee AB, Metargränd 1, SE-904 21 Umeå, Sweden; ATTENTION: Legal
      Department. <br />
      <br />
      9.7 Nothing in this BLA shall be construed to create an agency, joint
      venture, partnership, fiduciary relationship, or similar relationship
      between the Parties. If any provision of this BLA is invalid or
      unenforceable, that provision shall be construed, limited, modified or, if
      necessary, severed to the extent necessary to eliminate its invalidity or
      unenforceability, and the other provisions of this BLA shall remain in
      full force and effect. All terms of this BLA shall be binding upon, inure
      to the benefit of, and be enforceable by and against the respective
      successors and permitted assigns of Licensor and User. No term of this BLA
      shall be deemed waived and no breach or default excused unless such waiver
      or excuse shall be in writing and signed by the Party issuing the same.{" "}
      <br />
      <br />
      9.8 If the User is a unit or agency of the United States Government, User
      agrees that the Software is “commercial computer software” or “commercial
      computer software documentation” and that, absent a written agreement to
      the contrary, the United States Government’s rights with respect to such
      Software or Documentation are limited by the terms specified in this BLA,
      pursuant to FAR 12.212(a) and/or DFARS 227.7202-1(a), as applicable. The
      Software has been developed exclusively at private expense, and has been
      available for license by members of the public.
      <h4>10. USER REGISTRATION AND INFORMATION</h4>
      10.1 Prior to using the Software, the User is required to register with
      Licensor and provide certain information. User consents to allow Licensor
      to store such information and to contact the User regarding products and
      services, for the term of this BLA. If User withdraws the consent at any
      time, this BLA shall automatically terminate. In order to improve the
      Software and the User’s experience with the Software, Licensor may collect
      and use certain statistical and usage information relating to the
      Software. This information may include size and number of databases and
      document objects, session information (e.g., number, duration, error
      messages, types/number of users and applications used) and browser
      configurations. Any attempt by the User to disable or interfere with any
      functionality in the Software is not permitted and shall be a breach of
      the BLA.
      <h4> DATAPROVIDERS’ DISCLAIMERS</h4>
      CheckBiz AB: Informationen i databasen har sammanställts från ett stort
      antal olika källor vilka bedömts tillförlitliga. CheckBiz kan inte
      garantera att informationen är korrekt eller fullständig. Det är
      användaren/Kunden som måste avgöra om informationen är tillräckligt
      tillförlitlig eller fullständig att använda för avsett ändamål. CheckBiz
      friskriver sig för ekonomiskt ansvar för direkt eller indirekt skada eller
      förlust som grundar sig på användandet av CheckBiz information såvida inte
      CheckBiz gjort sig skyldig till grov oaktsamhet. CheckBiz AB: Assume no
      liability for the accuracy of the information provided and a make no
      warranties, express or implied that the Information is error free, timely,
      accurate or contains no omissions. This financial data does not contain
      legal advice, nor does it contain investment advice and should not be
      relied upon for these purposes. Investing in securities involves risks. We
      are not responsible for any trading decisions, damages or other losses
      related to the Information or its use. Securities can increase and
      decrease in value and there is no guarantee that you will get your
      investment back. Past performance is no guarantee of future returns.
      Advice from a securities professional is strongly advised before taking or
      refraining from taking any action as a result of the contents of this
      financial data.
    </div>
  );
};

export default TermsOfUse;
