import * as constants from "../constants";

const initialState = {
    isSuccess: null,
    payload: null,
    error: null
};

export default function(state = initialState, action) {

    switch (action.type) {

            // confirm email

        case constants.USER_TOOL_CONFIRM_EMAIL_REQUEST:
            return {
                ...state,
            };
        case constants.USER_TOOL_CONFIRM_EMAIL_SUCCESS:
            return {
                ...state,
                isSuccess: true,
                payload: action.payload,
                error: null,
            };
        case constants.USER_TOOL_CONFIRM_EMAIL_FAILURE:
            return {
                ...state,
                isSuccess: false,
                error: action.payload,
            };

            // reset

        case constants.USER_TOOL_RESET:
            return initialState;

        default:
            return state;

    }

}
