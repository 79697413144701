import React from "react";

class DropdownCompanies extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            companyID: "",
            companyLabel: "Välj företag",
        };

        this.handleCompanyChange = this.handleCompanyChange.bind(this);

    }

    renderDropdownCompanies() {
        let html = [];
        let companiesState = this.props.companies.payload;
        let companies = [];

        for (let key in companiesState) {
            companies.push(companiesState[key]);
        }

        companies.map((company, i) => {
            return html.push(
                <li
                    className="dropdown-item"
                    key={i}
                    onClick={() => {
                        this.handleCompanyChange(company);
                    }}
                >
                    {company.name}
                </li>
            );
        });

        return html;
    }

    handleCompanyChange(company) {
        let state = Object.assign({}, this.state);
        state.companyID = company.pk;
        if (this.state.companyID !== state.companyID) {
            state.dropdownAccountingLabel = "Välj bokslut";
        }
        state.companyLabel = company.name;
        this.setState(state);
        this.props.callbackHandleCompanyChange(company.pk);
    }

    render() {
        return (
            <div className="dropdown tree-dropdown">
                <button
                    id={"dropdown-company-" + this.props.elementID}
                    className="dropdown-button dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    {this.state.companyLabel}
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {this.renderDropdownCompanies()}
                </div>
            </div>
        )
    }

}

export default DropdownCompanies;

