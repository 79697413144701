import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import store from '../store'

const PrivateRoute = ({component: Component, ...rest}) => {

    const isAuthenticated = store.getState().reducerUser.isAuthenticated;

    if(isAuthenticated) {
        return (
            <Route {...rest} render={(props) => <Component {...props} />} />
        )
    } else {
        return (
            <Route {...rest} render={(props) => <Redirect to={{pathname: '/login', state: {from: props.location}}} />} />
        )
    }

};

export default PrivateRoute;