import React from "react";
import CustomHeader from "../header";
import CustomFooter from "../footer";
import {Link} from "react-router-dom";
import MenuLeft from "../menu-left";

class Products extends React.Component {
  render() {
    return (
      <div className="wrapper-main">
        <CustomHeader />
        <div className="wrapper-content">
          <MenuLeft />
          <Chapter />
        </div>
        <CustomFooter />
      </div>
    );
  }
}

const ImageCheck = () => {
  return (
      <img className="product-sign" alt=""
           src={process.env.PUBLIC_URL + "/images/icons/sign-check.svg"} />
  )
};

const ImageUncheck = () => {
  return (
      <img className="product-sign" alt=""
           src={process.env.PUBLIC_URL + "/images/icons/sign-uncheck.svg"} />
  )
};

const Chapter = () => {
  return (
    <div className="page-chapters wrapper-grey">
      <div className="wrapper-chapter">
        <div className="chapter-content">

          <h2>Abonnemang</h2>

          <div className="products">

            <div className="product">

              <div className="product-content">

                <h1 className="product-header">Bas</h1>
                <p className="product-header-desc">För dig som vill testa våra tjänster</p>
                <h1 className="product-price">Gratis</h1>

                <hr className="product-hr" />

                <ul>
                  <li>
                    <ImageCheck />
                    Hantera bokslut
                    <ul>
                      <li>
                        <ImageCheck />
                        Skapa bokslut manuellt
                      </li>
                      <li>
                        <ImageUncheck />
                        Importera bokslut
                      </li>
                    </ul>
                  </li>
                  <li>
                    <ImageCheck />
                    Bokslutsträd&reg;
                  </li>
                  <li>
                    <ImageCheck />
                    Kontoträd
                  </li>
                  <li>
                    <ImageCheck />
                    Lönsamhetsträd
                  </li>
                  <li>
                    <ImageUncheck />
                    Support
                  </li>
                </ul>

              </div>
              <div className="product-footer">
                <hr className="product-hr" />
                <Link to="/register">
                  <div className="price-btn">
                    <button className="btn btn-green btn-sm">Skapa konto</button>
                  </div>
                </Link>
              </div>

            </div>

            <div className="product">

              <div className="product-content">

                <h1 className="product-header">Standard</h1>
                <p className="product-header-desc">För dig som vill ha full åtkomst till våra tjänster</p>
                <h1 className="product-price">Från 139 kr/mån</h1>

                <hr className="product-hr" />

                <ul>
                  <li>
                    <ImageCheck />
                    Hantera bokslut
                    <ul>
                      <li>
                        <ImageCheck />
                        Skapa bokslut manuellt
                      </li>
                      <li>
                        <ImageCheck />
                        Importera bokslut
                      </li>
                    </ul>
                  </li>
                  <li>
                    <ImageCheck />
                    Bokslutsträd&reg;
                  </li>
                  <li>
                    <ImageCheck />
                    Kontoträd
                  </li>
                  <li>
                    <ImageCheck />
                    Lönsamhetsträd
                  </li>
                  <li>
                    <ImageCheck />
                    Support
                  </li>
                </ul>

              </div>
              <div className="product-footer">
                <hr className="product-hr" />
                <Link to="/register">
                  <div className="price-btn">
                    <button className="btn btn-green btn-sm">Skapa konto</button>
                  </div>
                </Link>
              </div>

            </div>

          </div>

          <h2>Övrigt</h2>

          <div className="products">

            <div className="product">

              <div className="product-content">

                <h1 className="product-header">Utbildning och Workshop</h1>
                <p className="product-header-desc">För dig som vill ha företagsanpassad utbildning</p>

                <hr className="product-hr" />

                <ul>
                  <li>
                    <ImageCheck />
                    Ekonomins gyllene cirkel, bokslutsträd och helhets-matrisen
                  </li>
                  <li>
                    <ImageCheck />
                    Genomgång av företagets ekonomi och bokslut
                  </li>
                  <li>
                    <ImageCheck />
                    Work-shop kring visualisering av information
                    <ul>
                      <li>
                        <ImageCheck />
                        Fysiskt bokslutspussel
                      </li>
                    </ul>
                  </li>
                </ul>

              </div>
              <div className="product-footer">
                <hr className="product-hr" />
                <Link to="/contact">
                  <div className="price-btn">
                    <button className="btn btn-green btn-sm">Kontakta oss</button>
                  </div>
                </Link>
              </div>

            </div>

          </div>

        </div>
      </div>
    </div>
  );
};

export default Products;
