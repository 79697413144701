import React from "react";

import CustomHeader from "../header";
import CustomFooter from "../footer";
import MenuLeft from "../menu-left";

class Services extends React.Component {
  render() {
    return (
      <div className="wrapper-main">
        <CustomHeader />
        <div className="wrapper-content">
          <MenuLeft />
          <Chapter />
        </div>
        <CustomFooter />
      </div>
    );
  }
}

const Chapter = () => {
  return (
    <div className="page-chapters wrapper-grey">
      <div className="wrapper-chapter">
        <div className="chapter-content">

          <h2>Våra tjänster</h2>

          <div className="quick-guide-container">

            <div className="white-container margin-bottom-50">
              <div className="service-box">
                <img
                    className="guide-img"
                    src={process.env.PUBLIC_URL + "/images/icons/circle-accounting.svg"}
                    alt=""
                />
                <h3>Hantera bokslut</h3>
              </div>
              <p>
                För att börja använda våra tjänster behöver du först ange
                bokslutsdata. Det gör du enklast genom att importera en SIE-fil
                från ditt bokföringsprogram eller mata in ett bokslut manuellt.
                Genom denna tjänst får du en översikt över dina företag och bokslut.
                Här kan du lägga till nya bokslut, redigera redan
                befintliga eller ta bort bokslut du inte längre vill behålla.
              </p>
            </div>

            <div className="white-container margin-bottom-50">
              <div className="service-box">
                <img
                    className="guide-img"
                    src={process.env.PUBLIC_URL + "/images/icons/circle-tree-accounting.svg"}
                    alt=""
                />
                <h3>Bokslutsträd&reg;</h3>
              </div>
              <p>
                Med hjälp av Bokslutsträdet kan du visualisera ett bokslut i en enda bild.
                Trädets krona representerar resultaträkningen
                (<span className="fw-600 color-green-0">R</span><span className="fw-600 color-yellow-0">R</span>),
                medan trädets stam representerar bokslutets balansräkning
                (<span className="fw-600 color-blue-0">B</span><span className="fw-600 color-red-0">R</span>).
                Alla ytor är proportionella mot den post som de representerar
                och det går därför snabbt att upptäcka skillnader mellan
                bokslutets olika poster.
              </p>

              <img
                  className="guide-img"
                  src={process.env.PUBLIC_URL + "/images/icons/bokslutsträd.svg"}
                  alt=""
              />

              <p>
                Bokslutsträdet går även att använda för att jämföra bokslut från
                olika år eller skapa en prognos för ett framtida bokslut.
                Bokslutsträden kan då jämföras genom att visas skalenliga eller
                i samma storlek. Utvecklingen som skett mellan boksluten visas i
                både procent och kronor.
              </p>

            </div>

            <div className="white-container margin-bottom-50">
              <div className="service-box">
                <img
                    className="guide-img"
                    src={process.env.PUBLIC_URL + "/images/icons/circle-tree-base-accounting.svg"}
                    alt=""
                />
                <h3>Kontoträd</h3>
              </div>
              <p>
                Här kan du enkelt och visuellt klicka dig djupare bland dina konton. Färgerna följer samma logik som
                bokslutsträdet och storleken på de helfärgade cirklarna är proportioneliga.
                Det har aldrig varit så enkelt att borra i din data!
              </p>
              <img
                  className="guide-img"
                  src={process.env.PUBLIC_URL + "/images/logos/services-demo-1.png"}
                  alt=""
              />
            </div>

            <div className="white-container margin-bottom-50">
              <div className="service-box">
                <img
                    className="guide-img"
                    src={process.env.PUBLIC_URL + "/images/icons/circle-tree-key-values.svg"}
                    alt=""
                />
                <h3>Lönsamhetsträd</h3>
              </div>
              <p>
                Lönsamhetsträdet används för att få en insikt hur lönsamt ett
                företag är baserat på ett sparat bokslut. Lönsamhetsträdet
                innehåller nyckeltalen kapitalomsättningshastighet,
                Vinstmarginal, räntabilitet på totalt kapital och soliditet. Utöver att
                presentera bokslutets aktuella värden kan lönsamhetsträdet även
                användas för att se effekten av förändringar som företaget
                planerar att genomföra. Detta görs genom att ändra de
                redigerbara posterna i trädet. När en post ändras, uppdateras
                övriga trädet utifrån den givna ändringen.{" "}
              </p>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
