import {connect} from 'react-redux'
import * as userSubscriptionActions from '../../../controller/actions/user-subscription'
import UserSubscription from '../../components/user/user-subscription'

const mapStateToProps = state => ({
    userSubscription: state.reducerUserSubscription
});

const mapDispatchToProps = {
    actionUserSubscriptionGet: userSubscriptionActions.actionUserSubscriptionGet,
    actionUserSubscriptionTerminate: userSubscriptionActions.actionUserSubscriptionTerminate,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserSubscription)
