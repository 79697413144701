import $ from "jquery";

export const colorGray = ["#DDD"];
export const colorBlack = ["#000000"];
export const colorPurple = ["#A661BF"];
export const colorGreen = ["#6BC149", "#44AA33", "#2A8C3A"];
export const colorYellow = ["#FFF043", "#FADA0C", "#FCC42D"];
export const colorBlue = ["#38B1E5", "#1B8ED6", "#1569C1", "#274CA3"];
export const colorRed = ["#FF8383", "#F35656", "#E02F2F", "#B91414"];

export const empty_table = () => {

	return [
		{
			title: "Resultaträkning",
			interval: [3000, 8999],
			id: 3,
			value: 0,
			color: colorGray[0],
			type: "title",
			classes: "form-title-border",
			classesBaseAccountTree: "img-bg-tree",
			showValue: false,
			children: [
				{
					title: "Intäkter",
					interval: [3000, 3999],
					id: 30,
					value: 0,
					color: colorGreen[0],
					showAddPost: false,
					info: "Anges som positiva tal",
					children: [
						{
							title: "Rörelsens intäkter",
							interval: [3000, 3799],
							id: 300,
							value: 0,
							color: colorGreen[0]
						},
						{
							title: "Övriga intäkter",
							interval: [3800, 3999],
							id: 380,
							value: 0,
							color: colorGreen[1]
						}
					]
				},
				{
					title: "Kostnader",
					interval: [4000, 7999],
					id: 40,
					value: 0,
					color: colorYellow[1],
					showAddPost: false,
					info: "Anges som negativa tal",
					children: [
						{
							title: "Varor, materiel och tjänster",
							interval: [4000, 4999],
							id: 400,
							value: 0,
							color: colorYellow[0]
						},
						{
							title: "Övriga externa kostnader",
							interval: [5000, 6999],
							id: 500,
							value: 0,
							color: colorYellow[0]
						},
						{
							title: "Personalkostnader",
							interval: [7000, 7699],
							id: 700,
							value: 0,
							color: colorYellow[0]
						},
						{
							title: "Av-/nedskrivningar och övrigt",
							interval: [7700, 7999],
							id: 770,
							value: 0,
							color: colorYellow[1]
						}
					]
				},
				{
					title: "Rörelseresultat",
					interval: [-1, -2],
					id: 50,
					value: 0,
					color: colorBlack[0],
					type: "title",
				},
				{
					title: "Finansiella poster",
					interval: [8000, 8989],
					id: 80,
					value: 0,
					color: colorYellow[2],
					showAddPost: false,
					info: "Anges som positiva eller negativa tal",
					children: [
						{
							title: "Finansiella intäkter",
							interval: [8000, 8399],
							id: 800,
							value: 0,
							color: colorYellow[2]
						},
						{
							title: "Finansiella kostnader",
							interval: [8400, 8799],
							id: 840,
							value: 0,
							color: colorYellow[2]
						},
						{
							title: "Resultat efter finansiella poster",
							interval: [-1, -2],
							id: 85,
							value: 0,
							color: colorBlack[0],
							type: "title",
						},
						{
							title: "Bokslutsdispositioner",
							interval: [8800, 8899],
							id: 880,
							value: 0,
							color: colorYellow[2]
						},
						{
							title: "Skatt",
							interval: [8900, 8989],
							id: 890,
							value: 0,
							color: colorYellow[2]
						}
					]
				},
				{
					title: "Årets resultat",
					interval: [8990, 8999],
					id: 90,
					value: 0,
					color: colorBlack[0],
					type: "title",
				}
			]
		},
		{
			title: "Balansräkning",
			interval: [1000, 2999],
			id: 1,
			value: 0,
			color: colorGray[0],
			type: "title",
			classes: "form-title-border",
			showValue: false,
			children: [
				{
					title: "Tillgångar",
					interval: [1000, 1999],
					id: 10,
					value: 0,
					color: colorBlue[0],
					showAddPost: false,
					info: "Anges som positiva tal",
					children: [
						{
							title: "Anläggningstillgångar",
							interval: [1000, 1399],
							id: 100,
							value: 0,
							color: colorBlue[0]
						},
						{
							title: "Lager",
							interval: [1400, 1499],
							id: 140,
							value: 0,
							color: colorBlue[1]
						},
						{
							title: "Fodringar/placeringar",
							interval: [1500, 1899],
							id: 150,
							value: 0,
							color: colorBlue[2]
						},
						{
							title: "Kassa och bank",
							interval: [1900, 1999],
							id: 190,
							value: 0,
							color: colorBlue[3]
						}
					]
				},
				{
					title: "Skulder",
					interval: [2000, 2999],
					value: 0,
					id: 20,
					color: colorRed[1],
					classes: "form-title-margin-top",
					showAddPost: false,
					info: "Anges som positiva tal",
					children: [
						{
							title: "Eget kapital",
							interval: [2000, 2099],
							id: 200,
							value: 0,
							color: colorRed[0]
						},
						{
							title: "Obeskattade reserver",
							interval: [2100, 2199],
							id: 210,
							value: 0,
							color: colorRed[1]
						},
						{
							title: "Långa skulder",
							interval: [2200, 2399],
							id: 220,
							value: 0,
							color: colorRed[2]
						},
						{
							title: "Korta skulder",
							interval: [2400, 2999],
							id: 240,
							value: 0,
							color: colorRed[3]
						}
					]
				}
			]
		}
	];
};

export const dataToTree = function (data) {

	let table = fillTemplate(data);
	table = parseAccountingDataColorSumPosts(table);

	return {
		pie: calculateTreePieData(table[0]),
		bar: calculateTreeBarData(table[1]),
		label_currency: "kr",
		label_unit: ""
	};

};

const calculateTreePieData = (accountingData) => {

	let data = {
		right: {
			"Kostnader": getEntryByLabel(accountingData, "Kostnader", []),
			"Finansiella kostnader": getEntryByLabel(accountingData, "Finansiella kostnader", []),
		},
		left: {
			"Intakter": getEntryByLabel(accountingData, "Intäkter", []),
			"Finansiella intakter": getEntryByLabel(accountingData, "Finansiella intäkter", [])
		},
		pieRadius: 300,
	};

	let bokslutsdispositioner = getEntryByLabel(accountingData, "Bokslutsdispositioner", []);
	let skatt = getEntryByLabel(accountingData, "Skatt", []);

	if (bokslutsdispositioner.value < 0) {
		data.right["Bokslutsdispositioner"] = bokslutsdispositioner;
	} else {
		data.left["Bokslutsdispositioner"] = bokslutsdispositioner;
	}

	if (skatt.value < 0) {
		data.right["Skatt"] = skatt;
	} else {
		data.left["Skatt"] = skatt;
	}

	// calculate area and sums

	let pieArea = 0;
	let pieSum = 0;

	for (let key in data.left) {
		if (data.left.hasOwnProperty(key)) {
			let value = data.left[key].value;
			pieArea += Math.abs(value);
			pieSum += value;
		}
	}

	for (let key in data.right) {
		if (data.right.hasOwnProperty(key)) {
			let value = data.right[key].value;
			pieArea += Math.abs(value);
			pieSum += value;
		}
	}

	// calculate pie radius

	data.pieRadius = Math.sqrt(pieArea / Math.PI);

	// calculate win / loss

	if (pieSum > 0) {
		data.right["Resultat"] = {
			title: "Resultat",
			value: pieSum,
			sign: "+",
			color: colorPurple[0],
			children: {
				Vinst: {
					title: "Vinst",
					value: pieSum,
					sign: "+",
					color: colorPurple[0],
				}
			}
		};
	} else {
		data.left["Resultat"] = {
			title: "Resultat",
			value: pieSum,
			sign: "-",
			color: colorBlack[0],
			children: {
				Forlust: {
					title: "Förlust",
					value: pieSum,
					sign: "-",
					color: colorBlack[0],
				}
			}
		};
	}

	return data;

};

const calculateTreeBarData = (accountingData) => {

	let data = {
		right: {
			"Skulder": getEntryByLabel(accountingData, "Skulder", [])
		},
		left: {
			"Tillgangar": getEntryByLabel(accountingData, "Tillgångar", [])
		},
		colHeight: 100,
		colWidth: 20,
		solidity: 0.1,
	};

	let obeskattadeReserver = getEntryByLabel(data.right["Skulder"], "Obeskattade reserver", []).value;

	let delKapital = 0;
	if (obeskattadeReserver != null) {
		delKapital = obeskattadeReserver * 0.78;
	}

	let justeratEgetKapital = getEntryByLabel(data.right["Skulder"], "Eget kapital", []).value + delKapital;
	let soliditet = Math.abs(justeratEgetKapital / data.left["Tillgangar"].value);

	// soliditet får inte vara lägre än 10%

	if (soliditet < 0.1) {
		data.solidity = 0.1;
	} else {
		data.solidity = soliditet;
	}

	data.colHeight = Math.abs(Math.sqrt(Math.abs(2 * data.left["Tillgangar"].value) / data.solidity));
	data.colWidth = Math.abs(data.solidity * data.colHeight) / 2;

	return data;

};

export const parseData = (treeData) => {
	let tempData = {};
	let data = [];

	if (treeData != null) {
		Object.keys(treeData).forEach(function (key) {
			if (treeData[key].hasOwnProperty("children")) {
				Object.keys(treeData[key].children).forEach(function (key2) {
					tempData[key2] = treeData[key].children[key2];
				});
			} else {
				tempData[key] = treeData[key];
			}
		});
	}
	if (tempData != null) {
		Object.keys(tempData).forEach(function (key) {
			tempData[key].key = key;
			data.push(tempData[key]);
		});
	}

	data.sort(function (a, b) {
		return a.key - b.key;
	});

	return data;
};

export const getEntryByLabel = (entry, label, back) => {
	if (entry.title === label) {
		return entry;
	} else if (entry.hasOwnProperty("children")) {
		entry.children.forEach(function (child) {
			back.push(child);
		});
	}
	return getEntryByLabel(back.pop(), label, back);
};

export const fillTemplate = data => {

	let table = empty_table();

	Object.keys(data).forEach(function (key) {
		table.forEach(function (entry) {
			propagateSum(entry, data[key]);
		});
	});

	return table;
};

function propagateSum(entry, data) {

	if (isBetween(data.bsNumber, entry["interval"][0], entry["interval"][1])) {
		if (entry.hasOwnProperty("children")) {
			entry.children.forEach(function (child) {
				propagateSum(child, data);
			});
			entry.value += data.value;
		} else {
			entry.value += data.value;
			if (!entry.hasOwnProperty("dynamic_children")) {
				entry.dynamic_children = [];
			}
			data["title"] = data["name"];
			entry.dynamic_children.push(data);
		}
	}

}


// replaces "dynamic_children" element with "children"
export const parseTemplate = table => {
	Object.keys(table).forEach(function (key) {
		if (table[key].hasOwnProperty("children")) {
			parseTemplate(table[key].children);
		} else if (table[key].hasOwnProperty("dynamic_children")) {
			table[key].children = table[key].dynamic_children;
			delete table[key].dynamic_children;
		}
	});

	return table;
};

export const parseAccountingData = (data) => {

	let table = fillTemplate(data);

	let newData = {
		title: "",
		children: table
	};

	newData = accountingDataPostUpdateValue(
		newData,
		"Rörelseresultat",
		getNodeValueByTitle(table, "Intäkter") + getNodeValueByTitle(table, "Kostnader")
	);
	newData = accountingDataPostUpdateValue(
		newData,
		"Årets resultat",
		getNodeValueByTitle(table, "Rörelseresultat") + getNodeValueByTitle(table, "Finansiella poster")
	);

	newData = accountingDataPostUpdateValue(
		newData,
		"Finansiella poster",
		getNodeValueByTitle(table, "Finansiella intäkter") + getNodeValueByTitle(table, "Finansiella kostnader")
	);

	newData = accountingDataPostUpdateValue(
		newData,
		"Resultat efter finansiella poster",
		getNodeValueByTitle(table, "Intäkter") + getNodeValueByTitle(table, "Kostnader")
		+ getNodeValueByTitle(table, "Finansiella poster")
	);

	return newData;

};

export const parseAccountingDataColorSumPosts = (data) => {

	let newData = {
		title: "",
		children: data
	};

	let post;

	post = "Årets resultat";
	if (getNodeValueByTitle(data, post) < 0) {
		newData = accountingDataPostUpdateColor(newData, post, colorBlack[0]);
	} else {
		newData = accountingDataPostUpdateColor(newData, post, colorPurple[0]);
	}

	post = "Finansiella poster";
	if (getNodeValueByTitle(data, post) < 0) {
		newData = accountingDataPostUpdateColor(newData, post, colorYellow[2]);
	} else {
		newData = accountingDataPostUpdateColor(newData, post, colorGreen[2]);
	}

	post = "Finansiella intäkter";
	if (getNodeValueByTitle(data, post) < 0) {
		newData = accountingDataPostUpdateColor(newData, post, colorYellow[2]);
	} else {
		newData = accountingDataPostUpdateColor(newData, post, colorGreen[2]);
	}

	post = "Resultat efter finansiella poster";
	if (getNodeValueByTitle(data, post) < 0) {
		newData = accountingDataPostUpdateColor(newData, post, colorYellow[2]);
	} else {
		newData = accountingDataPostUpdateColor(newData, post, colorGreen[2]);
	}

	post = "Bokslutsdispositioner";
	if (getNodeValueByTitle(data, post) < 0) {
		newData = accountingDataPostUpdateColor(newData, post, colorYellow[2]);
	} else {
		newData = accountingDataPostUpdateColor(newData, post, colorGreen[2]);
	}

	post = "Skatt";
	if (getNodeValueByTitle(data, post) < 0) {
		newData = accountingDataPostUpdateColor(newData, post, colorYellow[2]);
	} else {
		newData = accountingDataPostUpdateColor(newData, post, colorGreen[2]);
	}

	return newData.children;

};

export const parseAccountingDataFinancialPostsColors = (data) => {

	Object.keys(data).forEach(function (key) {
		if (data[key].hasOwnProperty("children")) {
			parseAccountingDataFinancialPostsColors(data[key].children)
		} else {
			if (isBetween(data[key].bsNumber, 8000, 8999)) {
				if (data[key].value < 0) {
					data[key].color = colorYellow[2];
				} else {
					data[key].color = colorGreen[2];
				}
			}
		}
	});

};


/*
 * Removes the summation posts
 */
export const convertTemplateForDisplay = table => {
	Object.keys(table).forEach(function (key) {
		if (table[key].id === 3) {
			table[key].value = "";
			if (table[key].hasOwnProperty("children")) {
				table[key].children = removeFromArrayById(table[key].children, 50);
				Object.keys(table[key].children).forEach(function (key2) {
					if (table[key].children[key2].id === 80) {
						if (table[key].children[key2].hasOwnProperty("children")) {
							table[key].children[key2].children = removeFromArrayById(table[key].children[key2].children, 85);
						}
					}
				});
			}
		}
		if (table[key].id === 1) {
			table[key].value = "";
		}
	});
	return table;
};

export const inheritColorAndValueFromParents = (table, parent) => {
	Object.keys(table).forEach(function (key) {
		if (table[key].hasOwnProperty("children")) {
			inheritColorAndValueFromParents(table[key].children, table[key]);
		} else {
			if (parent.hasOwnProperty("color")) {
				table[key].color = parent.color;
			} else {
				table[key].color = "#fff";
			}
			if (parent.hasOwnProperty("value")) {
				table[key].parentValue = parent.value;
			} else {
				table[key].parentValue = 0;
			}
		}
	});
	return table;
};

export const removeFromArrayById = (array, id) => {
	return $.map(array, function (v) {
		return v["id"] === id ? null : v;
	});
};


export const numberWithSpaces = function (x) {
	if (x === null) {
		return "N/A";
	} else {
		return (parseFloat(x).toFixed(0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
	}
};

export const numberWithSpacesAndDecimals = function (x, decimals) {
	if (x === null) {
		return "N/A";
	} else {
		return (parseFloat(x).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
	}
};

export const numberWithSpacesCompare = function (x) {

	if (x === null) {
		return "N/A";
	}

	let value = (parseFloat(x).toFixed(0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");

	if (x > 0) {
		return "+" + value;
	} else {
		return value;
	}

};

export const numberWithCommas = function (x) {
	if (x === null) {
		return "N/A";
	} else {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}
};

export const valueFormatterCompare = function (value) {
	if (value === "Infinity") {
		return 0;
	} else if (value > 0) {
		value = parseFloat(value).toFixed(0);
		return "+" + numberWithSpaces(value);
	} else {
		value = parseFloat(value).toFixed(0);
		return numberWithSpaces(value);
	}
};

export const valueFormatter = function (value) {
	if (value === "Infinity") {
		return "N/A";
	} else {
		value = parseFloat(value).toFixed(0);
		return numberWithCommas(value);
	}
};

export const getNodeByTitle = function (data, title) {
	let reduce = [].reduce;

	function runner(result, data) {
		if (result || !data) {
			result = JSON.parse(JSON.stringify(result));
			if (result != null && result.hasOwnProperty("children")) {
				delete result.children;
			}
			return result;
		}
		return (
			(String(data.title).toLowerCase() === String(title).toLowerCase() &&
				data) || //is this the proper node?
			runner(null, data.children) || //process this nodes children
			reduce.call(Object(data), runner, result)
		); //maybe this is some ArrayLike Structure
	}

	return runner(null, data);
};

export const getNodeValueByTitle = function (data, title) {
	let node = getNodeByTitle(data, title);
	if (node != null && node.hasOwnProperty("value")) {
		return parseInt(node.value);
	} else {
		console.log("Error: " + title + " is null, value is set to 0");
		return 0;
	}
};

export const accountingDataPostUpdateValue = function (tree, title, newValue) {
	if (String(tree.title).toLowerCase() === String(title).toLowerCase()) {
		tree.value = newValue
	} else if (tree.hasOwnProperty("children")) {
		tree.children = tree.children.map(function (item) {
			return accountingDataPostUpdateValue(item, title, newValue)
		})
	}
	return tree
};

export const accountingDataPostUpdateColor = function (tree, title, newColor) {
	if (String(tree.title).toLowerCase() === String(title).toLowerCase()) {
		tree.color = newColor
	} else if (tree.hasOwnProperty("children")) {
		tree.children = tree.children.map(function (item) {
			return accountingDataPostUpdateColor(item, title, newColor)
		})
	}
	return tree
};

export const generateHash = function (length) {
	let result = '';
	let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	let charactersLength = characters.length;
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
};

export const calculatePercentage = function (a, b, numDecimals) {
	let value = (a / b) * 100;
	return roundFloat(value, numDecimals);
};

export const calculateDifferencePercentage = function (a, b) {
	if (a === 0 || b === 0) {
		return 0;
	} else {
		return ((a - b) / b) * 100;
	}
};

export const calculateDifferencePercentageFlipped = function (a, b) {
	if (a === 0 || b === 0) {
		return 0;
	} else {
		return ((a - b) / a) * 100;
	}
};

export const calculateDifferenceValue = function (a, b) {
	return a - b;
};

export const isBetween = (x, min, max) => {
	return x >= min && x <= max;
};

export const parseAccountingPostValue = (value) => {
	let parsedValue = parseInt(value);
	if (Number.isNaN(parsedValue)) {
		return null;
	} else {
		return parsedValue;
	}
};

export const preRenderTree = (data, colHeight) => {
	let sum_money = 0;
	let sum_pixels = 0;
	data.forEach(function(e) {
		sum_money += e.value;
	});
	data.forEach(function(e) {
		e.height = Math.round((e.value / sum_money) * colHeight);
		if(e.height < 0) {
			e.height = 0;
		}
	});
	data.forEach(function(e) {
		sum_pixels += e.height;
	});
	data[data.length - 1].height += colHeight - sum_pixels;
	if(data[data.length - 1].height < 0) {
		data[data.length - 1].height = 0;
	}
	return data;
};

/*
 * This function sets the primary key of newly inserted posts to 0.
 * In the backend, each new key must have pk=0 and flag=1 to be inserted into the database.
 */
export const accountingDataParseNewPosts = (state) => {
	Object.keys(state.data).forEach(function (k) {
		if (state.data[k].flag === 1) {
			state.data[k].pk = 0;
		}
	});
};

/*
 * This function changes the sign of every post in the data in specific intervals.
 */
export const accountingDataParseSign = (data) => {

	let state = JSON.parse(JSON.stringify(data));

	for (let key in state) {
		if (state.hasOwnProperty(key)) {
			if (state[key].pk != null && accountingDataParseSignCheckInterval(state[key].bsNumber)) {
				state[key].value = -state[key].value;
			}
		}
	}

	return state;

};

const accountingDataParseSignCheckInterval = (bsNumber) => {

	// these intervals should be the same as the ones in the table above

	return (
		isBetween(bsNumber, 3000, 3999) ||
		isBetween(bsNumber, 4000, 7999) ||
		isBetween(bsNumber, 8000, 8989) ||
		isBetween(bsNumber, 2000, 2999)
	);

};

export const roundFloat = (value, places) => {
	let roundedValue = +(Math.round(value + "e+" + places) + "e-" + places);
	if (Number.isNaN(roundedValue)) {
		return 0;
	} else {
		return roundedValue;
	}
};

export const roundFloatShowZeros = (value, numDecimals) => {
	return Number((value).toFixed(numDecimals))
};

export const downloadImage = (id, name) => {
	if ($(id).length) {
		let svgData = $(id)[0].outerHTML;
		let svgBlob = new Blob([svgData], {type: "image/svg+xml;charset=utf-8"});
		let svgUrl = URL.createObjectURL(svgBlob);
		let downloadLink = document.createElement("a");
		downloadLink.href = svgUrl;
		downloadLink.download = name;
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
	}
};

export const convertTime = (dateString) => {
	let options = {
		year: "numeric", month: "long", day: "numeric",
		hour: "2-digit", minute: "2-digit", second: "2-digit"
	};
	return new Date(dateString).toLocaleTimeString("sv-se", options);
};

export const formatCash = n => {
	if (n < 1e6) return formatCashJSON(n, "SEK");
	if (n >= 1e3 && n < 1e6) return formatCashJSON(+(n / 1e3).toFixed(1), "k");
	if (n >= 1e6 && n < 1e9) return formatCashJSON(+(n / 1e6).toFixed(1), "m");
	if (n >= 1e9 && n < 1e12) return formatCashJSON(+(n / 1e9).toFixed(1), "M");
	if (n >= 1e12) return formatCashJSON(+(n / 1e12).toFixed(1), "B");
};

export const formatCashJSON = (value, unit) => {
	return {
		value: value,
		unit: unit
	}
};

export const getData = (accounting, accountingDatas, companyID) => {

	let data = [];

	for (let key in accounting) {


		if (accounting.hasOwnProperty(key) && (accounting[key].company === companyID)) {

			let accountingData = [];

			for (let key2 in accountingDatas) {
				if (accountingDatas.hasOwnProperty(key2)) {
					if(accountingDatas[key2][0].accounting === accounting[key].pk) {
						accountingData = accountingDatas[key2]
					}
				}
			}

			let accountingDataParsed = parseAccountingData(accountingDataParseSign(accountingData));

			let intakter = getNodeValueByTitle(accountingDataParsed, "Intäkter");

			let resultat = getNodeValueByTitle(accountingDataParsed, "Årets resultat");

			data.push({
				"year": getYear(accounting[key].periodStart),
				"posts": [
					{
						"title": "Intäkter",
						"value": intakter,
					},
					{
						"title": "Resultat",
						"value": resultat,
					},
				],
				"data": accountingData
			})
		}
	}

	data = data.sort((a,b) => {
		return a.year > b.year ? -1 : 1 ;
	});

	data = data.slice(0, 4);

	return data;

};

export const calculateKeyValues = (accountingData) => {

	accountingData = accountingDataParseSign(accountingData);
	let rawTable = fillTemplate(accountingData);
	let data = parseTemplate(rawTable);
	let json = {};

	json["Rörelsens intäkter"] = getNodeValueByTitle(data, "Rörelsens intäkter");
	json["Övriga intäkter"] = getNodeValueByTitle(data, "Övriga intäkter");
	json["Varor, materiel och tjänster"] = getNodeValueByTitle(data, "Varor, materiel och tjänster");
	json["Övriga externa kostnader"] = getNodeValueByTitle(data, "Övriga externa kostnader");
	json["Personalkostnader"] = getNodeValueByTitle(data, "Personalkostnader");
	json["Av-/nedskrivningar och övrigt"] = getNodeValueByTitle(data, "Av-/nedskrivningar och övrigt");
	json["Finansiella intäkter"] = getNodeValueByTitle(data, "Finansiella intäkter");
	json["Anläggningstillgångar"] = getNodeValueByTitle(data, "Anläggningstillgångar");
	json["Lager"] = getNodeValueByTitle(data, "Lager");
	json["Fodringar/placeringar"] = getNodeValueByTitle(data, "Fodringar/placeringar");
	json["Kassa och bank"] = getNodeValueByTitle(data, "Kassa och bank");
	json["Eget kapital"] = getNodeValueByTitle(data, "Eget kapital");
	json["Obeskattade reserver"] = getNodeValueByTitle(data, "Obeskattade reserver");

	return json;

};

export const calculateKeyValuesSum = (data) => {

	let json = JSON.parse(JSON.stringify(data));
	let json2 = calculateKeyValuesSum2(json);

	for (let key in json2) {
		json[key] = json2[key];
	}

	return json;

};

export const calculateKeyValuesSum2 = (data) => {

	let json = JSON.parse(JSON.stringify(data));
	let json2 = {};

	let TAX_FACTOR = 0.78;

	// level 2
	json2["Intäkter"] = json["Rörelsens intäkter"] + json["Övriga intäkter"];
	json2["Kostnader"] = json["Varor, materiel och tjänster"] + json["Övriga externa kostnader"] + json["Personalkostnader"];
	json2["Avskrivningar och ränteintäkter"] = json["Finansiella intäkter"]+json["Av-/nedskrivningar och övrigt"];
	json2["Omsättningstillgångar"] = json["Lager"] + json["Fodringar/placeringar"] + json["Kassa och bank"];
	json2["Justerat eget kapital"] = json["Eget kapital"] + Math.round(TAX_FACTOR * json["Obeskattade reserver"]);

	// level 3
	json2["Resultat före räntekostnader"] = json2["Intäkter"] + json2["Kostnader"] + json2["Avskrivningar och ränteintäkter"];
	json2["Tillgångar"] = json2["Omsättningstillgångar"] + json["Anläggningstillgångar"];

	// level 4
	json2["Vinstmarginal"] = parseToPercent(json2["Resultat före räntekostnader"] / json["Rörelsens intäkter"]);
	json2["Kapitalomsättningshastighet"] = parseToFloat(json["Rörelsens intäkter"] / json2["Tillgångar"]);
	json2["Soliditet"] = parseToPercent(json2["Justerat eget kapital"] / json2["Tillgångar"]);

	// level 5
	json2["Räntabilitet"] = parseToFloat(json2["Vinstmarginal"] * json2["Kapitalomsättningshastighet"]);

	return json2;

};

export const parseToPercent = (x) => {
	return Number.parseFloat(x*100);
};

export const parseToFloat = (x) => {
	return Number.parseFloat(x);
};

export const getYear = (text) => {
	return /\d{4}/.test(text) ? text.replace(/^[^\d]*(\d{4}).*$/, '$1') : '-1';
};

export const sortDataByKeyAsc = (data, key) => {
	return data.sort((a,b) => {
		return a[key] > b[key] ? -1 : 1 ;
	});
};

export const sortDataByKeyDesc = (data, key) => {
	return data.sort((a,b) => {
		return a[key] < b[key] ? -1 : 1 ;
	});
};

export const getLatestAccounting = (arr) => {
	if(arr.length > 0) {
		return arr.sort((a, b) => parseFloat(a.date) > parseFloat(b.date))[0];
	} else {
		return null
	}
};

export const getAccounting = (arr, num) => {
	if(arr.length > num) {
		return arr.sort((a, b) => parseFloat(a.date) > parseFloat(b.date))[num];
	} else {
		return null
	}
};

export const renderResultColor = (value) => {
	if(value > 0) {
		return colorPurple[0]
	} else {
		return colorBlack[0]
	}
};

export const renderPostColor = (value) => {
	return colorGreen[1]
};