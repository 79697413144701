import React from "react";
import CustomHeader from "../../containers/header";
import CustomFooter from "../../containers/footer";
import { Link } from "react-router-dom";

class Register extends React.Component {

  constructor(props) {
    super(props);

    this.handleInput = this.handleInput.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      email: "",
      password1: "",
      password2: "",
      agree: false,
      agreeError: ""
    };
  }

  componentDidMount() {
    this.props.actionUserReset();
  }

  handleInput(e) {
    const key = e.target.name;
    const value = e.target.value;

    let new_state = JSON.parse(JSON.stringify(this.state));
    new_state[key] = value;
    this.setState(new_state);
  }

  handleCheckbox(e) {
    const value = e.target.checked;

    let new_state = JSON.parse(JSON.stringify(this.state));
    new_state.agree = value;
    this.setState(new_state);
  }

  handleSubmit(e) {
    e.preventDefault();

    if (this.state.agree) {
      let new_state = JSON.parse(JSON.stringify(this.state));
      new_state.agreeError = null;
      this.setState(new_state);

      const form_data = JSON.stringify(this.state);

      this.props.actionUserRegister(form_data);
    } else {
      let new_state = JSON.parse(JSON.stringify(this.state));
      new_state.agreeError = "Du har inte accepterat villkoren";
      this.setState(new_state);
    }
  }

  render() {

    let { user } = this.props;

    return (
      <div className="wrapper-main">
        <CustomHeader />
        <div className="page-chapters">
          <div className="wrapper-chapter">
            <div className="chapter-content">
              <h2>Registrera ett konto</h2>
              <p className="chapter-content-info">
                Vad roligt att du vill testa vår tjänst att visualisera data på nya och färgglada sätt!<br />
                Berätta gärna vad som fungerar bra och skapar värde för er samt tipsa <br />
                om förbättringar och förbättrade funktioner till <a href="mailto:info@umesee.se">info@umesee.se</a>
              </p>
              <div>
                <form onSubmit={this.handleSubmit} className="form-account">
                  <div>
                    <div>
                      <label>E-post</label>
                    </div>
                    <div>
                      <input
                        name="email"
                        placeholder="E-post"
                        type="text"
                        value={this.state.email}
                        onChange={this.handleInput}
                      />
                    </div>
                    {user.error && (
                      <div className="page__error">{user.error.email}</div>
                    )}
                  </div>
                  <div>
                    <div>
                      <label>Lösenord</label>
                    </div>
                    <div>
                      <input
                        name="password1"
                        placeholder="Lösenord"
                        type="password"
                        value={this.state.password1}
                        onChange={this.handleInput}
                      />
                    </div>
                    {user.error && (
                      <div className="page__error">{user.error.password1}</div>
                    )}
                  </div>
                  <div>
                    <div>
                      <label>Verifiera lösenord</label>
                    </div>
                    <div>
                      <input
                        name="password2"
                        placeholder="Verifiera lösenord"
                        type="password"
                        value={this.state.password2}
                        onChange={this.handleInput}
                      />
                    </div>
                    {user.error && (
                      <div className="page__error">{user.error.password2}</div>
                    )}
                  </div>
                  <div>
                    <div>
                      <label>Användarvillkor</label>
                    </div>
                    <div>
                      <label className="signup-agreement-label">
                        <input
                          type="checkbox"
                          className="signup-checkbox"
                          defaultChecked={this.state.agree}
                          onChange={this.handleCheckbox}
                        />
                        Jag godkänner
                        <Link
                          className="text-link"
                          to="/terms-of-use"
                          target="_blank"
                        >
                          användarvillkoren
                        </Link>
                      </label>
                    </div>
                    {this.state.agreeError && (
                      <div className="page__error">{this.state.agreeError}</div>
                    )}
                  </div>
                  <button type="submit" className="btn btn-green btn-lg">
                    Registrera
                  </button>
                  {user.error && (
                    <div className="page__error">
                      {user.error.non_field_errors}
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
        <CustomFooter />
      </div>
    );
  }
}

export default Register;
