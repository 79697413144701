import { connect } from "react-redux";
import * as actions from "../../../controller/actions/accounting";

import AccountingTree from "../../components/graphs/accounting-tree/accounting-tree";

const mapStateToProps = state => ({
  companies: state.reducerCompany,
  accountings: state.reducerAccounting,
  accountingData: state.reducerAccountingData
});

const mapDispatchToProps = {
  actionCompaniesGet: actions.actionCompaniesGet
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountingTree);
