import {connect} from 'react-redux'
import * as userProfileActions from '../../../controller/actions/user-profile'
import * as userSubscriptionActions from '../../../controller/actions/user-subscription'
import UserSubscriptionHistory from '../../components/user/user-subscription-history'

const mapStateToProps = state => ({
    user: state.reducerUser,
    userProfile: state.reducerUserProfile,
    userSubscription: state.reducerUserSubscription
});

const mapDispatchToProps = {
    actionUserProfileGet: userProfileActions.actionUserProfileGet,
    actionUserSubscriptionOrderGet: userSubscriptionActions.actionUserSubscriptionOrderGet
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserSubscriptionHistory)
