import React from "react";
import $ from "jquery";
import {
  numberWithSpaces,
  parseAccountingPostValue, numberWithSpacesAndDecimals, calculateKeyValues, calculateKeyValuesSum
} from "../../utils";

class KeyValuesTree extends React.Component {

  constructor(props) {

    super(props);

    let keyValues = calculateKeyValuesSum(calculateKeyValues(this.props.accountingData));

    this.state = {
      keyValues: keyValues,
    };

  }

  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip({
      trigger: "hover"
    });
  }

  getKeyPost(key) {
    if(this.state.keyValues.hasOwnProperty(key)) {
      return this.state.keyValues[key];
    } else {
      return 0;
    }
  }

  getKeyPostFormatted(key) {
    return numberWithSpaces(this.getKeyPost(key));
  }

  getKeyPostFormattedDecimals(key) {
    return numberWithSpacesAndDecimals(this.getKeyPost(key), 2);
  }

  listenerChangeData(e, key) {

    let value = parseAccountingPostValue(e.target.value);
    let newState = JSON.parse(JSON.stringify(this.state));

    if(newState.keyValues.hasOwnProperty(key)) {
      newState.keyValues[key] = value;
    } else {
      newState.keyValues[key] = 0;
    }

    newState.keyValues = calculateKeyValuesSum(newState.keyValues);

    console.log(newState);

    this.setState(newState);

  }

  render() {

    return (
      <div id="key-tree-wrapper">
        <div id="root-left">
          <div className="key-tree-branch l1">
            <div className="key-tree-entry">
              <div className="key-label">
                <p>Vm (%)</p>
                <p className="key-value">
                  {this.getKeyPostFormattedDecimals("Vinstmarginal")}
                </p>
                <img
                  className="action-icon"
                  src={
                    process.env.PUBLIC_URL + "/images/icons/info-button.svg"
                  }
                  data-toggle="tooltip"
                  data-placement="top"
                  data-html="true"
                  title={
                    "Vinstmarginal <br/>" +
                    '= Resultat före räntekostnader &#247; Rörelsens intäkter <div style="height:8px;font-size:8px;">&nbsp;</div> ' +
                    "Vinstmarginal visar ett företags lönsamhet utan hänsyn till hur den är finansierad."
                  }
                  alt=""
                />
              </div>
              <div className="key-tree-branch l2">
                <div className="key-tree-entry key-tree-entry-top">
                  <div className="key-tree-label">
                    <p className="key-value">
                      {this.getKeyPostFormatted("Resultat före räntekostnader")}
                    </p>
                    <img
                      className="action-icon"
                      src={
                        process.env.PUBLIC_URL +
                        "/images/icons/info-button.svg"
                      }
                      data-toggle="tooltip"
                      data-placement="top"
                      data-html="true"
                      title={"Resultat före räntekostnader"}
                      alt=""
                    />
                  </div>
                  <div className="key-tree-branch l3">
                    <div className="key-tree-entry">
                      <div className="key-tree-label">
                        <p className="key-value">
                          {this.getKeyPostFormatted("Intäkter")}
                        </p>
                        <img
                          className="action-icon"
                          src={
                            process.env.PUBLIC_URL +
                            "/images/icons/info-button.svg"
                          }
                          data-toggle="tooltip"
                          data-placement="top"
                          data-html="true"
                          title={"Intäkter"}
                          alt=""
                        />
                      </div>
                      <div className="key-tree-branch l4">
                        <div className="key-tree-entry key-tree-input">
                          <div className="tree-half-left tree-coloring r-i" />
                          <label>Rörelsens intäkter</label>
                          <input
                            className="label-input"
                            value={this.getKeyPost("Rörelsens intäkter")}
                            onChange={e => this.listenerChangeData(e, "Rörelsens intäkter")}
                          />
                        </div>
                        <div className="key-tree-entry key-tree-input">
                          <div className="tree-half-left tree-coloring ö-i" />
                          <label>Övriga intäkter</label>
                          <input
                            className="label-input"
                            value={this.getKeyPost("Övriga intäkter")}
                            onChange={e => this.listenerChangeData(e, "Övriga intäkter")}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="key-tree-entry">
                      <div className="key-tree-label">
                        <p className="key-value">
                          {this.getKeyPostFormatted("Kostnader")}
                        </p>
                        <img
                          className="action-icon"
                          src={
                            process.env.PUBLIC_URL +
                            "/images/icons/info-button.svg"
                          }
                          data-toggle="tooltip"
                          data-placement="top"
                          data-html="true"
                          title={"Kostnader"}
                          alt=""
                        />
                      </div>
                      <div className="key-tree-branch l4">
                        <div className="key-tree-entry key-tree-input">
                          <div className="tree-half-right tree-coloring" />
                          <label>Varor, materiel & tjänster</label>
                          <input
                            className="label-input"
                            value={this.getKeyPost("Varor, materiel och tjänster")}
                            onChange={e => this.listenerChangeData(e, "Varor, materiel och tjänster")}
                          />
                        </div>
                        <div className="key-tree-entry key-tree-input">
                          <div className="tree-half-right tree-coloring" />
                          <label>Övriga externa kostnader</label>
                          <input
                            className="label-input"
                            value={this.getKeyPost("Övriga externa kostnader")}
                            onChange={e => this.listenerChangeData(e, "Övriga externa kostnader")}
                          />
                        </div>
                        <div className="key-tree-entry key-tree-input">
                          <div className="tree-half-right tree-coloring" />
                          <label>Personalkostnader</label>
                          <input
                            className="label-input"
                            value={this.getKeyPost("Personalkostnader")}
                            onChange={e => this.listenerChangeData(e, "Personalkostnader")}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="key-tree-entry">
                      <div className="key-tree-label">
                        <p className="key-value">
                          {this.getKeyPostFormatted("Avskrivningar och ränteintäkter")}
                        </p>
                        <img
                          className="action-icon"
                          src={
                            process.env.PUBLIC_URL +
                            "/images/icons/info-button.svg"
                          }
                          data-toggle="tooltip"
                          data-placement="top"
                          data-html="true"
                          title={"Avskrivningar och ränteintäkter"}
                          alt=""
                        />
                      </div>
                      <div className="key-tree-branch l4">
                        <div className="key-tree-entry key-tree-input">
                          <div className="tree-half-right tree-coloring" />
                          <label>Av-/nedskrivningar & övrigt</label>
                          <input
                            className="label-input"
                            value={this.getKeyPost("Av-/nedskrivningar och övrigt")}
                            onChange={e => this.listenerChangeData(e, "Av-/nedskrivningar och övrigt")}
                          />
                        </div>
                        <div className="key-tree-entry key-tree-input">
                          <div className="tree-half-left tree-coloring" />
                          <label>Finansiella intäkter</label>
                          <input
                            className="label-input"
                            value={this.getKeyPost("Finansiella intäkter")}
                            onChange={e => this.listenerChangeData(e, "Finansiella intäkter")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/*<div className="key-tree-entry center-entry">
                  <div className="key-tree-label center-label">
                    <p className="key-value">
                      {this.getKeyPostFormatted("Rörelsens intäkter")}
                    </p>
                    <img
                      className="action-icon"
                      src={
                        process.env.PUBLIC_URL +
                        "/images/icons/info-button.svg"
                      }
                      data-toggle="tooltip"
                      data-placement="top"
                      data-html="true"
                      title={"Rörelsens intäkter"}
                      alt=""
                    />
                  </div>
                </div>*/}
              </div>
            </div>
            <div className="key-tree-entry key-tree-entry-last-visible-child">
              <div className="key-label">
                <p>KOH (ggr)</p>
                <p className="key-value">
                  {this.getKeyPostFormattedDecimals("Kapitalomsättningshastighet")}
                </p>
                <img
                  className="action-icon"
                  src={
                    process.env.PUBLIC_URL + "/images/icons/info-button.svg"
                  }
                  data-toggle="tooltip"
                  data-placement="top"
                  data-html="true"
                  title={
                    "Kapitalomsättningshastighet <br/>" +
                    ' = Rörelsens intäkter &#247; Tillgångar <div style="height:8px;font-size:8px;">&nbsp;</div> ' +
                    "KOH är ett mått som visar hur " +
                    "mycket kapital en verksamhet kräver. Det beräknas " +
                    "genom att ta intäkterna dividerat med det totala kapitalet."
                  }
                  alt=""
                />
              </div>
              <div className="key-tree-branch l2 l2-bottom">
                <div className="key-tree-entry key-tree-entry-straight entry-bottom">
                  <div className="key-tree-label sole">
                    <p className="key-value">
                      {this.getKeyPostFormatted("Tillgångar")}
                    </p>
                    <img
                      className="action-icon"
                      src={
                        process.env.PUBLIC_URL +
                        "/images/icons/info-button.svg"
                      }
                      data-toggle="tooltip"
                      data-placement="top"
                      data-html="true"
                      title={"Tillgångar"}
                      alt=""
                    />
                  </div>
                  <div className="key-tree-branch l3 l3-bottom">
                    <div className="key-tree-entry entry-l4 key-tree-input">
                      <div className="tree-trunk-left tree-coloring a-t" />
                      <label>Anläggningstillgångar</label>
                      <input
                        className="label-input"
                        value={this.getKeyPost("Anläggningstillgångar")}
                        onChange={e => this.listenerChangeData(e, "Anläggningstillgångar")}
                      />
                    </div>
                    <div className="key-tree-entry">
                      <div className="key-tree-label">
                        <p className="key-value">
                          {this.getKeyPostFormatted("Omsättningstillgångar")}
                        </p>
                        {/*<div className="key-tree-info">Hej</div>*/}
                        <img
                          className="action-icon"
                          src={process.env.PUBLIC_URL + "/images/icons/info-button.svg"}
                          data-toggle="tooltip"
                          data-placement="top"
                          data-html="true"
                          title={"Omsättningstillgångar"}
                          alt=""
                        />
                      </div>
                      <div className="key-tree-branch l4">
                        <div className="key-tree-entry key-tree-input">
                          <div className="tree-trunk-left tree-coloring la" />
                          <label>Lager</label>
                          <input
                            className="label-input"
                            value={this.getKeyPost("Lager")}
                            onChange={e => this.listenerChangeData(e, "Lager")}
                          />
                        </div>
                        <div className="key-tree-entry key-tree-input">
                          <div className="tree-trunk-left tree-coloring f-p" />
                          <label>Fodringar/placeringar</label>
                          <input
                            className="label-input"
                            value={this.getKeyPost("Fodringar/placeringar")}
                            onChange={e => this.listenerChangeData(e, "Fodringar/placeringar")}
                          />
                        </div>
                        <div className="key-tree-entry key-tree-input">
                          <div className="tree-trunk-left tree-coloring k-b" />
                          <label>Kassa och bank</label>
                          <input
                            className="label-input"
                            value={this.getKeyPost("Kassa och bank")}
                            onChange={e => this.listenerChangeData(e, "Kassa och bank")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="key-tree-entry key-tree-entry-last">
              <div className="key-label">
                <p>Soliditet (%)</p>
                <p className="key-value">
                  {this.getKeyPostFormattedDecimals("Soliditet")}
                </p>
                <img
                    className="action-icon"
                    src={
                      process.env.PUBLIC_URL + "/images/icons/info-button.svg"
                    }
                    data-toggle="tooltip"
                    data-placement="top"
                    data-html="true"
                    title={
                      "Soliditet <br/>" +
                      '= Justerat eget kapital &#247; Totala tillgångar <div style="height:8px;font-size:8px;">&nbsp;</div> ' +
                      "Soliditeten visar hur stor del av företaget som är finansierat med eget kapital"
                    }
                    alt=""
                />
              </div>
              <div className="key-tree-branch l2 l2-bottom">
                <div className="key-tree-entry-colspan entry-bottom">
                  <div className="key-tree-branch l3">
                    <div className="key-tree-entry-colspan-2">
                      <div className="key-tree-label">
                        <p className="key-value">
                          {this.getKeyPostFormatted("Justerat eget kapital")}
                        </p>
                        <img
                            className="action-icon"
                            src={process.env.PUBLIC_URL + "/images/icons/info-button.svg"}
                            data-toggle="tooltip"
                            data-placement="top"
                            data-html="true"
                            title={"Justerat eget kapital"}
                            alt=""
                        />
                      </div>
                      <div className="key-tree-branch l4">
                        <div className="key-tree-entry key-tree-input">
                          <div className="tree-coloring c-red-0" />
                          <label>Eget kapital</label>
                          <input
                              className="label-input"
                              value={this.getKeyPost("Eget kapital")}
                              onChange={e => this.listenerChangeData(e, "Eget kapital")}
                          />
                        </div>
                        <div className="key-tree-entry key-tree-input">
                          <div className="tree-coloring c-red-1" />
                          <label>Obeskattade reserver</label>
                          <input
                              className="label-input"
                              value={this.getKeyPost("Obeskattade reserver")}
                              onChange={e => this.listenerChangeData(e, "Obeskattade reserver")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="main-root">
          <div className="key-label">
            <p>Rt (%)</p>
            <p className="key-value">{this.getKeyPostFormattedDecimals("Räntabilitet")}</p>
            <img
              className="action-icon"
              src={process.env.PUBLIC_URL + "/images/icons/info-button.svg"}
              data-toggle="tooltip"
              data-placement="top"
              data-html="true"
              title={
                "Räntabilitet på totalt kapital <br/>" +
                '= Vm &#215 KOH <div style="height:8px;font-size:8px;">&nbsp;</div>' +
                "Nyckeltalet visar avkastning på det totala kapitalet, det vill säga hur " +
                "effektivt ett företag använder sina resurser i förhållande till intäkterna."
              }
              alt=""
            />
          </div>
        </div>
      </div>
    );
  }
}

export default KeyValuesTree;
