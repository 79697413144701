import React from "react";
import $ from "jquery";
import AccountingTreeSubD3 from "./accounting-tree-sub-d3";
import AccountingTreeSubLabels from "./accounting-tree-sub-labels";
import { generateHash, downloadImage } from "../../utils";

class AccountingTreeSub extends React.Component {

  constructor(props) {

    super(props);

    this.props.actionCompaniesGet();

    this.state = {
      companyID: "",
      accountingID: "",
      dropdownCompanyLabel: "Välj företag",
      dropdownAccountingLabel: "Välj bokslut",
      renderKey: "",
    };

    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.handleAccountingChange = this.handleAccountingChange.bind(this);

  }

  renderDropdown() {
    return (
      <div className="tree-dropdown-container">
        <div className="dropdown-container">
          <div className="dropdown tree-dropdown">
            <button
              id={"dropdown-company-" + this.props.currentTree}
              className="dropdown-button dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {this.state.dropdownCompanyLabel}
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              {this.renderDropdownCompanies()}
            </div>
          </div>

          <div className="dropdown tree-dropdown">
            <button
              id={"dropdown-accounting-" + this.props.currentTree}
              className="dropdown-button dropdown-button-extended dropdown-toggle"
              type="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              disabled
            >
              {this.state.dropdownAccountingLabel}
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              {this.renderDropdownAccountings()}
            </div>
          </div>
        </div>
        <div
          className="btn-icon tree-download"
          id="graph-download"
          onClick={() => {
            downloadImage("#" + this.props.currentTree, "tree.svg");
          }}
        >
          <img
            className="tree-download"
            src={process.env.PUBLIC_URL + "/images/icons/download-arrow.svg"}
            alt=""
          />
        </div>
      </div>
    );
  }

  renderDropdownCompanies() {
    let html = [];
    let companiesState = this.props.companies.payload;
    let companies = [];

    for (let key in companiesState) {
      companies.push(companiesState[key]);
    }

    companies.map((company, i) => {
      return html.push(
        <li
          className="dropdown-item"
          key={i}
          onClick={() => {
            this.handleCompanyChange(company);
          }}
        >
          {company.name}
        </li>
      );
    });

    return html;
  }

  renderDropdownAccountings() {
    let html = [];
    let accountings = [];
    let accountingState = this.props.accountings.payload;

    for (let key in accountingState) {
      if (accountingState[key].company === this.state.companyID) {
        accountings.push(accountingState[key]);
      }
    }

    accountings.map((accounting, i) => {
      let title = accounting.name + ", " + accounting.periodStart + " - " + accounting.periodEnd;
      return html.push(
        <li
          className="dropdown-item"
          key={i}
          onClick={() => {
            this.handleAccountingChange(accounting, title);
          }}
        >
          {title}
        </li>
      );
    });

    return html;
  }

  handleCompanyChange(company) {
    let state = Object.assign({}, this.state);
    state.companyID = company.pk;
    if(this.state.companyID !== state.companyID) {
      state.dropdownAccountingLabel = "Välj bokslut";
    }
    state.dropdownCompanyLabel = company.name;
    this.setState(state);
    $("#dropdown-accounting-" + this.props.currentTree).prop("disabled", false);
  }

  handleAccountingChange(accounting, title) {
    let state = Object.assign({}, this.state);
    state.accountingID = accounting.pk;
    state.dropdownAccountingLabel = title;
    state.renderKey = generateHash(8);
    this.setState(state);
    this.props.setSelectedAccounting(this.props.currentTree.toString() + "AccountingID", accounting.pk);
  }

  renderTree() {
    let currentTree = this.props.currentTree;

    if (this.state.accountingID !== "") {

      return (
        <AccountingTreeSubD3
          treeID={currentTree}
          checkbox={this.props.checkbox}
          actionCompaniesGet={this.props.actionCompaniesGet}
          companies={this.props.companies}
          accountings={this.props.accountings}
          accountingData={this.props.accountingData}
          companyID={this.state.companyID}
          accountingID={this.state.accountingID}
          key={this.props.currentTree + this.state.renderKey + this.props.parentRenderKey}
          setHeight={this.props.setHeight}
          globalScale={this.props.globalScale}
          tree1AccountingID={this.props.tree1AccountingID}
          tree2AccountingID={this.props.tree2AccountingID}
        />
      );
    } else {
      return (
        <span>
          Välj ett företag och ett bokslut för att visa Bokslutsträd&reg;
        </span>
      );
    }
  }


  render() {
    return (
      <div className="tree-container">
        {this.renderDropdown()}
        <div id={this.props.currentTree} className="tree-canvas white-canvas">
          {this.renderTree()}
        </div>
        <AccountingTreeSubLabels
            treeID={this.props.currentTree}
            accountingData={this.props.accountingData}
            accountingID={this.state.accountingID}
            infoLevel={this.props.infoLevel}
            key={
              this.props.currentTree + this.state.renderKey +
              this.props.parentRenderKey + this.props.tree1AccountingID +
              this.props.tree2AccountingID
            }
            tree1AccountingID={this.props.tree1AccountingID}
            tree2AccountingID={this.props.tree2AccountingID}
        />
        <div className="tree-tooltip" id="tree-tooltip" />
        <div className="tree-tooltip" id="tree-tooltip-info" />
      </div>
    );
  }
}

export default AccountingTreeSub;
