import * as constants from "../constants";
import api from "../api";

export function actionUserBillingGet(token) {
    return dispatch => {
        dispatch({
            type: constants.USER_BILLING_GET_REQUEST
        });

        return api.userBillingGet(token).then(response => {
            if (response.ok) {
                dispatch({
                    type: constants.USER_BILLING_GET_SUCCESS,
                    payload: response.data
                })
            } else {
                dispatch({
                    type: constants.USER_BILLING_GET_FAILURE,
                    payload: response.data
                })
            }
        })
    }
}

export function actionUserBillingUpdate(data) {
    return dispatch => {
        dispatch({
            type: constants.USER_BILLING_UPDATE_REQUEST
        });

        return api.userBillingUpdate(data).then(response => {
            if (response.ok) {
                dispatch({
                    type: constants.USER_BILLING_UPDATE_SUCCESS,
                    payload: response.data
                })
            } else {
                dispatch({
                    type: constants.USER_BILLING_UPDATE_FAILURE,
                    payload: response.data
                })
            }
        })
    }
}
