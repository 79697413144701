import * as constants from "../constants";

const initialState = {
    payload: null,
    isFetched: false,
    isSaved: false,
    isCreated: false,
    isOrderSuccessful: false,
    type: 0,
    error: null
};

export default function(state = initialState, action) {

    let subscriptionType = null;
    if(action.payload && action.payload[0] && action.payload[0].type) {
        subscriptionType = action.payload[0].type
    }

    switch (action.type) {

        // get subscription

        case constants.USER_SUBSCRIPTION_GET_REQUEST:
            return {
                ...state,
                isFetched: false,
                type: 0,
                isSaved: false
            };
        case constants.USER_SUBSCRIPTION_GET_SUCCESS:
            return {
                ...state,
                payload: action.payload,
                isFetched: true,
                type: subscriptionType,
                isCreated: true,
                error: null
            };
        case constants.USER_SUBSCRIPTION_GET_FAILURE:
            return {
                ...state,
                isFetched: true,
                isCreated: false,
                isSaved: false,
                error: action.payload
            };

        // update subscription

        case constants.USER_SUBSCRIPTION_UPDATE_REQUEST:
            return {
                ...state,
                isFetched: false,
                isOrderSuccessful: false,
                isSaved: false
            };
        case constants.USER_SUBSCRIPTION_UPDATE_SUCCESS:
            return {
                ...state,
                payload: action.payload,
                isFetched: true,
                isCreated: true,
                isOrderSuccessful: true,
                error: null
            };
        case constants.USER_SUBSCRIPTION_UPDATE_FAILURE:
            return {
                ...state,
                isFetched: true,
                isCreated: false,
                isOrderSuccessful: false,
                isSaved: false,
                error: action.payload
            };

        // terminate subscription

        case constants.USER_SUBSCRIPTION_TERMINATE_REQUEST:
            return {
                ...state,
                isFetched: false,
                isOrderSuccessful: false,
                isSaved: false
            };
        case constants.USER_SUBSCRIPTION_TERMINATE_SUCCESS:
            return {
                ...state,
                payload: action.payload,
                isFetched: true,
                isOrderSuccessful: false,
                type: 0,
                isCreated: true,
                error: null
            };
        case constants.USER_SUBSCRIPTION_TERMINATE_FAILURE:
            return {
                ...state,
                isFetched: true,
                isCreated: false,
                isOrderSuccessful: false,
                isSaved: false,
                error: action.payload
            };

        // get subscription order

        case constants.USER_SUBSCRIPTION_ORDER_GET_REQUEST:
            return {
                ...state,
                isFetched: false,
                isSaved: false
            };
        case constants.USER_SUBSCRIPTION_ORDER_GET_SUCCESS:
            return {
                ...state,
                orders: action.payload,
                isFetched: true,
                isCreated: true,
                error: null
            };
        case constants.USER_SUBSCRIPTION_ORDER_GET_FAILURE:
            return {
                ...state,
                isFetched: true,
                isCreated: false,
                isSaved: false,
                error: action.payload
            };

        // default

        default:
            return state;

    }

}
