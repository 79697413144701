import React from "react";
import CustomHeader from "../../containers/header";
import CustomFooter from "../../containers/footer";

class Login extends React.Component {

    constructor(props) {

        super(props);

        this.handleInput = this.handleInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            form: {
                email: "",
                password: ""
            }
        };

    }

    componentDidMount() {
        this.props.actionUserReset();
    }

    handleInput(e) {
        const key = e.target.name;
        const value = e.target.value;

        let newState = JSON.parse(JSON.stringify(this.state));
        newState.form[key] = value;
        this.setState(newState);
    }

    handleSubmit(e) {
        e.preventDefault();
        const formData = JSON.stringify(this.state.form);
        this.props.actionUserLogin(formData);
    }

    render() {

        let {user} = this.props;

        return (
            <div className="wrapper-main">
                <CustomHeader/>
                <div className="page-chapters">
                    <div className="wrapper-chapter">
                        <div className="chapter-content">
                            <h2>Logga in</h2>
                            <div>
                                <form onSubmit={this.handleSubmit} className="form-account">
                                    <div>
                                        <div>
                                            <label>E-post</label>
                                        </div>
                                        <div>
                                            <input
                                                name="email"
                                                placeholder="E-post"
                                                type="text"
                                                value={this.state.form.email}
                                                onChange={this.handleInput}
                                            />
                                        </div>
                                        {user.error && (
                                            <div className="page__error">{user.error.email}</div>
                                        )}
                                    </div>
                                    <div>
                                        <div>
                                            <label>Lösenord</label>
                                        </div>
                                        <div>
                                            <input
                                                name="password"
                                                placeholder="Lösenord"
                                                type="password"
                                                value={this.state.form.password}
                                                onChange={this.handleInput}
                                            />
                                        </div>
                                        {user.error && (
                                            <div className="page__error">{user.error.password}</div>
                                        )}
                                    </div>
                                    <button type="submit" className="btn btn-green btn-lg">
                                        Logga in
                                    </button>
                                    {user.error && (
                                        <div className="page__error">
                                            {user.error.non_field_errors}
                                        </div>
                                    )}
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <CustomFooter/>
            </div>
        );
    }
}

export default Login;
