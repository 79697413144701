import * as d3 from "d3";
import {
  numberWithSpaces,
  numberWithSpacesCompare,
  roundFloatShowZeros,
  valueFormatterCompare
} from "../../utils";
import {margin} from "./accounting-tree-sub-d3";

export const RENDER_SVG = function(graphID, id, containerWidth, containerHeight) {
  d3.select(graphID)
    .append("svg")
    .attr("id", id + "-svg")
    .attr("version", "1")
    .attr("xmlns", "http://www.w3.org/2000/svg")
    .attr("width", containerWidth)
    .attr("height", containerHeight);
};

export const RENDER_TEXT_TITLE = function(graphID, companyName) {
  let svg = d3.select(graphID).select("svg");
  svg
    .append("text")
    .text(companyName)
    .attr("x", "50%")
    .attr("y", 20)
    .attr("alignment-baseline", "middle")
    .attr("text-anchor", "middle")
    .attr("class", "legend-company-name")
    .style("font-family", "helvetica")
    .style("font-size", "20px")
    .style("font-weight", "400");
};

export const RENDER_TEXT_PERIOD = function(graphID, periodStart, periodEnd) {
  let svg = d3.select(graphID).select("svg");
  svg
    .append("text")
    .text(periodStart + " - " + periodEnd)
    .attr("x", "50%")
    .attr("y", 40)
    .attr("alignment-baseline", "middle")
    .attr("text-anchor", "middle")
    .attr("class", "legend-period")
    .style("font-family", "helvetica")
    .style("font-size", "14px")
    .style("font-weight", "400");
};

export const RENDER_TEXT_SCALE = function(graphID, scale, containerWidth, containerHeight) {

  scale = Math.round(1 / scale);

  if(scale <= 0) {
    scale = 1;
  }

  let svg = d3.select(graphID).select("svg");
  svg
    .append("text")
    .text("Skala 1:" + scale)
    .attr("x", containerWidth-margin.left)
    .attr("y", containerHeight - 5)
    .attr("alignment-baseline", "middle")
    .attr("text-anchor", "middle")
    .attr("class", "legend-period")
    .style("font-family", "helvetica")
    .style("font-size", "14px")
    .style("font-weight", "400");
};

export const RENDER_TEXT_SOLIDITY = function(graphID, solidity, containerWidth, containerHeight) {

  console.log(solidity);

  let svg = d3.select(graphID).select("svg");
  let fixedSolidity = roundFloatShowZeros(solidity*100, 0);
  let solidityText = fixedSolidity + "%";

  if(solidity <= 0.10) {
    svg
        .append("text")
        .text("Soliditet: <" + solidityText)
        .attr("x", margin.right)
        .attr("y", containerHeight - 5)
        .attr("alignment-baseline", "middle")
        .attr("text-anchor", "middle")
        .attr("class", "legend-period")
        .style("font-family", "helvetica")
        .style("font-size", "14px")
        .style("font-weight", "400")
        .style("fill", "rgb(255,44,54)")
        .on("mouseover", (d, i) => RENDER_TOOLTIP_INFO_SHOW("När soliditeten är mindre än 10% så låses bredden på stammen till 10%"))
        .on("mousemove", () => RENDER_TOOLTIP_INFO_MOVE())
        .on("mouseout", () => RENDER_TOOLTIP_INFO_HIDE());

  } else {
    svg
        .append("text")
        .text("Soliditet: " + solidityText)
        .attr("x", margin.right)
        .attr("y", containerHeight - 5)
        .attr("alignment-baseline", "middle")
        .attr("text-anchor", "middle")
        .attr("class", "legend-period")
        .style("font-family", "helvetica")
        .style("font-size", "14px")
        .style("font-weight", "400");
  }


};

export const RENDER_TOOLTIP = function() {
  let tooltip = d3.select("#tree-tooltip");
  tooltip.append("div").attr("class", "title");
  tooltip.append("div").attr("class", "bsNumber");
  tooltip.append("div").attr("class", "data");
  tooltip.append("div").attr("class", "diffV");
  tooltip.append("div").attr("class", "diffP");
};

export const RENDER_TOOLTIP_INFO = function() {
  let tooltip = d3.select("#tree-tooltip-info");
  tooltip.append("div").attr("class", "info");
};


export const RENDER_TOOLTIP_SHOW = function(title, data, labelUnit, labelCurrency) {
  let tooltip = d3.select("#tree-tooltip");
  let formattedData = numberWithSpaces(data) + " " + labelUnit + labelCurrency;
  tooltip.select(".title").html(title);
  tooltip.select(".data").html(formattedData);
  tooltip.select(".diffV").html("");
  tooltip.select(".diffP").html("");
  tooltip.style("display", "block");
  tooltip.style("opacity", 2);
};

export const RENDER_TOOLTIP_INFO_SHOW = function(info) {
  let tooltip = d3.select("#tree-tooltip-info");
  tooltip.select(".info").html(info);
  tooltip.style("display", "block");
  tooltip.style("opacity", 2);
};

export const RENDER_TOOLTIP_SHOW_DETAILED = function(title, data, labelUnit, labelCurrency, diffV, diffP) {
  let tooltip = d3.select("#tree-tooltip");
  let formattedData = numberWithSpaces(data) + " " + labelUnit + labelCurrency;
  let formattedDiffV = "(" + numberWithSpacesCompare(diffV) + " " + labelUnit + labelCurrency + ")";
  let formattedDiffP = "(" + valueFormatterCompare(diffP) + " %)";
  tooltip.select(".title").html(title);
  tooltip.select(".data").html(formattedData);
  tooltip.select(".diffV").html(formattedDiffV);
  tooltip.select(".diffP").html(formattedDiffP);
  tooltip.style("display", "block");
  tooltip.style("opacity", 2);
};

export const RENDER_TOOLTIP_MOVE = function() {
  let tooltip = d3.select("#tree-tooltip");
  tooltip.style("top", d3.event.layerY + 100 + "px");
  tooltip.style("left", d3.event.layerX + 250 + "px");
};

export const RENDER_TOOLTIP_HIDE = function() {
  let tooltip = d3.select("#tree-tooltip");
  tooltip.style("display", "none");
  tooltip.style("opacity", 0);
};

export const RENDER_TOOLTIP_INFO_MOVE = function() {
  let tooltip = d3.select("#tree-tooltip-info");
  tooltip.style("top", d3.event.layerY + 10 + "px");
  tooltip.style("left", d3.event.layerX - 25 + "px");
};

export const RENDER_TOOLTIP_INFO_HIDE = function() {
  let tooltip = d3.select("#tree-tooltip-info");
  tooltip.style("display", "none");
  tooltip.style("opacity", 0);
};

export const RENDER_GRAPH_BAR = function(graphID, id, data, yPos, xPos, barColWidth) {
  let svg = d3.select(graphID).select("svg");
  data.forEach(function(bar) {
      svg
          .append("rect")
          .attr("id", id)
          .attr("x", xPos)
          .attr("y", yPos)
          .attr("width", barColWidth)
          .attr("height", bar.height)
          .style("fill", bar.color)
          .transition()
          .styleTween("fill", function () {
            return d3.interpolate("white", bar.color);
          })
          .duration(300);
      yPos += bar.height;
  });
};

export const RENDER_GRAPH_PIE = function(graphID, tree, arcID, data, pieRadius, xPos, yPos, startAngle, endAngle) {
  let svg = d3.select(graphID).select("svg");
  let g = svg
    .append("g")
    .attr("transform", "translate(" + xPos + "," + yPos + ")");

  let pie = d3
    .pie()
    .sort(null)
    .value(function(d) {
      return Math.abs(d.value);
    })
    .startAngle(startAngle)
    .endAngle(endAngle);

  let arc = g
    .selectAll(".arc")
    .data(pie(data))
    .enter()
    .append("g");
  arc.attr("class", "arc").attr("id", arcID);

  let path = arc.append("path");
  path.attr(
    "d",
    d3
      .arc()
      .outerRadius(pieRadius)
      .innerRadius(0)
  );
  path.style("fill", (d, i) => data[i].color);

};
