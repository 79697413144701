import React from 'react';
import { Route } from 'react-router-dom';
import store from '../store'
import SubscriptionRequired from "../../view/containers/home/subscription-required";

const SubscriptionRoute = ({component: Component, ...rest}) => {

    const isSubscriptionType = store.getState().reducerUserSubscription.type;

    if(isSubscriptionType > 0) {
        return (
            <Route {...rest} render={(props) => <Component {...props} />} />
        )
    } else {
        return (
            <Route {...rest} render={(props) => <SubscriptionRequired {...props} />} />
        )
    }

};

export default SubscriptionRoute;