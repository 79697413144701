import apisauce from "apisauce";
import Cookie from "universal-cookie";
import store from "./store";

let __DEV__ = false;

const getOrigin = () => {

  const ORIGIN_LOCAL = "http://localhost:8000";
  const ORIGIN_LIVE = "https://api.umesee.se";

  if (
    window.location.hostname === "localhost" ||
    window.location.hostname === "127.0.0.1"
  ) {
    __DEV__ = true;
    return ORIGIN_LOCAL;
  } else {
    __DEV__ = false;
    return ORIGIN_LIVE;
  }

};


let create = () => {

  new Cookie();

  const api = apisauce.create({
    baseURL: getOrigin(),
    headers: {
      "Content-type": "application/json",
      Accept: "application/json",
      "Accept-Language": "sv"
    }
  });

  if (__DEV__ && console.tron) {
    api.addMonitor(console.tron.apisauce);
  }

  const headersAuthenticated = () => {
    const isAuthenticated = store.getState().reducerUser.isAuthenticated;
    if (isAuthenticated) {
      const token = store.getState().reducerUser.token;
      return {
        headers: {
          Authorization: "Token " + token
        }
      };
    }
    return null;
  };

  const headers = () => ({
    headers: {}
  });

  /*const defaultOptions = () => ({
    headers: {
      'X-CSRFToken': cookie.get('csrftoken')
    }
  })
  
  const defaultHead = () => ({
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })*/


  const userRegister = data => api.post(
      "/rest-auth/registration/", data, headersAuthenticated());
  const userLogin = data => api.post(
      "/rest-auth/login/", data, headersAuthenticated());
  const userLogout = data => api.post(
      "/rest-auth/logout/", data, headers());
  const userGet = data => api.get(
      "/rest-auth/user/", data, headersAuthenticated());
  const userToolConfirmEmail = data => api.post(
      "/rest-auth/registration/account-confirm-email/" + data.key, data, headersAuthenticated());

  const userProfileGet = data => api.get(
      "/user/profile/get/", null, headersAuthenticated());

  const userBillingCreate = data => api.post(
      "/main/accountingOverView/create/", data, headersAuthenticated());
  const userBillingGet = data => api.get(
      "/user/billing/get/", null, headersAuthenticated());
  const userBillingUpdate = data => api.patch(
      "/user/billing/update/", data, headersAuthenticated());

  const userSubscriptionGet = data => api.get(
      "/user/subscription/get/", data, headersAuthenticated());
  const userSubscriptionUpdate = data => api.post(
      "/user/subscription/update/", data, headersAuthenticated());
  const userSubscriptionTerminate = data => api.post(
      "/user/subscription/terminate/", data, headersAuthenticated());
  const userSubscriptionOrderGet = data => api.get(
      "/user/subscription/order/get/", data, headersAuthenticated());

  const companiesGet = data => api.get(
      "/main/company/get/", data, headersAuthenticated());
  const companyAdd = data => api.post(
      "/main/company/create/", data, headersAuthenticated());
  const companyDelete = data => api.post(
      "/main/company/delete/" + data.pk, data, headersAuthenticated());
  const companyEdit = data => api.patch(
      "/main/company/update/" + data.pk, data, headersAuthenticated());

  const accountingsGet = data => api.get(
      "/main/accounting/get/" + data, data, headersAuthenticated());
  const accountingCreate = data => api.post(
      "/main/accounting/create/" + data.companyID, data, headersAuthenticated());
  const accountingDelete = data => api.get(
      "/main/accounting/delete/" + data.pk, null, headersAuthenticated());
  const accountingEdit = data => api.patch(
      "/main/accounting/update/" + data.pk, data, headersAuthenticated());

  const accountingDataGet = data => api.get(
      "/main/accountingData/get/" + data.pk, data, headersAuthenticated());
  const accountingImport = data => api.post(
      "/main/accountingData/sie/" + data.companyID, data.data, headersAuthenticated());

  return {

    userRegister,
    userLogin,
    userLogout,
    userGet,
    userToolConfirmEmail,

    userBillingCreate,
    userBillingGet,
    userBillingUpdate,

    userProfileGet,

    userSubscriptionGet,
    userSubscriptionUpdate,
    userSubscriptionTerminate,
    userSubscriptionOrderGet,

    companiesGet,
    companyAdd,
    companyDelete,
    companyEdit,

    accountingsGet,

    accountingCreate,
    accountingImport,
    accountingDelete,
    accountingEdit,
    accountingDataGet

  };

};

export default create();
