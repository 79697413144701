import { connect } from "react-redux";
import * as userActions from "../../../controller/actions/user";
import Register from "../../components/user/register";

const mapStateToProps = state => ({
  user: state.reducerUser
});

const mapDispatchToProps = {
  actionUserRegister: userActions.actionUserRegister,
  actionUserReset: userActions.actionUserReset
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Register);
