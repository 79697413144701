import React from "react";
import CustomHeader from "../../containers/header";
import CustomFooter from "../../containers/footer";

class Logout extends React.Component {


  componentDidMount() {
    this.props.actionUserLogout();
  }

  render() {

    return (
      <div className="wrapper-main">
        <CustomHeader />
        <div className="page-chapters">
          <div className="wrapper-chapter">
            <div className="chapter-content">
              <h3>Utloggning</h3>
              <p>Du har nu loggats ut</p>
            </div>
          </div>
        </div>
        <CustomFooter />
      </div>
    );
  }
}

export default Logout;
