import {connect} from 'react-redux'
import * as actions from '../../../controller/actions/user-profile'
import UserProfile from '../../components/user/user-profile'

const mapStateToProps = state => ({
    user: state.reducerUser,
    userProfile: state.reducerUserProfile
});

const mapDispatchToProps = {
    actionUserProfileGet: actions.actionUserProfileGet
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserProfile)
