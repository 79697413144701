import { combineReducers } from 'redux'
import reducerUser from './user'
import reducerUserProfile from './user-profile'
import reducerUserBilling from './user-billing'
import reducerUserTools from './user-tools'
import reducerCompany from './company'
import reducerAccounting from './accounting'
import reducerAccountingData from './accounting-data'
import reducerUserSubscription from './user-subscription'

export default combineReducers({
  reducerUser,
  reducerUserProfile,
  reducerUserBilling,
  reducerUserTools,
  reducerUserSubscription,
  reducerCompany,
  reducerAccounting,
  reducerAccountingData,
})
