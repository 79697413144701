import React from "react";
import CustomHeader from "../header";
import CustomFooter from "../footer";
import MenuLeft from "../menu-left";

class TermsOfPurchase extends React.Component {
  render() {
    return (
      <div className="wrapper-main">
        <CustomHeader />
          <div className="wrapper-content">
              <MenuLeft />
              <Chapter />
          </div>
        <CustomFooter />
      </div>
    );
  }
}

const Chapter = () => {
  return (
    <div className="page-chapters">
      <div className="wrapper-chapter">
        <div className="chapter-content">
          <h2>Köpvillkor</h2>
          <Content />
        </div>
      </div>
    </div>
  );
};

const Content = () => {
  return (
    <div class="termsofuse">
      <h2>Kommer snart!</h2>
    </div>
  );
};

export default TermsOfPurchase;
