import { connect } from "react-redux";
import * as actions from "../../../controller/actions/accounting";

import AccountingEdit from "../../components/accounting/accounting-edit";

const mapStateToProps = state => ({
  companies: state.reducerCompany,
  accountings: state.reducerAccounting,
  accountingData: state.reducerAccountingData
});

const mapDispatchToProps = {
  actionAccountingEdit: actions.actionAccountingEdit,
  actionAccountingReset: actions.actionAccountingReset
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountingEdit);
