import { connect } from "react-redux";
import * as userAccountingActions from "../../../controller/actions/accounting";
import * as userSubscriptionActions from "../../../controller/actions/user-subscription";
import AccountingImport from "../../components/accounting/accounting-import";

const mapStateToProps = state => ({
  companies: state.reducerCompany,
  accountings: state.reducerAccounting,
  userSubscription: state.reducerUserSubscription
});

const mapDispatchToProps = {
  actionAccountingCreate: userAccountingActions.actionAccountingCreate,
  actionAccountingImport: userAccountingActions.actionAccountingImport,
  actionsAccountingReset: userAccountingActions.actionAccountingReset,
  actionUserSubscriptionGet: userSubscriptionActions.actionUserSubscriptionGet,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountingImport);
