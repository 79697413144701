import * as constants from "../constants";

const initialState = {
  payload: null,
  isFetched: false,
  error: null
};

export default function(state = initialState, action) {
  let newState = null;
  let key = null;

  switch (action.type) {
    // get

    case constants.ACCOUNTING_DATA_GET_REQUEST:
      return {
        ...state,
        isFetched: false
      };

    case constants.ACCOUNTING_DATA_GET_SUCCESS:
      if (state.payload == null) {
        newState = {};
      } else {
        newState = JSON.parse(JSON.stringify(state.payload));
      }

      key = Object.keys(action.payload)[0];
      newState[key] = action.payload[key];

      return {
        ...state,
        payload: newState,
        isFetched: true,
        error: null
      };

    case constants.ACCOUNTING_DATA_GET_FAILURE:
      return {
        ...state,
        isFetched: true,
        error: action.payload
      };

    // delete

    case constants.ACCOUNTING_DATA_DELETE_SUCCESS:
      newState = JSON.parse(JSON.stringify(state.payload));
      key = action.payload.pk;
      delete newState[key];

      return {
        ...state,
        payload: newState,
        isFetched: true,
        error: action.payload
      };

    default:
      return state;
  }
}
