import React from "react";
import {
  dataToTree,
  numberWithSpaces,
  parseData,
  valueFormatterCompare,
  calculateDifferencePercentage,
  calculateDifferenceValue,
  accountingDataParseSign,
  numberWithSpacesCompare
} from "../../utils";

class AccountingTreeSubLabels extends React.Component {

  constructor(props) {

    super(props);

    let otherAccountingID = this.getOtherAccountingID();

    this.state = {
      otherAccountingID: otherAccountingID
    };

  }

  getOtherAccountingID() {
    let otherAccountingID = null;
    if(this.props.tree1AccountingID === this.props.accountingID) {
      otherAccountingID = this.props.tree2AccountingID
    }
    if(this.props.tree2AccountingID === this.props.accountingID) {
      otherAccountingID = this.props.tree1AccountingID
    }
    return otherAccountingID;
  }

  render() {

    if (this.props.infoLevel < 1 || this.props.accountingID === "") {

      return <div />;

    } else  {

      let accountingID = this.props.accountingID;

      let accountingData = this.props.accountingData.payload[accountingID];
      accountingData = accountingDataParseSign(accountingData);
      let tree = dataToTree(JSON.parse(JSON.stringify(accountingData)));


      let dataPieRight = parseData(JSON.parse(JSON.stringify(tree.pie.right)));
      let dataPieLeft = parseData(JSON.parse(JSON.stringify(tree.pie.left)));
      let dataBarRight = parseData(JSON.parse(JSON.stringify(tree.bar.right)));
      let dataBarLeft = parseData(JSON.parse(JSON.stringify(tree.bar.left)));

      let otherDataPieRight = null;
      let otherDataPieLeft = null;
      let otherDataBarRight = null;
      let otherDataBarLeft = null;


      if(this.state.otherAccountingID != null) {
        let otherAccountingData = this.props.accountingData.payload[this.state.otherAccountingID];
        otherAccountingData = accountingDataParseSign(otherAccountingData);
        let otherTree = dataToTree(JSON.parse(JSON.stringify(otherAccountingData)));
        otherDataPieRight = parseData(JSON.parse(JSON.stringify(otherTree.pie.right)));
        otherDataPieLeft = parseData(JSON.parse(JSON.stringify(otherTree.pie.left)));
        otherDataBarRight = parseData(JSON.parse(JSON.stringify(otherTree.bar.right)));
        otherDataBarLeft = parseData(JSON.parse(JSON.stringify(otherTree.bar.left)));
      }

      return (
        <div className="white-canvas label-container" key={this.props.treeID+"-label-container"}>
          {this.renderLabelGroup("Intäkter", dataPieLeft, otherDataPieLeft, "tree-half-left")}
          {this.renderLabelGroup("Kostnader", dataPieRight, otherDataPieRight, "tree-half-right")}
          {this.renderLabelGroup("Tillgångar", dataBarLeft, otherDataBarLeft, "tree-trunk-left")}
          {this.renderLabelGroup("Skulder", dataBarRight, otherDataBarRight, "tree-trunk-right")}
        </div>
      );
    }

  }

  renderLabelGroup(title, data, otherData, className) {

    let sum = 0;

    Object.keys(data).forEach(function(key) {
      sum+= data[key].value
    });

    let parentValue = numberWithSpaces(sum) + " kr";
    let labelData = [];

    data.map((child, i) => {
      return labelData.push(this.renderLabelGroupData(data, otherData, i, className))
    });

    return (
      <div className="label-group" key={this.props.treeID+className} >
        <div className="label-header">
          <div className={className + " tree-shape"} />
          <div className="label-header-title">{title}</div>
          <div className="label-header-value">{parentValue}</div>
        </div>
        {labelData}
      </div>
    );
  }

  renderCompareText(data, otherData, i) {

    if(otherData != null && otherData[i] != null) {

      let differenceValue = calculateDifferenceValue(data[i].value,otherData[i].value);
      let differencePercentage = calculateDifferencePercentage(data[i].value, otherData[i].value);
      let compareValueText = numberWithSpacesCompare(differenceValue) + " kr";
      let compareValuePercentText = "";

      compareValuePercentText = valueFormatterCompare(differencePercentage) + " %";

      return "(" + compareValueText + ", " + compareValuePercentText + ")";

    }

    return "";

  }

  renderLabelGroupData(data, otherData, i, className) {

    const colorDotStyle = { background: data[i].color };
    let valueText = numberWithSpaces(data[i].value) + " kr";
    let compareText = this.renderCompareText(data, otherData, i);

    if (this.props.infoLevel >= 2) {
      return (
          <div className="label-data-container" id={"label-data-"+i} key={this.props.treeID+"label-data-"+i}>
            <div className={className + " tree-shape"} style={colorDotStyle} />
            <div>
              <div className="label-sub-title">
                {data[i].title}
              </div>
              <div className="label-bs-number">
                {this.props.infoLevel >= 3 && (
                    valueText
                )}
              </div>
              <div className="label-bs-number">
                {this.props.infoLevel >= 4 && (
                    compareText
                )}
              </div>
            </div>
            <div className="label-right-column">
                <div className="label-sub-value">
                  {data[i].interval && (
                      " [" + data[i].interval[0] + "-" + data[i].interval[1] + "]"
                  )}
                </div>
            </div>
          </div>
      );
    }



  }


}

export default AccountingTreeSubLabels;
