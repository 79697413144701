import * as constants from "../constants";

const initialState = {
  payload: null,
  isFetched: false,
  error: null
};

export default function(state = initialState, action) {
  //console.log(action)

  let newState = null;
  let key = null;

  switch (action.type) {
    // get

    case constants.COMPANIES_GET_REQUEST:
      return {
        ...state,
        isFetched: false
      };
    case constants.COMPANIES_GET_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        isFetched: true,
        error: null
      };
    case constants.COMPANIES_GET_FAILURE:
      return {
        ...state,
        payload: null,
        isFetched: true,
        error: action.payload
      };

    // add

    case constants.COMPANY_ADD_REQUEST:
      return {
        ...state,
        isFetched: false
      };
    case constants.COMPANY_ADD_SUCCESS:
      if (state.payload == null) {
        newState = {};
      } else {
        newState = JSON.parse(JSON.stringify(state.payload));
      }
      key = Object.keys(action.payload)[0];
      newState[key] = action.payload[key];

      return {
        ...state,
        payload: newState,
        isFetched: true,
        error: null
      };
    case constants.COMPANY_ADD_FAILURE:
      return {
        ...state,
        isFetched: true,
        error: action.payload
      };

    // delete

    case constants.COMPANY_DELETE_REQUEST:
      return {
        ...state,
        isFetched: false
      };
    case constants.COMPANY_DELETE_SUCCESS:
      newState = JSON.parse(JSON.stringify(state.payload));
      key = action.payload.pk;
      delete newState[key];

      return {
        ...state,
        isFetched: true,
        payload: newState,
        error: null
      };

    case constants.COMPANY_DELETE_FAILURE:
      return {
        ...state,
        isFetched: true,
        error: action.payload
      };

    // edit

    case constants.COMPANY_EDIT_REQUEST:
      return {
        ...state,
        isFetched: false
      };
    case constants.COMPANY_EDIT_SUCCESS:
      newState = JSON.parse(JSON.stringify(state.payload));
      key = action.payload.pk;
      newState[key] = action.payload;

      return {
        ...state,
        payload: newState,
        isFetched: true,
        error: null
      };

    case constants.COMPANY_EDIT_FAILURE:
      return {
        ...state,
        isFetched: true,
        error: action.payload
      };

    default:
      return state;
  }
}
