import { connect } from "react-redux";
import * as actions from "../../../controller/actions/accounting";

import Companies from "../../components/accounting/companies";

const mapStateToProps = state => ({
  companies: state.reducerCompany,
  accountings: state.reducerAccounting
});

const mapDispatchToProps = {
  actionCompaniesGet: actions.actionCompaniesGet,
  actionCompanyAdd: actions.actionCompanyAdd,
  actionCompanyDelete: actions.actionCompanyDelete,
  actionCompanyEdit: actions.actionCompanyEdit,
  actionAccountingDelete: actions.actionAccountingDelete
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Companies);
