import React from "react";
import CustomHeader from "../../containers/header";
import CustomFooter from "../../containers/footer";
import {Link} from "react-router-dom";
import MenuLeft from "../../containers/menu-left";

export const subscriptions = [
    {id: "1", type: "Standard", desc: "0-10 anställda", employees: {min: 0, max: 10}, price: 139},
    {id: "2", type: "Standard", desc: "11-25 anställda", employees: {min: 11, max: 25}, price: 278},
    {id: "3", type: "Standard", desc: "26-50 anställda", employees: {min: 26, max: 50}, price: 417},
    {id: "4", type: "Standard", desc: "51-100 anställda", employees: {min: 51, max: 100}, price: 556},
    {id: "5", type: "Standard", desc: "101-250 anställda", employees: {min: 101, max: 250}, price: 695},
    {id: "6", type: "Standard", desc: "250+ anställda", employees: {min: 251, max: 100000}, price: 834},
];

export const getSubscriptionFromID = (id) => {
    let subscription = null;
    subscriptions.forEach((sub, i) => {
        if(sub.id === id) {
            subscription = sub;
        }
    });
    return subscription;
};

export const getSubscriptionPriceYearly = (id) => {
    let subscription = getSubscriptionFromID(id);
    if(subscription) {
        let totalPrice = subscription.price*12;
        return totalPrice.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
    } else {
        return "N/A";
    }
};

class UserSubscriptionOrder extends React.Component {

    constructor(props) {

        super(props);

        this.handleSubscriptionChange = this.handleSubscriptionChange.bind(this);
        this.handleTermsChange = this.handleTermsChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            userProfile: null,
            userBilling: null,
            subscriptionID: "0",
            isTermsChecked: null,
        };

        if (this.props.user && this.props.user.token) {
            this.props.actionUserBillingGet(this.props.user.token);
            this.props.actionUserProfileGet(this.props.user.token);
        }

    }

    componentDidMount() {

        if (this.props.userProfile && this.props.userProfile.payload) {
            this.setState({
                userProfile: this.props.userProfile.payload
            });
        }

        if (this.props.userBilling && this.props.userBilling.payload) {
            this.setState({
                userBilling: this.props.userBilling.payload
            });
        }

        this.props.actionUserSubscriptionGet();

    }

    handleSubscriptionChange(e) {
        const value = e.target.value;
        let newState = JSON.parse(JSON.stringify(this.state));
        newState.subscriptionID = value;
        this.setState(newState);
    }

    handleTermsChange(e) {
        const value = e.target.checked;
        let newState = JSON.parse(JSON.stringify(this.state));
        newState.isTermsChecked = value;
        this.setState(newState);
    }

    handleSubmit(e) {

        e.preventDefault();

        let formData = {
            subscriptionID: this.state.subscriptionID,
            billing: this.state.userBilling,
        };

        console.log("Submitting:");
        console.log(formData);

        this.props.actionUserSubscriptionUpdate(formData);

    }

    renderContentOrder() {

        let {userBilling, userSubscription } = this.props;

        let userBillingData = null;

        if(userBilling) {
            userBillingData = userBilling.payload;
        }

        console.log(userBilling)

        let html = [];

        subscriptions.sort(function(a, b) {
            return parseFloat(a.id) - parseFloat(b.id);
        });

        html.push(<option value="0">Välj abonnemang</option>);

        subscriptions.map((subscription, i) => {
            let title = subscription.type + ", " + subscription.desc + ", " + subscription.price + " SEK / månad";
            return html.push(
                <option value={subscription.id}>{title}</option>
            );
        });

        let price = getSubscriptionPriceYearly(this.state.subscriptionID);
        let submitIsEnabled = this.state.isTermsChecked;

        return (
            <div className="user-profile-content">

                <h2 className="user-profile-header">Beställ abonnemang</h2>

                <form className="form-profile" onSubmit={(e) => this.handleSubmit(e)}>

                    <h2 className="form-profile-header">1. Välj abonnemang</h2>

                    <hr />

                    <div className="form-profile-section">
                        <select id="subscription-employees"
                                name="subscription-employees"
                                onChange={this.handleSubscriptionChange}
                                value={this.state.subscriptionID}>
                            {html}
                        </select>
                    </div>

                    <h2 className="form-profile-header">2. Kontrollera faktureringsuppgifter</h2>

                    <hr />

                    {userBillingData && (
                        <div className="form-profile-section">

                            <label>Namn</label>
                            <p className="form-profile-billing">
                                {userBillingData.firstName ? userBillingData.firstName : "N/A"}&nbsp;
                                {userBillingData.lastName ? userBillingData.lastName : "N/A"}
                            </p>

                            <label>Adress</label>
                            <p className="form-profile-billing">
                                {userBillingData.address ? userBillingData.address : "N/A"}
                            </p>
                            <p className="form-profile-billing">
                                {userBillingData.postalCode ? userBillingData.postalCode : "N/A"}&nbsp;
                                {userBillingData.city ? userBillingData.city : "N/A"}
                            </p>
                            <p className="form-profile-billing">
                                {userBillingData.country ? userBillingData.country : "N/A"}
                            </p>

                            <label>Organisationsnummer</label>
                            <p className="form-profile-billing">
                                {userBillingData.organizationNumber ? userBillingData.organizationNumber : "N/A"}
                            </p>
                            <Link to="/user/billing/">
                                <p className="form-profile-link-edit">
                                    Ändra faktureringsuppgifter
                                </p>
                            </Link>

                        </div>
                    )}

                    <h2 className="form-profile-header">3. Summa</h2>

                    <hr />

                    <div className="form-profile-section form-profile-mid ">

                        <p className="form-profile-price">Totalt {price} SEK</p>
                        <p className="form-profile-price-desc">Faktureras årsvis</p>

                    </div>

                    <h2 className="form-profile-header">3. Godkänn och beställ</h2>

                    <hr />

                    <div className="form-profile-section form-profile-mid">

                        <p className="form-profile-agree-label">
                            <input type="checkbox"
                                   className="signup-checkbox"
                                   onChange={(e) => this.handleTermsChange(e)}
                                   value={this.state.isTermsChecked}
                            />
                            Jag godkänner <Link className="form-profile-link-agree"
                                                to="/terms-of-purchase" target="_blank">
                            köpvillkoren
                        </Link>
                        </p>

                        {submitIsEnabled ? (
                            <button className="btn btn-sm btn-green">
                                Beställ
                            </button>
                        ) : (
                            <button className="btn btn-sm btn-green" disabled>
                                Beställ
                            </button>
                        )}

                        {userSubscription.error && (
                            <div className="alert alert-danger alert-dismissable" style={alwaysVisible}>
                                Ett fel inträffade
                            </div>
                        )}

                    </div>

                </form>

            </div>
        )
    }

    renderContentSuccess() {

        return (
            <div className="user-profile-content">

                <h2 className="user-profile-header">Beställ abonnemang</h2>

                <p>Ditt abonnemang har nu aktiverats!</p>

                <Link to={"/user/subscription/"}>
                    <button className="btn btn-sm btn-green form-btn">
                        Se ditt abonnemang
                    </button>
                </Link>

            </div>
        )

    }

    renderContent() {

        let { userSubscription } = this.props;

        if(userSubscription.isOrderSuccessful) {
            return this.renderContentSuccess();
        } else {
            return this.renderContentOrder();
        }

    }

    renderChapter() {
        let { userBilling } = this.props;
        return (
            <div className="wrapper-chapter">
                <div className="icon-heading">
                    <h2>Mitt konto</h2>
                </div>
                <div className="user-profile-wrapper">
                    {userBilling && userBilling.isFetched ?
                        this.renderContent() : <ContentLoading/>
                    }
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className="wrapper-main">
                <CustomHeader />
                <div className="wrapper-content">
                    <MenuLeft />
                    {this.renderChapter()}
                </div>
                <CustomFooter />
            </div>
        );

    }

}

class ContentLoading extends React.Component {

    render() {
        return (
            <div className="user-profile-content">
                <h2 className="user-profile-header">Loading...</h2>
            </div>
        )
    }

}

const alwaysVisible = {display: "block"};

export default UserSubscriptionOrder;