import { connect } from "react-redux";
import * as userActions from "../../../controller/actions/user-tools";
import UserToolEmailConfirm from "../../components/user/user-tool-email-confirm";

const mapStateToProps = state => ({
    user: state.reducerUser,
    userTools: state.reducerUserTools
});

const mapDispatchToProps = {
    actionUserToolConfirmEmail: userActions.actionUserToolConfirmEmail,
    actionUserToolReset: userActions.actionUserToolReset,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserToolEmailConfirm);
