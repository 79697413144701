import * as constants from "../constants";
import api from "../api";
import history from '../history'

export function actionUserRegister(data) {
  return dispatch => {
    dispatch({
      type: constants.USER_REGISTER_REQUEST
    });

    return api.userRegister(data).then(response => {
      if (response.ok) {
        dispatch({
          type: constants.USER_REGISTER_SUCCESS,
          payload: response.data
        });
        history.push("/get-started")
      } else {
        dispatch({
          type: constants.USER_REGISTER_FAILURE,
          payload: response.data
        });
      }
    });
  };
}

export function actionUserLogin(data) {
  return dispatch => {
    dispatch({
      type: constants.USER_LOGIN_REQUEST
    });

    return api.userLogin(data).then(response => {
      if (response.ok) {
        console.log("response ok:");

        let token = response.data.key;
        console.log("token:" + token);

        dispatch({
          type: constants.USER_LOGIN_SUCCESS,
          payload: response.data
        });

        dispatch({
          type: constants.USER_GET_REQUEST,
          payload: response.data
        });

        api.userGet(token).then(response2 => {
          if (response2.ok) {
            console.log("response2 ok");
            dispatch({
              type: constants.USER_GET_SUCCESS,
              payload: response2.data
            });
            history.push("/get-started")
          } else {
            console.log("response2 fail");
            dispatch({
              type: constants.USER_GET_FAILURE,
              payload: response2.data
            });
          }
        });
      } else {
        console.log("response fail:");
        console.log(response.data);

        dispatch({
          type: constants.USER_LOGIN_FAILURE,
          payload: response.data
        });
      }
    });
  };
}

export function actionUserLogout() {
  return dispatch => {
    dispatch({
      type: constants.USER_LOGOUT_REQUEST
    });

    return api.userLogout(null).then(response => {
      if (response.ok) {
        dispatch({
          type: constants.USER_LOGOUT_SUCCESS,
          payload: response.data
        });
        history.push("/")
      } else {
        dispatch({
          type: constants.USER_LOGOUT_FAILURE,
          payload: response.data
        });
      }
    });
  };
}

export function actionUserReset() {
  return dispatch => {
    dispatch({
      type: constants.USER_RESET
    });
  };
}