import React from "react";
import $ from "jquery";
import {
    getData,
    calculateKeyValues, calculateKeyValuesSum2,
} from "../../utils";
import * as d3 from "d3";
import { addSimpleBarListeners } from "../accounting-tree/accounting-tree-sub-d3";
import DropdownKeyValues from "../../../subcomponents/dropdown/dropdown-key-values";

const margin = {top: 60, right: 100, bottom: 60, left: 100};
const height = 300;

class Dashboard4 extends React.Component {

    constructor(props) {

        super(props);

        let graphID = "#" + this.props.elementID;
        let data = this.prepareData();

        this.state = {
            title: "Nyckeltal",
            id: null,
            data: data.data,
            dropdownItems: data.dropdownItems,
            graphID: graphID,
        };

        this.handleChangeCallback = this.handleChangeCallback.bind(this);

    }

    getGraphContainerWrapper() {
        let width = $(this.state.graphID).innerWidth();
        return {
            height: height,
            width: width,
        };
    }

    getGraphContainer() {
        let width = $(this.state.graphID).innerWidth();
        return {
            height: height - margin.top - margin.bottom,
            width: width - margin.left - margin.right,
        };
    }

    prepareData() {

        let data = getData(
            this.props.accountings.payload,
            this.props.accountingData.payload,
            this.props.companyID
        );

        let keyValueData = [];

        data.forEach((acc) => {

            let keyValues = calculateKeyValuesSum2(calculateKeyValues(acc.data));

            keyValueData.push({
                year: acc.year,
                data: keyValues,
            })

        });

        let dropdownItems = [];

        if(keyValueData && keyValueData.length > 0) {
            for(let key in keyValueData[0].data) {
                if(keyValueData[0].data.hasOwnProperty(key)) {
                    dropdownItems.push(key);
                }
            }
        }

        let state = {};
        state.data = keyValueData;
        state.dropdownItems = dropdownItems;
        return state;

    }

    renderSVG() {

        let graphContainerWrapper = this.getGraphContainerWrapper();

        d3.select(this.state.graphID + "-svg").remove();

        let svg = d3.select(this.state.graphID)
            .append("svg")
            .attr("id", this.props.elementID + "-svg")
            .attr("width", graphContainerWrapper.width)
            .attr("height", graphContainerWrapper.height);

        svg.append("g")
            .append("text")
            .text(this.state.title)
            .attr("x", 15)
            .attr("y", 30)
            .style("font-family", "helvetica")
            .style("font-size", "20px")
            .style("font-weight", "300");

        this.renderGraph();

    }

    renderGraph() {

        if((this.state.data && this.state.data.length <= 0) || !this.state.id) {
            return this.renderNoData();
        }

        let graphContainer = this.getGraphContainer();

        let padding = {
            top: 15,
            right: 15,
            left: 15,
            bottom: 15,
        };

        let years = [];
        let values = [];
        let posts = [];

        this.state.data.forEach((accounting) => {

            let value = null;
            let year = accounting.year;

            if(accounting.data.hasOwnProperty(this.state.id)) {
                value = accounting.data[this.state.id];
            }

            if(value) {
                years.push(year);
                values.push(value);
                posts.push({
                    year: year,
                    value: value,
                })
            }


        });

        let maxPostValue = Math.max(...values);
        let minPostValue = Math.min(...values);

        if(minPostValue === maxPostValue) {
            minPostValue = 0;
        }

        if(minPostValue > 0) {
            minPostValue = 0;
        }

        /*if(minPostValue < maxPostValue) {
            let temp = minPostValue;
            minPostValue = maxPostValue;
            maxPostValue = temp
        }*/

        console.log(years);
        console.log(values);
        console.log(minPostValue);
        console.log(maxPostValue);

        console.log(graphContainer);

        let y = d3.scaleLinear()
			.domain([minPostValue, maxPostValue]).nice()
			.range([graphContainer.height, 0]);

		let x = d3.scaleBand()
			.domain(years)
			.range([0, graphContainer.width])
			.padding(0.4);

		let svg = d3.select(this.state.graphID).select("svg");

		let xAxis = g => g
			.attr("transform", `translate(0, ${graphContainer.height + padding.bottom})`)
			.call(d3.axisBottom(x).tickSizeOuter(0).tickSize(0))
			.call(g => g.select(".domain").remove());

		let yAxis = g => g
			.attr("transform", `translate(0,0)`)
			.call(d3.axisLeft(y).ticks(6).tickSize(-graphContainer.width))
			.call(g => g.select(".domain").remove())
			.selectAll('line').style("stroke", "#ddd");

		let g2 = svg
			.append("g")
			.attr("transform", "translate(" + margin.left + "," + margin.top + ")");

		g2.append("g")
			.call(xAxis);

		g2.append("g")
			.call(yAxis);

		console.log(posts);

		g2.append("g")
			.selectAll("g")
			.data(posts)
			.join("rect")
			.attr("id", "graph-rect")
			.attr("x", d => x(d.year))
            .attr("y", function(d) { return y(Math.max(0, d.value)); })
            .attr("height", function(d) { return Math.abs(y(d.value) - y(0)); })
			.attr("width", x.bandwidth())
			.attr("fill", d => {
				return "#aaa";
			});

		 addSimpleBarListeners(this.state.graphID, "graph-rect", posts, "");


    }

    renderNoData() {
        let svg = d3.select(this.state.graphID).select("svg");
        svg.append("text")
            .text("Ingen data finns att visa")
            .attr("x", 15)
            .attr("y", 50)
            .style("font-family", "helvetica")
            .style("font-size", "12px")
            .style("font-weight", "300")
    }

    handleChangeCallback(item) {
        let state = JSON.parse(JSON.stringify(this.state));
        state.id = item;
        this.setState(state);
    }

    render() {
        return (
            <div id={this.props.elementID} >
                <div className="key-dropdown-container dropdown-fixed">
                    <DropdownKeyValues
                        elementID={"dropdown-nyckeltal"}
                        data={this.state.dropdownItems}
                        handleChangeCallback={this.handleChangeCallback}
                    />
                    {this.renderSVG()}
                </div>
            </div>
        );
    }

}

export default Dashboard4;
