import {connect} from 'react-redux'
import * as userProfileActions from '../../../controller/actions/user-profile'
import * as userBillingActions from '../../../controller/actions/user-billing'
import * as userSubscriptionActions from '../../../controller/actions/user-subscription'
import UserSubscriptionOrder from '../../components/user/user-subscription-order'

const mapStateToProps = state => ({
    user: state.reducerUser,
    userProfile: state.reducerUserProfile,
    userBilling: state.reducerUserBilling,
    userSubscription: state.reducerUserSubscription,
});

const mapDispatchToProps = {
    actionUserBillingGet: userBillingActions.actionUserBillingGet,
    actionUserProfileGet: userProfileActions.actionUserProfileGet,
    actionUserSubscriptionGet: userSubscriptionActions.actionUserSubscriptionGet,
    actionUserSubscriptionUpdate: userSubscriptionActions.actionUserSubscriptionUpdate,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserSubscriptionOrder)
