import React from "react";
import CustomHeader from "../header";
import CustomFooter from "../footer";
import MenuLeft from "../menu-left";

class NotFound extends React.Component {
  render() {
    return (
      <div className="wrapper-main">
        <CustomHeader />
          <div className="wrapper-content">
              <MenuLeft />
              <Chapter />
          </div>
        <CustomFooter />
      </div>
    );
  }
}

const Chapter = () => {
  return (
    <div className="page-chapters">
      <div className="wrapper-chapter">
        <div className="chapter-content">
          <h3>Error</h3>
          <p>Den här sidan kunde inte hittas</p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
