import React from "react";
import $ from "jquery";
import CustomHeader from "../../../containers/header";
import CustomFooter from "../../../containers/footer";
import MenuLeft from "../../../containers/menu-left";
import DropdownCompanies from "../../../subcomponents/dropdown/dropdown-companies";
import Dashboard1 from "./dashboard-1";
import Dashboard2 from "./dashboard-2";
import {generateHash} from "../../utils";
import Dashboard3 from "./dashboard-3";
import Dashboard4 from "./dashboard-4";

class Dashboard extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            companyID: "",
            renderKey: 0,
        };

        this.callbackHandleCompanyChange = this.callbackHandleCompanyChange.bind(this)

    }

    componentDidMount() {
        $('[data-toggle="tooltip"]').tooltip({
            trigger: "hover"
        });
        this.props.actionCompaniesGet();
    }

    callbackHandleCompanyChange(companyID) {
        let state = Object.assign({}, this.state);
        state.companyID = companyID;
        state.renderKey = generateHash(8);
        this.setState(state);
    }

    renderDashboardWrapper() {
        return (
            <div className="wrapper-chapter-grey">
                <div className="icon-heading">
                    <h2>
                        Översiktsvy
                        <img
                            className="action-icon"
                            src={process.env.PUBLIC_URL + "/images/icons/info-button.svg"}
                            data-toggle="tooltip"
                            data-placement="bottom"
                            data-html="true"
                            title={""}
                            alt=""
                        />
                    </h2>
                </div>
                <div className="compare-container">
                    <div className="key-dropdown-container">
                        <DropdownCompanies
                            elementID={this.props.elementID}
                            companies={this.props.companies}
                            callbackHandleCompanyChange={this.callbackHandleCompanyChange}
                        />
                    </div>
                    {this.renderGraphs()}
                </div>
            </div>
        )
    }

    renderGraphs() {

        if (this.state.companyID !== "") {

            return (
                <div>
                    <div className="tree-holder">
                        <div className="tree-container">
                            <div className="white-canvas">
                                <Dashboard1
                                    companies={this.props.companies}
                                    accountings={this.props.accountings}
                                    accountingData={this.props.accountingData}
                                    elementID={"dashboard-1"}
                                    companyID={this.state.companyID}
                                    key={this.state.renderKey}
                                />
                            </div>
                        </div>
                        <div className="tree-container">
                            <div className="white-canvas">
                                <Dashboard2
                                    companies={this.props.companies}
                                    accountings={this.props.accountings}
                                    accountingData={this.props.accountingData}
                                    elementID={"dashboard-2"}
                                    companyID={this.state.companyID}
                                    key={this.state.renderKey}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="tree-holder">
                        <div className="tree-container">
                            <div className="white-canvas">
                                <Dashboard3
                                    companies={this.props.companies}
                                    accountings={this.props.accountings}
                                    accountingData={this.props.accountingData}
                                    elementID={"dashboard-3"}
                                    companyID={this.state.companyID}
                                    key={this.state.renderKey}
                                />
                            </div>
                        </div>
                        <div className="tree-container">
                            <div className="white-canvas">
                                <Dashboard4
                                    companies={this.props.companies}
                                    accountings={this.props.accountings}
                                    accountingData={this.props.accountingData}
                                    elementID={"dashboard-4"}
                                    companyID={this.state.companyID}
                                    key={this.state.renderKey}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );

        } else {

            return (
                <div className="tree-holder">
                    <div className="tree-container">
                        <div id="dashboard-default" className="white-canvas">
                            Välj ett företag
                        </div>
                    </div>
                </div>
            );

        }

    }

    render() {
        return (
            <div className="wrapper-main">
                <CustomHeader/>
                <div className="wrapper-content">
                    <MenuLeft/>
                    {this.renderDashboardWrapper()}
                    <div className="tree-tooltip" id="tree-tooltip" />
                    <div className="tree-tooltip" id="tree-tooltip-info" />
                </div>
                <CustomFooter/>
            </div>
        );
    }

}

export default Dashboard;