import * as constants from "../constants";

const initialState = {
  payload: null,
  isFetched: false,
  isSaved: false,
  isCreated: false,
  error: null
};

export default function(state = initialState, action) {

  switch (action.type) {

    // get

    case constants.USER_BILLING_GET_REQUEST:
      return {
        ...state,
        isFetched: false,
        isSaved: false
      };
    case constants.USER_BILLING_GET_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        isFetched: true,
        isCreated: true,
        error: null
      };
    case constants.USER_BILLING_GET_FAILURE:
      return {
        ...state,
        isFetched: true,
        isCreated: false,
        isSaved: false,
        error: action.payload
      };

      // update

    case constants.USER_BILLING_UPDATE_REQUEST:
      return {
        ...state,
        isSaved: false
      };
    case constants.USER_BILLING_UPDATE_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        isSaved: true,
        isFetched: true,
        error: null
      };
    case constants.USER_BILLING_UPDATE_FAILURE:
      return {
        ...state,
        error: action.payload
      };

      // default

    default:
      return state;

  }

}
