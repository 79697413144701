import * as constants from "../constants";

const initialState = {
  payload: null,
  isFetched: false,
  error: null,
  isAuthenticated: false,
  token: null
};

export default function(state = initialState, action) {
  switch (action.type) {

      // login

    case constants.USER_LOGIN_REQUEST:
      return {
        ...state,
        isFetched: false
      };
    case constants.USER_LOGIN_SUCCESS:
      return {
        ...state,
        isFetched: true,
        error: null,
        isAuthenticated: true,
        token: action.payload.key,
      };
    case constants.USER_LOGIN_FAILURE:
      return {
        ...state,
        isFetched: true,
        error: action.payload,
        isAuthenticated: false
      };

      // register

    case constants.USER_REGISTER_REQUEST:
      return {
        ...state,
        isFetched: false
      };
    case constants.USER_REGISTER_SUCCESS:
      return {
        ...state,
        isFetched: true,
        error: null,
        isAuthenticated: true,
        token: action.payload.key,
      };
    case constants.USER_REGISTER_FAILURE:
      return {
        ...state,
        isFetched: true,
        error: action.payload,
        isAuthenticated: false
      };

      // logout

    case constants.USER_LOGOUT_REQUEST:
      return state;
    case constants.USER_LOGOUT_SUCCESS:
      return {
        ...initialState,
        isAuthenticated: false,
      };
    case constants.USER_LOGOUT_FAILURE:
      return {
        ...state,
        error: action.payload
      };

    case constants.USER_GET_REQUEST:
      return {
        ...state,
        isFetched: false
      };
    case constants.USER_GET_SUCCESS:
      return {
        ...state,
        isFetched: true,
        error: null,
        isAuthenticated: true,
        email: action.payload.email
      };
    case constants.USER_GET_FAILURE:
      return {
        ...state,
        isFetched: true,
        error: action.payload,
        isAuthenticated: false,
        email: null
      };

      // reset

    case constants.USER_RESET:
      return initialState;

    default:
      return state;
  }
}
