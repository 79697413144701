import React from "react";
import $ from "jquery";
import {
  dataToTree, parseData, calculateDifferencePercentage,
  calculateDifferenceValue, accountingDataParseSign, preRenderTree
} from "../../utils";
import * as d3 from "d3";
import * as accountingTreeRenderActions from "./accounting-tree-sub-d3-render";

export const margin = {top: 70, bottom: 50, right: 50, left: 50};

class AccountingTreeSubD3 extends React.Component {

  constructor(props) {
    super(props);

    this.renderSVG = this.renderSVG.bind(this);

    let otherAccountingID = this.getOtherAccountingID();

    this.state = {
      otherAccountingID: otherAccountingID
    };

  }

  componentDidMount() {
    this.renderSVG();
  }

  getOtherAccountingID() {
    let otherAccountingID = null;
    if(this.props.tree1AccountingID === this.props.accountingID) {
      otherAccountingID = this.props.tree2AccountingID
    }
    if(this.props.tree2AccountingID === this.props.accountingID) {
      otherAccountingID = this.props.tree1AccountingID
    }
    return otherAccountingID;
  }

  renderSVG() {

    let treeID = this.props.treeID;
    let graphID = "#" + treeID + "-graph";

    let graphContainer = {};
    graphContainer.height = $(graphID).innerHeight();
    graphContainer.width = $(graphID).innerWidth();

    let companyID = this.props.companyID;
    let company = this.props.companies.payload[companyID];
    let accountingID = this.props.accountingID;
    let accounting = this.props.accountings.payload[accountingID];

    let accountingData = this.props.accountingData.payload[accountingID];
    accountingData = accountingDataParseSign(accountingData);
    let tree = dataToTree(JSON.parse(JSON.stringify(accountingData)));


    let dataPieRight = parseData(JSON.parse(JSON.stringify(tree.pie.right)));
    let dataPieLeft = parseData(JSON.parse(JSON.stringify(tree.pie.left)));
    let dataBarRight = parseData(JSON.parse(JSON.stringify(tree.bar.right)));
    let dataBarLeft = parseData(JSON.parse(JSON.stringify(tree.bar.left)));

    let otherDataPieRight = null;
    let otherDataPieLeft = null;
    let otherDataBarRight = null;
    let otherDataBarLeft = null;


    if(this.state.otherAccountingID != null) {
      let otherAccountingData = this.props.accountingData.payload[this.state.otherAccountingID];
      otherAccountingData = accountingDataParseSign(otherAccountingData);
      let otherTree = dataToTree(JSON.parse(JSON.stringify(otherAccountingData)));
      otherDataPieRight = parseData(JSON.parse(JSON.stringify(otherTree.pie.right)));
      otherDataPieLeft = parseData(JSON.parse(JSON.stringify(otherTree.pie.left)));
      otherDataBarRight = parseData(JSON.parse(JSON.stringify(otherTree.bar.right)));
      otherDataBarLeft = parseData(JSON.parse(JSON.stringify(otherTree.bar.left)));
    }

    // calculations

    let containerInner = { height: graphContainer.height - margin.top - margin.bottom};
    let graph = { height: tree.pie.pieRadius * 2 + tree.bar.colHeight };

    let scale = containerInner.height / graph.height;

    let diff = 0;

    if (this.props.globalScale != null && this.props.checkbox.selectedScale === true) {
      diff = Math.abs(graph.height * (scale - this.props.globalScale));
      scale = this.props.globalScale;
    }

    this.props.setHeight(treeID, graph.height, containerInner.height);

    let barColHeight = tree.bar.colHeight * scale;
    let barColWidth = tree.bar.colWidth * scale;

    dataBarLeft = preRenderTree(dataBarLeft, barColHeight);
    dataBarRight = preRenderTree(dataBarRight, barColHeight);

    let pieRadius = tree.pie.pieRadius * scale;

    let yPosPie = margin.top + pieRadius + diff;
    let yPosBar = margin.top + pieRadius*2 + diff;

    let xPosPie = Math.floor(graphContainer.width/2);
    let xPosBar = Math.floor(graphContainer.width/2);

    // render

    accountingTreeRenderActions.RENDER_SVG(graphID, treeID, graphContainer.width, graphContainer.height);
    accountingTreeRenderActions.RENDER_TEXT_TITLE(graphID, company.name);
    accountingTreeRenderActions.RENDER_TEXT_PERIOD(graphID, accounting.periodStart, accounting.periodEnd);
    accountingTreeRenderActions.RENDER_TEXT_SOLIDITY(graphID, tree.bar.solidity, graphContainer.width, graphContainer.height);
    accountingTreeRenderActions.RENDER_TEXT_SCALE(graphID, scale, graphContainer.width, graphContainer.height);
    accountingTreeRenderActions.RENDER_GRAPH_PIE(graphID, tree, "pie-right", dataPieRight, pieRadius, xPosPie, yPosPie, 0, Math.PI);
    accountingTreeRenderActions.RENDER_GRAPH_PIE(graphID, tree, "pie-left", dataPieLeft, pieRadius, xPosPie, yPosPie, 0, -Math.PI);
    accountingTreeRenderActions.RENDER_GRAPH_BAR(graphID, "bar-left", dataBarLeft, yPosBar, xPosBar-barColWidth, barColWidth);
    accountingTreeRenderActions.RENDER_GRAPH_BAR(graphID, "bar-right", dataBarRight, yPosBar, xPosBar, barColWidth);
    accountingTreeRenderActions.RENDER_TOOLTIP();
    accountingTreeRenderActions.RENDER_TOOLTIP_INFO();

    addPieListeners(graphID, tree, "pie-left", dataPieLeft, otherDataPieLeft, this.props.checkbox.detailedView);
    addPieListeners(graphID, tree, "pie-right", dataPieRight, otherDataPieRight, this.props.checkbox.detailedView);
    addBarListeners(graphID, tree, "bar-left", dataBarLeft, otherDataBarLeft, this.props.checkbox.detailedView);
    addBarListeners(graphID, tree, "bar-right", dataBarRight, otherDataBarRight, this.props.checkbox.detailedView);

  }


  render() {
    return (
      <div
        id={this.props.treeID + "-graph"}
        className={this.props.treeID + "-graph"}
      />
    );
  }

}

export const addPieListeners = (graphID, tree, arcID, data, otherData, isDetailed) => {
  let svg = d3.select(graphID).select("svg");
  let objs = svg.selectAll("g#" + arcID);
  objs.on("mouseover", (d, i) => renderTooltip(tree, data, otherData, i, isDetailed));
  objs.on("mousemove", () => accountingTreeRenderActions.RENDER_TOOLTIP_MOVE());
  objs.on("mouseout", () => accountingTreeRenderActions.RENDER_TOOLTIP_HIDE());
};

export const addBarListeners = (graphID, tree, rectID, data, otherData, isDetailed) => {
  let svg = d3.select(graphID).select("svg");
  let rects = svg.selectAll("rect#" + rectID);
  rects.on("mouseover", (d, i) => renderTooltip(tree, data, otherData, i, isDetailed));
  rects.on("mousemove", () => accountingTreeRenderActions.RENDER_TOOLTIP_MOVE());
  rects.on("mouseout", () => accountingTreeRenderActions.RENDER_TOOLTIP_HIDE());
};

export const addSimpleBarListeners = (graphID, rectID, data, currency) => {

  let tree = {
    label_unit: "",
    label_currency: currency,
  };

  let svg = d3.select(graphID).select("svg");
  let rects = svg.selectAll("rect#" + rectID);
  rects.on("mouseover", (d, i) => renderTooltip(tree, data, null, i, null));
  rects.on("mousemove", () => accountingTreeRenderActions.RENDER_TOOLTIP_MOVE());
  rects.on("mouseout", () => accountingTreeRenderActions.RENDER_TOOLTIP_HIDE());

};


export const renderTooltip = (tree, data, otherData, i, isDetailed) => {

  if(isDetailed === true && otherData[i] != null && otherData[i].value != null) {

    let differencePercentage = calculateDifferencePercentage(data[i].value, otherData[i].value);
    let differenceValue = calculateDifferenceValue(data[i].value,otherData[i].value);


    return accountingTreeRenderActions.RENDER_TOOLTIP_SHOW_DETAILED(
        data[i].title,
        data[i].value,
        tree.label_unit,
        tree.label_currency,
        differenceValue,
        differencePercentage
    )

  } else {
    return accountingTreeRenderActions.RENDER_TOOLTIP_SHOW(
        data[i].title,
        data[i].value,
        tree.label_unit,
        tree.label_currency
    )
  }

};

export default AccountingTreeSubD3;
