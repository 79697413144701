import React from "react";
import CustomHeader from "../header";
import CustomFooter from "../footer";
import MenuLeft from "../menu-left";

class Team extends React.Component {
  render() {
    return (
      <div className="wrapper-main">
        <CustomHeader />
        <div className="wrapper-content">
          <MenuLeft />
          <Chapter />
        </div>
        <CustomFooter />
      </div>
    );
  }
}

const Chapter = () => {
  return (
    <div className="page-chapters">
      <div className="wrapper-chapter">

        <div className="chapter-content">
          <h2>Vårt team</h2>
          <div className="team">
            <div className="team-members">
              <div className="team-member">
                <img
                    className="portrait"
                    src={process.env.PUBLIC_URL + "/images/staff/lars.jpg"}
                    alt=""
                />
                <h4>Lars Wikman</h4>
                <p>Grundare / VD</p>
              </div>
            </div>
          </div>
        </div>

        <div className="chapter-content">
          <h2>Kontributörer</h2>
          <div className="team">
            <div className="team-members">
              <div className="team-member">
                <img
                    className="portrait"
                    src={process.env.PUBLIC_URL + "/images/staff/default.svg"}
                    alt=""
                />
                <h4>Kaj Nygren</h4>
                <p>Utvecklare</p>
              </div>
              <div className="team-member">
                <img
                    className="portrait"
                    src={process.env.PUBLIC_URL + "/images/staff/default.svg"}
                    alt=""
                />
                <h4>Erik Gunne</h4>
                <p>Utvecklare</p>
              </div>
              <div className="team-member">
                <img
                    className="portrait"
                    src={process.env.PUBLIC_URL + "/images/staff/default.svg"}
                    alt=""
                />
                <h4>Anna Nystedt</h4>
                <p>Utvecklare / UX Designer</p>
              </div>
              <div className="team-member">
                <img
                    className="portrait"
                    src={process.env.PUBLIC_URL + "/images/staff/default.svg"}
                    alt=""
                />
                <h4>Erik Landfors</h4>
                <p>Utvecklare</p>
              </div>
              <div className="team-member">
                <img
                    className="portrait"
                    src={process.env.PUBLIC_URL + "/images/staff/default.svg"}
                    alt=""
                />
                <h4>Alina Levytska</h4>
                <p>Grafisk designer</p>
              </div>
              <div className="team-member">
                <img
                    className="portrait"
                    src={process.env.PUBLIC_URL + "/images/staff/melicia.jpg"}
                    alt=""
                />
                <h4>Melicia Lindström</h4>
                <p>Marknadsföring</p>
              </div>
              <div className="team-member">
                <img
                    className="portrait"
                    src={process.env.PUBLIC_URL + "/images/staff/default.svg"}
                    alt=""
                />
                <h4>Frida Ylitalo</h4>
                <p>Utvecklare / UX Designer</p>
              </div>
            </div>
          </div>
        </div>

        <div className="chapter-content">
          <h2>Samarbetspartners</h2>
          <div className="team">
            <div className="team-members">
              <div className="team-member">
                <h4>Länsstyrelsen Västerbotten</h4>
                <p><a href="https://lansstyrelsen.se/vasterbotten/">lansstyrelsen.se/vasterbotten</a></p>
              </div>
              <div className="team-member">
                <h4>Företagarna Region Västerbotten</h4>
                <p><a href="https://foretagarna.se/regioner/vasterbotten/">foretagarna.se/regioner/vasterbotten</a></p>
              </div>
              <div className="team-member">
                <h4>Uminova Holding</h4>
                <p><a href="http://umuholding.se/">umuholding.se</a></p>
              </div>
              <div className="team-member">
                <h4>Uminova Innovation</h4>
                <p><a href="https://uminovainnovation.se/">uminovainnovation.se</a></p>
              </div>
              <div className="team-member">
                <h4>Almi Företagspartner</h4>
                <p><a href="https://www.almi.se/vasterbotten">almi.se/vasterbotten</a></p>
              </div>
            </div>
          </div>
        </div>

        <div className="chapter-content">
          <h2>Framtida partners</h2>
          <div className="team">
            <div className="team-members">
              <div className="team-member">
                <h4>Är du intresserad av ett samarbete eller hjälpa oss på vår resa?</h4>
                <p><a href="/contact">Kontakta oss</a></p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Team;
