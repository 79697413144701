import React from "react";
import CustomHeader from "../../containers/header";
import CustomFooter from "../../containers/footer";
import {Link} from "react-router-dom";
import MenuLeft from "../menu-left";

class SubscriptionRequired extends React.Component {

    render() {

        return (
            <div className="wrapper-main">
                <CustomHeader/>
                <div className="wrapper-content">
                    <MenuLeft />
                    {this.renderChapter()}
                </div>
                <CustomFooter/>
            </div>
        );
    }

    renderChapter() {
        return (
            <div className="page-chapters">
                <div className="wrapper-chapter">
                    <div className="chapter-content center-key-container">
                        <h2>Åtkomst nekad</h2>
                        <p>För att få tillgång till denna sida krävs ett aktivt abonnemang</p>
                        <Link to={"/user/subscription/"}>
                            <button className="btn btn-sm btn-green form-btn">
                                Uppgradera ditt abonnemang
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }

}

export default SubscriptionRequired;
