import React from "react";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import { connect } from "react-redux";

class CustomHeader extends React.Component {

  constructor(props) {
    super(props);

    $(document).ready(function() {
      $(".navbar-nav li a").click(function() {
        $(".navbar-collapse").collapse("hide");
      });
    });
  }

  componentDidMount() {

    window.scrollTo(0, 0);

    $('#button-menu-left').click(function() {
      $('#menu-left').toggle();
    });



  }

  render() {

    const { user } = this.props;

    if (!user.isAuthenticated) {
      return (
        <div className="header navbar navbar-expand-lg">

          <div id="header-logo">
            <NavLink className="navbar-brand mx-auto" to="/">
              <img src={process.env.PUBLIC_URL + "/images/logos/logo.svg"} className="icon-left" alt="" />
              UMeSee
            </NavLink>
          </div>

          <button className="navbar-toggler btn" type="button"
                  data-toggle="collapse" data-target="#collapsibleNavbar" >
            <img src={process.env.PUBLIC_URL + "/images/icons/menu-icon.svg"} className="icon-right" alt=""/>
          </button>

          <div className="collapse navbar-collapse header-list" id="collapsibleNavbar">

            <ul className="nav navbar-nav navbar-right">

              <RenderNavLinkModel />
              <RenderNavLinkServices />
              <RenderNavLinkPrices />
              <RenderNavLinkTeam />
              <RenderNavLinkContact />

              <RenderUserLogin />
              <RenderUserRegister />

            </ul>
          </div>
        </div>
      );
    } else {
      return (
        <div className="header navbar navbar-expand-lg">

          <div id="button-menu-left">
            <img src={process.env.PUBLIC_URL + "/images/icons/menu-icon.svg"} className="icon-left" alt=""/>
          </div>

          <div id="header-logo">
            <NavLink className="navbar-brand mx-auto" to="/get-started">
              <img
                src={process.env.PUBLIC_URL + "/images/logos/logo.svg"}
                className="icon-left" alt=""
              />
              UMeSee
            </NavLink>
          </div>

          <button className="navbar-toggler btn" type="button"
                  data-toggle="collapse" data-target="#collapsibleNavbar" >
            <img src={process.env.PUBLIC_URL + "/images/icons/account.svg"} className="icon-right" alt="" />
          </button>

          <div className="collapse navbar-collapse header-list" id="collapsibleNavbar">
            <ul className="nav navbar-nav navbar-right">
              <RenderUserProfile/>
              <RenderUserLogout/>
            </ul>
          </div>
        </div>
      );
    }
  }
}

const RenderUserLogin = () => {
  return <RenderNavLinkRounded url="/login" label="Logga in"/>
};

const RenderUserLogout = () => {
  return <RenderNavLinkRounded url="/logout" label="Logga ut"/>
};

const RenderUserRegister = () => {
  return <RenderNavLinkRounded url="/register" label="Registrera"/>
};

const RenderUserProfile = () => {
  return <RenderNavLinkRounded url="/user/profile" label="Mitt Konto"/>
};

const RenderNavLinkModel = () => {
  return <RenderNavLink url="/model" label="Förklaringsmodell" />
};

const RenderNavLinkServices = () => {
  return <RenderNavLink url="/services" label="Tjänster" />
};

const RenderNavLinkPrices = () => {
  return <RenderNavLink url="/products" label="Priser" />
};

const RenderNavLinkTeam = () => {
  return <RenderNavLink url="/team" label="Team" />
};

const RenderNavLinkContact = () => {
  return <RenderNavLink url="/contact" label="Kontakta oss" />
};


const RenderNavLink = (props) => {
  return (
      <li className="nav-item nav-element" data-toggle="collapse">
        <NavLink className="nav-link" activeClassName="active" to={props.url}>
          {props.label}
        </NavLink>
      </li>
  )
};

const RenderNavLinkRounded = (props) => {
  return (
      <li className="nav-item " data-toggle="collapse">
        <NavLink className="nav-link btn-login" to={props.url}>
          <button className="btn btn-sm btn-white">
            {props.label}
          </button>
        </NavLink>
      </li>
  )
};

const mapStateToProps = state => ({
  user: state.reducerUser
});

const mapDispatchToProps = {
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomHeader);
