/* user */

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";
export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAILURE = "USER_REGISTER_FAILURE";
export const USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAILURE = "USER_LOGOUT_FAILURE";
export const USER_GET_REQUEST = "USER_GET_REQUEST";
export const USER_GET_SUCCESS = "USER_GET_SUCCESS";
export const USER_GET_FAILURE = "USER_GET_FAILURE";
export const USER_RESET = "USER_RESET";
export const USER_TOOL_CONFIRM_EMAIL_REQUEST = "USER_TOOL_CONFIRM_EMAIL_REQUEST";
export const USER_TOOL_CONFIRM_EMAIL_SUCCESS = "USER_TOOL_CONFIRM_EMAIL_SUCCESS";
export const USER_TOOL_CONFIRM_EMAIL_FAILURE = "USER_TOOL_CONFIRM_EMAIL_FAILURE";
export const USER_TOOL_RESET = "USER_TOOL_RESET";

export const USER_BILLING_GET_REQUEST = "USER_BILLING_GET_REQUEST";
export const USER_BILLING_GET_SUCCESS = "USER_BILLING_GET_SUCCESS";
export const USER_BILLING_GET_FAILURE = "USER_BILLING_GET_FAILURE";
export const USER_BILLING_UPDATE_REQUEST = "USER_BILLING_UPDATE_REQUEST";
export const USER_BILLING_UPDATE_SUCCESS = "USER_BILLING_UPDATE_SUCCESS";
export const USER_BILLING_UPDATE_FAILURE = "USER_BILLING_UPDATE_FAILURE";

export const USER_PROFILE_GET_REQUEST = "USER_PROFILE_GET_REQUEST";
export const USER_PROFILE_GET_SUCCESS = "USER_PROFILE_GET_SUCCESS";
export const USER_PROFILE_GET_FAILURE = "USER_PROFILE_GET_FAILURE";

export const USER_SUBSCRIPTION_GET_REQUEST = "USER_SUBSCRIPTION_GET_REQUEST";
export const USER_SUBSCRIPTION_GET_SUCCESS = "USER_SUBSCRIPTION_GET_SUCCESS";
export const USER_SUBSCRIPTION_GET_FAILURE = "USER_SUBSCRIPTION_GET_FAILURE";

export const USER_SUBSCRIPTION_UPDATE_REQUEST = "USER_SUBSCRIPTION_UPDATE_REQUEST";
export const USER_SUBSCRIPTION_UPDATE_SUCCESS = "USER_SUBSCRIPTION_UPDATE_SUCCESS";
export const USER_SUBSCRIPTION_UPDATE_FAILURE = "USER_SUBSCRIPTION_UPDATE_FAILURE";

export const USER_SUBSCRIPTION_TERMINATE_REQUEST = "USER_SUBSCRIPTION_TERMINATE_REQUEST";
export const USER_SUBSCRIPTION_TERMINATE_SUCCESS = "USER_SUBSCRIPTION_TERMINATE_SUCCESS";
export const USER_SUBSCRIPTION_TERMINATE_FAILURE = "USER_SUBSCRIPTION_TERMINATE_FAILURE";

export const USER_SUBSCRIPTION_ORDER_GET_REQUEST = "USER_SUBSCRIPTION_ORDER_GET_REQUEST";
export const USER_SUBSCRIPTION_ORDER_GET_SUCCESS = "USER_SUBSCRIPTION_ORDER_GET_SUCCESS";
export const USER_SUBSCRIPTION_ORDER_GET_FAILURE = "USER_SUBSCRIPTION_ORDER_GET_FAILURE";

/* accounting */

export const COMPANIES_GET_REQUEST = "COMPANIES_GET_REQUEST";
export const COMPANIES_GET_SUCCESS = "COMPANIES_GET_SUCCESS";
export const COMPANIES_GET_FAILURE = "COMPANIES_GET_FAILURE";
export const COMPANY_ADD_REQUEST = "COMPANY_ADD_REQUEST";
export const COMPANY_ADD_SUCCESS = "COMPANY_ADD_SUCCESS";
export const COMPANY_ADD_FAILURE = "COMPANY_ADD_FAILURE";
export const COMPANY_DELETE_REQUEST = "COMPANY_DELETE_REQUEST";
export const COMPANY_DELETE_SUCCESS = "COMPANY_DELETE_SUCCESS";
export const COMPANY_DELETE_FAILURE = "COMPANY_DELETE_FAILURE";
export const COMPANY_EDIT_REQUEST = "COMPANY_UPDATE_REQUEST";
export const COMPANY_EDIT_SUCCESS = "COMPANY_UPDATE_SUCCESS";
export const COMPANY_EDIT_FAILURE = "COMPANY_UPDATE_FAILURE";

export const ACCOUNTINGS_GET_REQUEST = "ACCOUNTINGS_GET_REQUEST";
export const ACCOUNTINGS_GET_SUCCESS = "ACCOUNTINGS_GET_SUCCESS";
export const ACCOUNTINGS_GET_FAILURE = "ACCOUNTINGS_GET_FAILURE";

export const ACCOUNTING_RESET = "ACCOUNTING_RESET";
export const ACCOUNTING_CREATE_REQUEST = "ACCOUNTING_CREATE_REQUEST";
export const ACCOUNTING_CREATE_SUCCESS = "ACCOUNTING_CREATE_SUCCESS";
export const ACCOUNTING_CREATE_FAILURE = "ACCOUNTING_CREATE_FAILURE";
export const ACCOUNTING_IMPORT_REQUEST = "ACCOUNTING_IMPORT_REQUEST";
export const ACCOUNTING_IMPORT_SUCCESS = "ACCOUNTING_IMPORT_SUCCESS";
export const ACCOUNTING_IMPORT_FAILURE = "ACCOUNTING_IMPORT_FAILURE";
export const ACCOUNTING_DELETE_REQUEST = "ACCOUNTING_DELETE_REQUEST";
export const ACCOUNTING_DELETE_SUCCESS = "ACCOUNTING_DELETE_SUCCESS";
export const ACCOUNTING_DELETE_FAILURE = "ACCOUNTING_DELETE_FAILURE";
export const ACCOUNTING_EDIT_REQUEST = "ACCOUNTING_EDIT_REQUEST";
export const ACCOUNTING_EDIT_SUCCESS = "ACCOUNTING_EDIT_SUCCESS";
export const ACCOUNTING_EDIT_FAILURE = "ACCOUNTING_EDIT_FAILURE";

export const ACCOUNTING_DATA_GET_REQUEST = "ACCOUNTINGDATA_GET_REQUEST";
export const ACCOUNTING_DATA_GET_SUCCESS = "ACCOUNTINGDATA_GET_SUCCESS";
export const ACCOUNTING_DATA_GET_FAILURE = "ACCOUNTINGDATA_GET_FAILURE";
export const ACCOUNTING_DATA_DELETE_SUCCESS = "ACCOUNTING_DATA_DELETE_SUCCESS";
