import React from "react";
import $ from "jquery";
import {
    getData,
    renderResultColor,
    renderPostColor,
} from "../../utils";
import * as d3 from "d3";
import { addSimpleBarListeners } from "../accounting-tree/accounting-tree-sub-d3";

const margin = {top: 60, right: 100, bottom: 60, left: 100};
const height = 300;

class Dashboard2 extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            title: "Omsättning och resultat",
            graphID: "#" + this.props.elementID,
            data: getData(
                this.props.accountings.payload,
                this.props.accountingData.payload,
                this.props.companyID
            )
        };

    }


    componentDidMount() {
        this.renderSVG();
        this.renderGraph();
    }

    getGraphContainerWrapper() {
        let width = $(this.state.graphID).innerWidth();
        return {
            height: height,
            width: width,
        };
    }

    getGraphContainer() {
        let width = $(this.state.graphID).innerWidth();
        return {
            height: height - margin.top - margin.bottom,
            width: width - margin.left - margin.right,
        };
    }

    renderSVG(graphID) {

        let graphContainerWrapper = this.getGraphContainerWrapper();

        let svg = d3.select(this.state.graphID)
            .append("svg")
            .attr("width", graphContainerWrapper.width)
            .attr("height", graphContainerWrapper.height);

        svg.append("g")
            .append("text")
            .text(this.state.title)
            .attr("x", 15)
            .attr("y", 30)
            .style("font-family", "helvetica")
            .style("font-size", "20px")
            .style("font-weight", "300");

    }

    renderGraph() {

        let graphContainer = this.getGraphContainer();

        let padding = {
            top: 15,
            right: 15,
            left: 15,
            bottom: 15,
        };

        let years = [];
        let posts = [];
        let toolData = [];
        let maxPostValue = 0;
        let minPostValue = 0;

        this.state.data.forEach((acc) => {

            years.push(acc.year);

            acc.posts.forEach((post) => {

                toolData.push(post);

                for (let key in post) {
                    if (post.hasOwnProperty(key)) {
                        if (!posts.includes(post.title)) {
                            posts.push(post.title);
                        }
                        if (post.value > maxPostValue) {
                            maxPostValue = post.value
                        }
                        if (minPostValue > post.value) {
                            minPostValue = post.value;
                        }
                    }
                }

            });

        });

        if(minPostValue === maxPostValue) {
            minPostValue = 0;
        }

        if(minPostValue > 0) {
            minPostValue = 0;
        }

        console.log(minPostValue);

        if(years.length <= 0) {
            return this.renderNoData();
        }

        let y = d3.scaleLinear()
            .domain([minPostValue, maxPostValue]).nice()
            .rangeRound([graphContainer.height, 0]);

        let x0 = d3.scaleBand()
            .domain(years)
            .rangeRound([padding.left, graphContainer.width-padding.left])
            .paddingInner(0.3);

        let x1 = d3.scaleBand()
            .domain(posts)
            .range([0, x0.bandwidth()])
            .padding(0.05);

        let svg = d3.select(this.state.graphID).select("svg");

        let xAxis = g => g
            .attr("transform", `translate(-5, ${graphContainer.height + padding.bottom })`)
            .call(d3.axisBottom(x0).tickSizeOuter(0).tickSize(0))
            .call(g => g.select(".domain").remove());

        let yAxis = g => g
            .attr("transform", `translate(0,0)`)
            .call(d3.axisLeft(y).ticks(6).tickSize(-graphContainer.width))
            .call(g => g.select(".domain").remove())
            .selectAll('line').style("stroke", "#ddd");

        let g2 = svg
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        g2.append("g")
            .call(xAxis);

        g2.append("g")
            .call(yAxis);

        g2.append("g")
            .selectAll("g")
            .data(this.state.data)
            .join("g")
            .attr("transform", d => `translate(${x0(d.year)},0)`)
            .selectAll("rect")
            .data(d => d.posts)
            .join("rect")
            .attr("id", "graph-rect")
            .attr("x", d => x1(d.title))
            .attr("y", function(d) { return y(Math.max(0, d.value)); })
            .attr("height", function(d) { return Math.abs(y(d.value) - y(0)); })
            .attr("width", x1.bandwidth())
            .attr("fill", d => {
                if(d.title === "Resultat") {
                    return renderResultColor(d.value);
                } else {
                    return renderPostColor(d.value);
                }
            });

        addSimpleBarListeners(this.state.graphID, "graph-rect", toolData, "kr");


    }

    renderNoData() {
        let svg = d3.select(this.state.graphID).select("svg");
        svg.append("text")
            .text("Ingen data finns att visa")
            .attr("x", 15)
            .attr("y", 50)
            .style("font-family", "helvetica")
            .style("font-size", "12px")
            .style("font-weight", "300")
    }

    render() {
        return (
            <div id={this.props.elementID} />
        );
    }

}

export default Dashboard2;

/*

class Dashboard2 extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            data: getData(
                this.props.accountings.payload,
                this.props.accountingData.payload,
                this.props.companyID
            )
        };

    }

    componentDidMount() {

        const graphID = "#" + this.props.elementID;

        let graphContainerWrapper = {
            height: 300,
            width: $(graphID).innerWidth(),
        };

        let graphContainer = {
            height: graphContainerWrapper.height - margin.top - margin.bottom,
            width: $(graphID).innerWidth() - margin.left - margin.right,
        };

        this.renderSVG(graphID, graphContainerWrapper, margin,"Omsättning och resultat");
        this.renderGraph(graphID, graphContainer, margin);

    }

    renderSVG(graphID, graphContainerWrapper, margin, title) {

        let svg = d3.select(graphID)
            .append("svg")
            .attr("width", graphContainerWrapper.width)
            .attr("height", graphContainerWrapper.height);

        svg.append("g")
            .append("text")
            .text(title)
            .attr("x", 15)
            .attr("y", 30)
            .style("font-family", "helvetica")
            .style("font-size", "20px")
            .style("font-weight", "300");

    }

    renderGraph(graphID, graphContainer, margin) {

        let padding = {
            top: 15,
            right: 15,
            left: 15,
            bottom: 15,
        };

        let years = [];
        let posts = [];
        let toolData = [];
        let maxPostValue = 0;
        let minPostValue = 0;

        this.state.data.forEach((acc) => {

            years.push(acc.year);

            acc.posts.forEach((post) => {

                toolData.push(post);

                for (let key in post) {
                    if (post.hasOwnProperty(key)) {
                        if (!posts.includes(post.title)) {
                            posts.push(post.title);
                        }
                        if (post.value > maxPostValue) {
                            maxPostValue = post.value
                        }
                        if (minPostValue > post.value) {
                            minPostValue = post.value;
                        }
                    }
                }

            });

        });

        if(years.length <= 0) {
            return this.renderNoData(graphID);
        }

        let y = d3.scaleLinear()
            .domain([minPostValue, maxPostValue]).nice()
            .rangeRound([graphContainer.height, margin.top]);

        let x0 = d3.scaleBand()
            .domain(years)
            .rangeRound([offset.x.left, graphContainer.width])
            .paddingInner(0.3);

        let x1 = d3.scaleBand()
            .domain(posts)
            .range([0, x0.bandwidth()])
            .padding(0.05);

        let svg = d3.select(graphID).select("svg");

        let xAxis = g => g
            .attr("transform", `translate(-5, ${graphContainer.height + padding.bottom })`)
            .call(d3.axisBottom(x0).tickSizeOuter(0).tickSize(0))
            .call(g => g.select(".domain").remove());

        let yAxis = g => g
            .attr("transform", `translate(0,0)`)
            .call(d3.axisLeft(y).ticks(6).tickSize(-graphContainer.width))
            .call(g => g.select(".domain").remove())
            .selectAll('line').style("stroke", "#ddd");

        let g2 = svg
            .append("g")
            .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

        g2.append("g")
            .call(xAxis);

        g2.append("g")
            .call(yAxis);

        g2.append("g")
            .selectAll("g")
            .data(this.state.data)
            .join("g")
            .attr("transform", d => `translate(${x0(d.year)},0)`)
            .selectAll("rect")
            .data(d => d.posts)
            .join("rect")
            .attr("id", "graph-rect")
            .attr("x", d => x1(d.title))
            .attr("y", d => {
                if(d.value > 0) {
                    return y(d.value)
                } else {
                    return y(0) // height + y
                }
            })
            .attr("height", d => {
                if(d.value > 0) {
                    return Math.abs(graphContainer.height - y(d.value))
                } else {
                    return y(d.value)
                }
            })
            .attr("width", x1.bandwidth())
            .attr("fill", d => {
                if(d.title === "Resultat") {
                    return renderResultColor(d.value);
                } else {
                    return renderPostColor(d.value);
                }
            });

        addSimpleBarListeners(graphID, "graph-rect", toolData, "kr");


    }

    renderNoData(graphID) {
        let svg = d3.select(graphID).select("svg");
        svg.append("text")
            .text("Ingen data finns att visa")
            .attr("x", 15)
            .attr("y", 50)
            .style("font-family", "helvetica")
            .style("font-size", "12px")
            .style("font-weight", "300")
    }

    render() {
        return (
            <div id={this.props.elementID} />
        );
    }

}

export default Dashboard2;*/
