import React from "react";
import $ from "jquery";
import * as d3 from "d3";
import {accountingDataParseSign, dataToTree, getData, getLatestAccounting, parseData, preRenderTree} from "../../utils";
import * as accountingTreeRenderActions from "../accounting-tree/accounting-tree-sub-d3-render";
import {addBarListeners, addPieListeners } from "../accounting-tree/accounting-tree-sub-d3";

class Dashboard1 extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			data: getData(
				this.props.accountings.payload,
				this.props.accountingData.payload,
				this.props.companyID
			),
		};

	}

	componentDidMount() {

		const margin = {top: 50, right: 0, bottom: 0, left: 0};
		const graphID = "#" + this.props.elementID;

		let graphContainerWrapper = {
			height: 300,
			width: $(graphID).innerWidth(),
		};

		let graphContainer = {
			height: graphContainerWrapper.height - margin.top - margin.bottom,
			width: $(graphID).innerWidth() - margin.left - margin.right,
		};

		this.renderSVG(graphID, graphContainerWrapper, margin,"Bokslutsträd");
		this.renderGraph(graphID, graphContainer, margin);

	}

	renderSVG(graphID, graphContainerWrapper, margin, title) {

		let svg = d3.select(graphID)
			.append("svg")
			.attr("width", graphContainerWrapper.width)
			.attr("height", graphContainerWrapper.height);

		svg.append("g")
			.append("text")
			.text(title)
			.attr("x", 15)
			.attr("y", 30)
			.style("font-family", "helvetica")
			.style("font-size", "20px")
			.style("font-weight", "300");

	}


	renderGraph(graphID, graphContainer, margin) {

		let accountingData = getLatestAccounting(this.state.data);

		if(accountingData == null) {
			return this.renderNoData(graphID)
		}

		// parse data

		accountingData = accountingDataParseSign(accountingData.data);
		let tree = dataToTree(JSON.parse(JSON.stringify(accountingData)));
		let dataPieRight = parseData(JSON.parse(JSON.stringify(tree.pie.right)));
		let dataPieLeft = parseData(JSON.parse(JSON.stringify(tree.pie.left)));
		let dataBarRight = parseData(JSON.parse(JSON.stringify(tree.bar.right)));
		let dataBarLeft = parseData(JSON.parse(JSON.stringify(tree.bar.left)));

		// calculations

		let treeContainer = {
			height: tree.pie.pieRadius * 2 + tree.bar.colHeight,
		};
		let scale = graphContainer.height / treeContainer.height;
		let diff = 0;
		if (this.props.globalScale != null && this.props.checkbox.selectedScale === true) {
			diff = Math.abs(treeContainer.height * (scale - this.props.globalScale));
			scale = this.props.globalScale;
		}
		let barColHeight = tree.bar.colHeight * scale;
		let barColWidth = tree.bar.colWidth * scale;
		dataBarLeft = preRenderTree(dataBarLeft, barColHeight);
		dataBarRight = preRenderTree(dataBarRight, barColHeight);
		let pieRadius = tree.pie.pieRadius * scale;
		let yPosPie = margin.top + pieRadius + diff;
		let yPosBar = margin.top + pieRadius*2 + diff;
		let xPosPie = Math.floor(graphContainer.width/2);
		let xPosBar = Math.floor(graphContainer.width/2);

		// render

		accountingTreeRenderActions.RENDER_GRAPH_PIE(graphID, tree, "pie-right", dataPieRight, pieRadius, xPosPie, yPosPie, 0, Math.PI);
		accountingTreeRenderActions.RENDER_GRAPH_PIE(graphID, tree, "pie-left", dataPieLeft, pieRadius, xPosPie, yPosPie, 0, -Math.PI);
		accountingTreeRenderActions.RENDER_GRAPH_BAR(graphID, "bar-left", dataBarLeft, yPosBar, xPosBar-barColWidth, barColWidth);
		accountingTreeRenderActions.RENDER_GRAPH_BAR(graphID, "bar-right", dataBarRight, yPosBar, xPosBar, barColWidth);
		accountingTreeRenderActions.RENDER_TOOLTIP();
		accountingTreeRenderActions.RENDER_TOOLTIP_INFO();


		addPieListeners(graphID, tree, "pie-left", dataPieLeft, null, false);
		addPieListeners(graphID, tree, "pie-right", dataPieRight, null, false);
		addBarListeners(graphID, tree, "bar-left", dataBarLeft, null, false);
		addBarListeners(graphID, tree, "bar-right", dataBarRight, null, false);

	}

	renderNoData(graphID) {
		let svg = d3.select(graphID).select("svg");
		svg.append("text")
			.text("Ingen data finns att visa")
			.attr("x", 15)
			.attr("y", 50)
			.style("font-family", "helvetica")
			.style("font-size", "12px")
			.style("font-weight", "300")
	}


	render() {
		return (
			<div id={this.props.elementID} />
		);
	}

}


export default Dashboard1;
