import { connect } from "react-redux";

import KeyValues from "../../components/graphs/key-values/key-values";
import * as actions from "../../../controller/actions/accounting";

const mapStateToProps = state => ({
  companies: state.reducerCompany,
  accountings: state.reducerAccounting,
  accountingData: state.reducerAccountingData
});

const mapDispatchToProps = {
  actionCompaniesGet: actions.actionCompaniesGet,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KeyValues);
