import * as constants from "../constants";

const initialState = {
    payload: null,
    isFetched: false,
    isSaved: false,
    isCreated: false,
    error: null
};

export default function(state = initialState, action) {

    switch (action.type) {

        // get

        case constants.USER_PROFILE_GET_REQUEST:
            return {
                ...state,
                isFetched: false,
                isSaved: false
            };
        case constants.USER_PROFILE_GET_SUCCESS:
            return {
                ...state,
                payload: action.payload,
                isFetched: true,
                isCreated: true,
                error: null
            };
        case constants.USER_PROFILE_GET_FAILURE:
            return {
                ...state,
                isFetched: true,
                isCreated: false,
                isSaved: false,
                error: action.payload
            };

        // default

        default:
            return state;

    }

}
