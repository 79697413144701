import { connect } from "react-redux";
import * as userActions from "../../../controller/actions/user";
import Logout from "../../components/user/logout";

const mapStateToProps = state => ({
  user: state.reducerUser
});

const mapDispatchToProps = {
  actionUserLogout: userActions.actionUserLogout
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Logout);
