import React from "react";
import { Link } from "react-router-dom";

class CustomFooter extends React.Component {
  render() {
    return (
      <div className="wrapper-footer">
        <div className="main-footer">
          <div className="footer-content">

            <div className="footer-item">
              <span>UMeSee</span>
              <br />
              <Link to="/model/">Förklaringsmodell</Link>
              <br />
              <Link to="/team/">Team</Link>
              <br />
            </div>

            <div className="footer-item">
              <span>Tjänster</span>
              <br />
              <Link to="/services/">Tjänster</Link>
            </div>

            <div className="footer-item">
              <span>Support</span>
              <br />
              <Link to="/contact/"> Kontakta oss</Link>
            </div>

            <div className="footer-item">
              <span>Villkor</span>
              <br />
              <Link to="/terms-of-use/"> Användarvillkor</Link>
              <br />
              <Link to="/terms-of-use/"> Integritetspolicy</Link>
              <br />
            </div>

          </div>

        </div>

        <div className="copy-footer">Copyright &copy; Umesee AB 2020</div>

      </div>
    );
  }
}

export default CustomFooter;
