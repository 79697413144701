import React from "react";

import CustomHeader from "../header";
import CustomFooter from "../footer";
import MenuLeft from "../menu-left";

class HomeAuthenticated extends React.Component {
    render() {
        return (
            <div className="wrapper-main">
                <CustomHeader/>
                <div className="wrapper-content">
                    <MenuLeft/>
                    <Chapter/>
                </div>
                <CustomFooter/>
            </div>
        );
    }
}

const Chapter = () => {
    return (
        <div className="wrapper-chapter-grey">
            <div className="icon-heading">
                <h2>Kom igång</h2>
            </div>
            <Section1/>
            <Section2/>
        </div>
    );
};

const Section1 = () => {
    return (
        <div className="white-canvas white-canvas-center introduction-wrapper">

            <h2>Välkommen till UMeSee</h2>
            <p>Låt oss komma igång!</p>

            <div className="introduction">
                <div>
                    <p>1. Börja med att lägga till ett företag</p>
                </div>
                <div>
                    <p>2. Importera eller mata sedan in ett bokslut manuellt</p>
                </div>
                <div>
                    <p>3. Du kan nu visualisera ditt bokslut genom olika grafer!</p>
                </div>
            </div>

            {/*<div className="fast-link-container">
                <div className="fast-link">
                    <div dangerouslySetInnerHTML={{__html: "<!-- Icon made by dDara from https://flaticon.com -->"}} />
                    <Link to="/accounting/">
                        <img className="link-icon-square" alt=""
                             src={process.env.PUBLIC_URL + "/images/icons/accounting-icon.svg"} />
                        <h5>Hantera bokslut</h5>
                    </Link>
                </div>
                <div className="fast-link">
                    <div dangerouslySetInnerHTML={{__html: "<!-- Icon made by Freepik from https://flaticon.com -->"}} />
                    <Link to="/graph/accounting-tree/">
                        <img className="link-icon-tall" alt=""
                             src={process.env.PUBLIC_URL + "/images/logos/logo-outline.svg"}/>
                        <h5>Jämför bokslut</h5>
                    </Link>
                </div>
                <div className="fast-link">
                    <div dangerouslySetInnerHTML={{__html: "<!-- Icon made by phatplus from https://flaticon.com -->"}} />
                    <Link to="/graph/base-account-tree/">
                        <img className="link-icon-square" alt=""
                             src={process.env.PUBLIC_URL + "/images/icons/accounting-tree-icon.svg"}/>
                        <h5>Se kontoträd</h5>
                    </Link>
                </div>
                <div className="fast-link">
                    <div dangerouslySetInnerHTML={{__html: "<!-- Icon made by Freepik from https://flaticon.com -->"}}/>
                    <Link to="/graph/key-value-tree/">
                        <img className="link-icon-square" alt=""
                             src={process.env.PUBLIC_URL + "/images/icons/key-icon.svg"} />
                        <h5>Se nyckeltal</h5>
                    </Link>
                </div>
            </div>*/}

        </div>
    )
};

const Section2 = () => {
    return (
        <div className="white-canvas white-canvas-center introduction-wrapper">

            <h2>Vill du veta mer?</h2>
            <p>Se våra instruktionsvideor för mer information</p>

            <iframe title="Introduktionsvideo"
                    width="560" height="315" src="https://www.youtube.com/embed/twj4OJ3VvhI" frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen></iframe>

            <div className="small-fast-link-center margin-top-30">
                <a href="https://www.youtube.com/channel/UCBGeHko04jMH_KK5ztW6D3w"
                   target="_blank" rel="noopener noreferrer">
                    <img className="small-fast-link-icon" alt=""
                         src={process.env.PUBLIC_URL + "images/icons/link-arrow-icon.svg"} />
                    Se fler videor på vår YouTube-kanal
                </a>
            </div>

        </div>
    )
};

export default HomeAuthenticated;
