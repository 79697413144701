import React from "react";
import Accounting from "./subcomponents/accounting";
import { Redirect } from "react-router-dom";
import {accountingDataParseNewPosts, accountingDataParseSign } from "../utils";

class AccountingEdit extends React.Component {

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e, state) {

    e.preventDefault();

    let accountingID = this.props.match.params.accountingID;
    state = JSON.parse(JSON.stringify(state));

    accountingDataParseNewPosts(state);
    console.log(state);

    let newState = accountingDataParseSign(state.data);
    console.log(newState);

    let data = {
      pk: accountingID,
      name: state.headers.name,
      periodStart: state.headers.periodStart,
      periodEnd: state.headers.periodEnd,
      data: newState
    };

    this.props.actionAccountingEdit(data);

  }

  render() {

    if (this.props.accountings.redirect === true) {
      this.props.actionAccountingReset();
      return <Redirect to="/accounting" />;
    }

    return (
        <Accounting
            accountingID={this.props.match.params.accountingID}
            companies={this.props.companies}
            accountings={this.props.accountings}
            accountingData={this.props.accountingData}
            handleSubmit={this.handleSubmit}
            title={"Redigera bokslut"}
        />
    )

  }


}

export default AccountingEdit;
