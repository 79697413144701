import React from "react";

class DropdownKeyValues extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            id: "",
            label: "Soliditet",
        };

        this.handleChange = this.handleChange.bind(this);

    }

    componentDidMount() {
        this.props.handleChangeCallback("Soliditet");
    }

    renderDropdown(data) {
        if(data && data.length > 0) {
            let html = [];
            data.forEach((item, i) => {
                return html.push(
                    <li
                        className="dropdown-item"
                        key={i}
                        onClick={() => {
                            this.handleChange(item);
                        }}
                    >
                        {item}
                    </li>
                );
            });
            return html;
        } else {
            return <li className="dropdown-item">{this.state.label}</li>
        }
    }

    handleChange(item) {
        let state = Object.assign({}, this.state);
        state.label = item;
        this.setState(state);
        this.props.handleChangeCallback(item);
    }

    render() {
        return (
            <div className="dropdown tree-dropdown">
                <button
                    id={this.props.elementID}
                    className="dropdown-button dropdown-toggle"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    {this.state.label}
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {this.renderDropdown(this.props.data)}
                </div>
            </div>
        )
    }

}

export default DropdownKeyValues;

