import * as constants from "../constants";

const initialState = {
  payload: {},
  isFetched: false,
  error: null,
  redirect: false
};

export default function(state = initialState, action) {
  let newState = null;
  let key = null;

  switch (action.type) {
    // get

    case constants.ACCOUNTINGS_GET_REQUEST:
      return {
        ...state,
        isFetched: false
      };

    case constants.ACCOUNTINGS_GET_SUCCESS:
      if (state.payload == null) {
        newState = {};
      } else {
        newState = JSON.parse(JSON.stringify(state.payload));
      }

      action.payload.forEach(function(accounting) {
        newState[accounting.pk] = accounting;
      });

      return {
        ...state,
        payload: newState,
        isFetched: true,
        error: null
      };

    case constants.ACCOUNTINGS_GET_FAILURE:
      return {
        ...state,
        isFetched: true,
        error: action.payload
      };

    // create

    case constants.ACCOUNTING_CREATE_REQUEST:
      return {
        ...state,
        isFetched: false
      };

    case constants.ACCOUNTING_CREATE_SUCCESS:
      return {
        ...state,
        isFetched: true,
        redirect: true,
        error: null
      };

    case constants.ACCOUNTING_CREATE_FAILURE:
      return {
        ...state,
        isFetched: true,
        error: action.payload
      };

    // reset

    case constants.ACCOUNTING_RESET:
      return {
        ...state,
        redirect: false
      };

    // import

    case constants.ACCOUNTING_IMPORT_REQUEST:
      return {
        ...state,
        isFetched: false
      };

    case constants.ACCOUNTING_IMPORT_SUCCESS:

      return {
        ...state,
        isFetched: true,
        error: null,
        redirect: true
      };

    case constants.ACCOUNTING_IMPORT_FAILURE:
      return {
        ...state,
        isFetched: true,
        error: action.payload
      };

    // delete

    case constants.ACCOUNTING_DELETE_REQUEST:
      return {
        ...state,
        isFetched: false
      };

    case constants.ACCOUNTING_DELETE_SUCCESS:
      newState = JSON.parse(JSON.stringify(state.payload));
      key = action.payload.pk;
      delete newState[key];

      return {
        ...state,
        payload: newState,
        isFetched: true,
        error: null
      };

    case constants.ACCOUNTING_DELETE_FAILURE:
      return {
        ...state,
        isFetched: true,
        error: action.payload
      };

    // edit

    case constants.ACCOUNTING_EDIT_REQUEST:
      return {
        ...state,
        isFetched: false
      };

    case constants.ACCOUNTING_EDIT_SUCCESS:
      return {
        ...state,
        isFetched: true,
        error: null,
        redirect: true
      };

    case constants.ACCOUNTING_EDIT_FAILURE:
      return {
        ...state,
        isFetched: true,
        error: action.payload
      };

    // default

    default:
      return state;
  }
}
