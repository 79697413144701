import React from "react";
import AccountingTreeSub from "./accounting-tree-sub.js";
import CustomHeader from "../../../containers/header";
import CustomFooter from "../../../containers/footer";
import {generateHash} from "../../utils";
import $ from "jquery";
import MenuLeft from "../../../containers/menu-left";

class TreeScreen extends React.Component {

  constructor(props) {

    super(props);

    this.changeSettingsHandler = this.changeSettingsHandler.bind(this);
    this.setHeight = this.setHeight.bind(this);
    this.setSelectedAccounting = this.setSelectedAccounting.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);

    this.state = {
      tree1: "",
      tree2: "",
      tree1AccountingID: null,
      tree2AccountingID: null,
      parentRenderKey: "",
      infoLevel: 1,
      checkbox: {
        selectedScale: false,
        detailedView: false
      },
    };

  }

  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip({
      trigger: "hover"
    });
    this.props.actionCompaniesGet();
  }

  setHeight(currentTree, totalheight, windowHeight) {
    if (currentTree === "tree1") {
      if (this.state.tree2 !== "") {
        this.compareHeight(totalheight, this.state.tree2, windowHeight);
      } else {
        this.setState({
          selectedScale: this.state.selectedScale,
          windowheight: windowHeight,
          tree1: totalheight
        });
      }
    }
    if (currentTree === "tree2") {
      if (this.state.tree1 !== "") {
        this.compareHeight(this.state.tree1, totalheight, windowHeight);
      } else {
        this.setState({
          selectedScale: this.state.selectedScale,
          windowheight: windowHeight,
          tree2: totalheight
        });
      }
    }

  }

  compareHeight(tree1, tree2, windowHeight) {

    if(tree1 === tree2) {
      // ignore
    } else if (tree1 > tree2) {
      let scale = windowHeight / tree1;
      this.setState({
        selectedScale: this.state.selectedScale,
        windowheight: windowHeight,
        tree1: tree1,
        tree2: tree2,
        scale: scale
      });
    } else {
      let scale = windowHeight / tree2;
      this.setState({
        selectedScale: this.state.selectedScale,
        windowheight: windowHeight,
        tree1: tree1,
        tree2: tree2,
        scale: scale
      });
    }
  }

  setSelectedAccounting(key, value) {
    let newState = JSON.parse(JSON.stringify(this.state));
    newState[key] = value;
    newState.parentRenderKey = generateHash(8);
    this.setState(newState);
  }

  handleCheckboxChange(e, key) {
    let newState = JSON.parse(JSON.stringify(this.state));
    newState.checkbox[key] = !this.state.checkbox[key];
    newState.parentRenderKey = generateHash(8);
    this.setState(newState);
  }

  changeSettingsHandler(key, value) {
    let newState = JSON.parse(JSON.stringify(this.state));
    newState[key] = value;
    newState.parentRenderKey = generateHash(8);
    this.setState(newState);
  }

  isCompareDisabled() {
    return this.state.tree1 === "" || this.state.tree2 === "";
  }

  renderChapter() {
    return (
        <div className="wrapper-chapter-grey">
          <div className="icon-heading">
            <h2>
              Bokslutsträd&reg;
              <img
                  className="action-icon"
                  src={process.env.PUBLIC_URL + "/images/icons/info-button.svg"}
                  data-toggle="tooltip"
                  data-placement="bottom"
                  data-html="true"
                  title={"Visualisera bokslut med form och färg. <br /> Trädets stam representerar balansräkningen, medan kronan representerar resultaträkningen. <br />Jämför mellan år och andra företag, visa skillnad i kr och %."}
                  alt=""
              />
            </h2>
          </div>
          <div className="compare-container">
            <div className="compare-controls">
              <div className="control-container toggle-container">
                <h5>Vy</h5>
                <div className="data-controls">
                  <label>
                    <input
                        type="checkbox"
                        value="selectedScale"
                        name="scaling"
                        disabled={this.isCompareDisabled()}
                        onChange={e => this.handleCheckboxChange(e, "selectedScale")}
                    />
                    Skalenlig
                  </label>
                  <label>
                    <input
                        type="checkbox"
                        value="detailedView"
                        name="view"
                        disabled={this.isCompareDisabled()}
                        onChange={e => this.handleCheckboxChange(e, "detailedView")}
                    />
                    Jämför (kr, %)
                  </label>
                </div>
              </div>
              <div className="control-container legend-container">
                <h5>Information</h5>
                <div className="data-controls">
                  <label>
                    <input
                        className="radio-inline"
                        type="radio"
                        value="infoLevel"
                        name="infoLevel"
                        defaultChecked={true}
                        onChange={e => this.changeSettingsHandler("infoLevel", 1)}
                    />
                    Rubriker
                  </label>
                  <label>
                    <input
                        className="radio-inline"
                        type="radio"
                        value="infoLevel"
                        name="infoLevel"
                        onChange={e => this.changeSettingsHandler("infoLevel", 2)}
                    />
                    Kontonummer
                  </label>
                  <label>
                    <input
                        className="radio-inline"
                        type="radio"
                        value="infoLevel"
                        name="infoLevel"
                        onChange={e => this.changeSettingsHandler("infoLevel", 3)}
                    />
                    Värde
                  </label>
                  <label>
                    <input
                        className="radio-inline"
                        type="radio"
                        value="infoLevel"
                        name="infoLevel"
                        disabled={this.isCompareDisabled()}
                        onChange={e => this.changeSettingsHandler("infoLevel", 4)}
                    />
                    Jämför (kr, %)
                  </label>
                </div>
              </div>
            </div>
            <div className="tree-holder">
              <AccountingTreeSub
                  currentTree="tree1"
                  otherTree="tree2"
                  actionCompaniesGet={this.props.actionCompaniesGet}
                  companies={this.props.companies}
                  accountings={this.props.accountings}
                  accountingData={this.props.accountingData}
                  setHeight={this.setHeight}
                  globalScale={this.state.scale}
                  checkbox={this.state.checkbox}
                  parentRenderKey={this.state.parentRenderKey}
                  infoLevel={this.state.infoLevel}
                  setSelectedAccounting={this.setSelectedAccounting}
                  tree1AccountingID={this.state.tree1AccountingID}
                  tree2AccountingID={this.state.tree2AccountingID}
                  key={
                    "tree1"
                  }
              />
              <AccountingTreeSub
                  currentTree="tree2"
                  otherTree="tree1"
                  actionCompaniesGet={this.props.actionCompaniesGet}
                  companies={this.props.companies}
                  accountings={this.props.accountings}
                  accountingData={this.props.accountingData}
                  setHeight={this.setHeight}
                  globalScale={this.state.scale}
                  checkbox={this.state.checkbox}
                  parentRenderKey={this.state.parentRenderKey}
                  infoLevel={this.state.infoLevel}
                  setSelectedAccounting={this.setSelectedAccounting}
                  tree1AccountingID={this.state.tree1AccountingID}
                  tree2AccountingID={this.state.tree2AccountingID}
                  key={
                    "tree2"
                  }
              />
            </div>
          </div>
        </div>
    )
  }

  render() {
    return (
      <div className="wrapper-main">
        <CustomHeader />
        <div className="wrapper-content">
          <MenuLeft />
          {this.renderChapter()}
        </div>
        <CustomFooter />
      </div>
    );
  }
}

export default TreeScreen;
