import React from "react";
import $ from "jquery";
import * as d3 from "d3";
import {
    accountingDataParseSign,
    getAccounting,
    getData,
    getLatestAccounting, numberWithSpaces, sortDataByKeyAsc, sortDataByKeyDesc,
} from "../../utils";

class Dashboard3 extends React.Component {

    constructor(props) {

        super(props);

        this.state = {
            data: getData(
                this.props.accountings.payload,
                this.props.accountingData.payload,
                this.props.companyID
            ),
        };

    }

    componentDidMount() {

        const margin = {top: 50, right: 0, bottom: 0, left: 0};
        const graphID = "#" + this.props.elementID;

        let graphContainerWrapper = {
            height: 300,
            width: $(graphID).innerWidth(),
        };

        let graphContainer = {
            height: graphContainerWrapper.height - margin.top - margin.bottom,
            width: $(graphID).innerWidth() - margin.left - margin.right,
        };

        this.renderSVG(graphID, graphContainerWrapper, margin,"Största förändringarna");
        this.renderGraph(graphID, graphContainer, margin);

    }

    renderSVG(graphID, graphContainerWrapper, margin, title) {

        let svg = d3.select(graphID)
            .append("svg")
            .attr("width", graphContainerWrapper.width)
            .attr("height", graphContainerWrapper.height);

        svg.append("g")
            .append("text")
            .text(title)
            .attr("x", 15)
            .attr("y", 30)
            .style("font-family", "helvetica")
            .style("font-size", "20px")
            .style("font-weight", "300");

    }


    renderGraph(graphID, graphContainer, margin) {

        let accountingData = getLatestAccounting(this.state.data);
        let accountingData2 = getAccounting(this.state.data, 1);

        if(accountingData == null || accountingData2 == null) {
            return this.renderNoData(graphID)
        }

        // parse data

        accountingData = accountingDataParseSign(accountingData);
        accountingData2 = accountingDataParseSign(accountingData2);

        let svg = d3.select(graphID).select("svg");

        svg.append("text")
            .text("Från " + accountingData2.year + " till " + accountingData.year)
            .attr("x", 15)
            .attr("y", 50)
            .style("font-family", "helvetica")
            .style("font-size", "12px")
            .style("font-weight", "300")


        let accountingDataPos = [];
        let accountingDataNeg = [];

        accountingData.data.forEach((post) => {
            accountingData2.data.forEach((post2) => {
                if(post.bsNumber === post2.bsNumber) {
                    if (post.value - post2.value > 0) {
                        accountingDataPos.push({
                            bsNumber: post.bsNumber,
                            name: post.name,
                            value: post.value-post2.value,
                        });
                    } else if (post.value - post2.value < 0) {
                        accountingDataNeg.push({
                            bsNumber: post.bsNumber,
                            name: post.name,
                            value: post.value-post2.value,
                        });
                    }
                }
            });
        });

        accountingDataPos = sortDataByKeyAsc(accountingDataPos, "value");
        accountingDataNeg = sortDataByKeyDesc(accountingDataNeg, "value");

        accountingDataPos = accountingDataPos.slice(0,3);
        accountingDataNeg = accountingDataNeg.slice(0,3);
        accountingDataNeg = sortDataByKeyAsc(accountingDataNeg, "value");

        let accountingDataDiff = [];
        accountingDataDiff = accountingDataDiff.concat(accountingDataPos);
        accountingDataDiff = accountingDataDiff.concat(accountingDataNeg);

        let pos = {x: 80, y: 100};

        accountingDataDiff.forEach((post) => {
            pos = this.renderRow(graphID, pos, post)
        })

    }

    renderRow(graphID, pos, post) {

        if(post.value > 0) {
            post.value = "+" + numberWithSpaces(post.value);
        } else {
            post.value = numberWithSpaces(post.value);
        }

        let svg = d3.select(graphID).select("svg");

        svg.append("text")
            .text(post.name + ", " + post.value + " kr")
            .attr("x", pos.x)
            .attr("y", pos.y)
            .style("font-family", "helvetica")
            .style("font-size", "15px")
            .style("font-weight", "300");

        return {x: pos.x, y: pos.y+25}
    }



    renderNoData(graphID) {
        let svg = d3.select(graphID).select("svg");
        svg.append("text")
            .text("Ingen data finns att visa")
            .attr("x", 15)
            .attr("y", 50)
            .style("font-family", "helvetica")
            .style("font-size", "12px")
            .style("font-weight", "300")
    }

    render() {
        return (
            <div id={this.props.elementID} />
        );
    }

}


export default Dashboard3;
