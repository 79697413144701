import React from "react";
import { Route, Router, Switch } from "react-router-dom";

import history from "../history";

import Home from "../../view/containers/home/home";
import Model from "../../view/containers/home/model";
import Products from "../../view/containers/home/products";
import Services from "../../view/containers/home/services";
import HomeAuthenticated from "../../view/containers/home/home-authenticated";
import Contact from "../../view/containers/home/contact";
import Team from "../../view/containers/home/team";
import TermsOfUse from "../../view/containers/home/terms-of-use";
import TermsOfPurchase from "../../view/containers/home/terms-of-purchase";

import Register from "../../view/containers/user/register";
import Login from "../../view/containers/user/login";
import Logout from "../../view/containers/user/logout";
import ConfirmEmail from "../../view/containers/user/user-tool-email-confirm";

import UserProfile from "../../view/containers/user/user-profile";
import UserBilling from "../../view/containers/user/user-billing";
import UserSubscription from "../../view/containers/user/user-subscription";
import UserSubscriptionOrder from "../../view/containers/user/user-subscription-order";
import UserSubscriptionHistory from "../../view/containers/user/user-subscription-history";

import Companies from "../../view/containers/accounting/companies";
import AccountingCreate from "../../view/containers/accounting/accounting-create";
import AccountingImport from "../../view/containers/accounting/accounting-import";
import AccountingEdit from "../../view/containers/accounting/accounting-edit";

import AccountingTree from "../../view/containers/graphs/accounting-tree";
import KeyValues from "../../view/containers/graphs/key-value-tree";
import BaseAccountTree from "../../view/containers/graphs/base-account-tree";
import Dashboard from "../../view/containers/graphs/dashboard";

import NotFound from "../../view/containers/error/not-found";
import PrivateRoute from "./private-route";
import SubscriptionRoute from "./subscription-route";

class CustomRouter extends React.PureComponent {

  render() {
    return (
        <Router history={history}>
          <Switch>

            <Route exact path="/" component={Home} />

            <Route exact path="/terms-of-use" component={TermsOfUse} />
            <Route exact path="/terms-of-purchase" component={TermsOfPurchase} />

            <Route exact path="/contact" component={Contact} />
            <Route exact path="/team" component={Team} />
            <Route exact path="/model" component={Model} />
            <Route exact path="/products" component={Products} />
            <Route exact path="/services" component={Services} />

            <Route exact path="/login" component={Login} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/logout" component={Logout} />
            <Route exact path="/user/confirm-email/:key" component={ConfirmEmail} />

            <PrivateRoute exact path="/get-started" component={HomeAuthenticated} />

            <PrivateRoute exact path="/dashboard" component={Dashboard} />

            <PrivateRoute exact path="/user/profile" component={UserProfile} />
            <PrivateRoute exact path="/user/billing" component={UserBilling} />
            <PrivateRoute exact path="/user/subscription" component={UserSubscription} />
            <PrivateRoute exact path="/user/subscription/order" component={UserSubscriptionOrder} />
            <PrivateRoute exact path="/user/orders" component={UserSubscriptionHistory} />

            <PrivateRoute exact path="/accounting" component={Companies} />
            <PrivateRoute exact path="/accounting/create/:companyID" component={AccountingCreate}/>
            <SubscriptionRoute exact path="/accounting/import/:companyID" component={AccountingImport}/>
            <PrivateRoute exact path="/accounting/edit/:accountingID" component={AccountingEdit}/>

            <PrivateRoute exact path="/graph/accounting-tree" component={AccountingTree}/>
            <PrivateRoute exact path="/graph/key-value-tree" component={KeyValues} />
            <PrivateRoute exact path="/graph/base-account-tree" component={BaseAccountTree}/>

            <Route component={NotFound} />

          </Switch>
        </Router>
    );
  }

}

export default CustomRouter;
