import React from "react";
import { NavLink } from "react-router-dom";
import $ from "jquery";

class MenuLeft extends React.Component {

    componentDidMount() {

        $('.wrapper-menu ul li ul').hide();

        $('.active').parent().parent().show();

        $('.wrapper-menu ul li').children('.active-parent').each(function() {
            $(this).parent().addClass('active-parent-li');
        });

        $('.wrapper-menu ul li ul li').children('.active').each(function() {
            $(this).next().show();
            $(this).parent().parent().parent().addClass('active-parent-li');
            $(this).parent().parent().parent().children('a').addClass('active-parent');
            $(this).parent().parent().show();
        });



        /*$('.active').each(function() {
            $(this).parents('ul').show();
            $(this).parents().children('ul').show();
        });*/

    }


    render() {

        return (
            <div className="wrapper-menu" id="menu-left">
                <ul>
                    <li>
                        <NavLink activeClassName="active-parent"  to="/get-started/">
                            <img src={process.env.PUBLIC_URL + "/images/icons/info-button.svg"}
                                 alt="" />
                            Kom igång
                        </NavLink>
                    </li>
                    <li>
                        <NavLink activeClassName="active-parent"  to="/dashboard/">
                            <img src={process.env.PUBLIC_URL + "/images/icons/home.svg"}
                                 alt="" />
                            Översikt
                        </NavLink>
                    </li>
                    <li>
                        <NavLink activeClassName="active-parent"  to="/accounting">
                            <img src={process.env.PUBLIC_URL + "/images/icons/edit-icon.svg"}
                                 alt="" />
                            Hantera bokslut
                        </NavLink>
                        <ul>
                            <li>
                                <NavLink activeClassName="active" to="/accounting">
                                    Hantera bokslut
                                </NavLink>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <NavLink activeClassName="active-parent" to="/graph/accounting-tree/">
                            <img src={process.env.PUBLIC_URL + "/images/icons/graph.svg"}
                                 alt="" />
                            Visualisera bokslut
                        </NavLink>
                        <ul>
                            <li>
                                <NavLink activeClassName="active" to="/graph/accounting-tree/">
                                    Bokslutsträd
                                </NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName="active" to="/graph/base-account-tree/">
                                    Kontoträd
                                </NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName="active" to="/graph/key-value-tree/">
                                    Lönsamhetsträd
                                </NavLink>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <NavLink activeClassName="active-parent"  to="/user/profile/">
                            <img src={process.env.PUBLIC_URL + "/images/icons/account-black.svg"}
                                 alt="" />
                            Mitt Konto
                        </NavLink>
                        <ul>
                            <li>
                                <NavLink activeClassName="active"  to="/user/profile/">
                                    <img src={process.env.PUBLIC_URL + "/images/icons/account-black.svg"}
                                         alt="" />
                                    Kontouppgifter
                                </NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName="active"  to="/user/billing/">
                                    <img src={process.env.PUBLIC_URL + "/images/icons/account-black.svg"}
                                         alt="" />
                                    Fakturering
                                </NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName="active"  to="/user/subscription">
                                    <img src={process.env.PUBLIC_URL + "/images/icons/account-black.svg"}
                                         alt="" />
                                    Abonnemang
                                </NavLink>
                            </li>
                            <li>
                                <NavLink activeClassName="active"  to="/user/orders">
                                    <img src={process.env.PUBLIC_URL + "/images/icons/account-black.svg"}
                                         alt="" />
                                    Beställningar
                                </NavLink>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        );
    }

}

export default MenuLeft;
