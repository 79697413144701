import * as constants from "../constants";
import api from "../api";

export function actionUserProfileGet(token) {

    return dispatch => {

        dispatch({
            type: constants.USER_PROFILE_GET_REQUEST
        });

        return api.userProfileGet(token).then(response => {
            if (response.ok) {
                dispatch({
                    type: constants.USER_PROFILE_GET_SUCCESS,
                    payload: response.data
                })
            } else {
                dispatch({
                    type: constants.USER_PROFILE_GET_FAILURE,
                    payload: response.data
                })
            }
        })

    }

}
