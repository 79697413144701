import React from "react";
import CustomHeader from "../../containers/header";
import CustomFooter from "../../containers/footer";
import {Link} from "react-router-dom";
import {getSubscriptionFromID} from "./user-subscription-order";
import {convertTime} from "../utils";
import MenuLeft from "../../containers/menu-left";

class UserSubscription extends React.Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.props.actionUserSubscriptionGet();
    }

    handleSubmit(e) {
        e.preventDefault();
        console.log("Avslutar abonnemang...");
        this.props.actionUserSubscriptionTerminate(null);
    }

    renderChapter() {
        let { userSubscription } = this.props;
        return (
            <div className="wrapper-chapter-grey">
                <div className="icon-heading">
                    <h2>Mitt konto</h2>
                </div>
                <div className="white-canvas">
                    {userSubscription && userSubscription.isFetched ? (
                        <Content
                            userSubscription={userSubscription}
                            handleSubmit={this.handleSubmit}
                        />
                    ) : (
                        <ContentLoading/>
                    )
                    }
                </div>
            </div>
        )
    }

    render() {

        return (
            <div className="wrapper-main">
                <CustomHeader />
                <div className="wrapper-content">
                    <MenuLeft />
                    {this.renderChapter()}
                </div>
                <CustomFooter />
            </div>
        );

    }

}


class ContentLoading extends React.Component {

    render() {
        return (
            <div className="user-profile-content">
                <h2 className="user-profile-header">Loading...</h2>
            </div>
        )
    }

}


class Content extends React.Component {

    render() {

        const { handleSubmit } = this.props;

        let subscription = null;
        let subscriptionID = null;
        let subscriptionDate = null;

        if(this.props.userSubscription
            && this.props.userSubscription.payload
            && this.props.userSubscription.payload[0]
        ) {
            subscriptionID = this.props.userSubscription.payload[0].type;
            subscriptionDate = this.props.userSubscription.payload[0].created;
            subscription = getSubscriptionFromID(subscriptionID.toString());
        }

        console.log(subscription);

        if(subscription) {

            console.log(subscription["created"]);

            return (
                <div className="user-profile-content">

                    <h2 className="user-profile-header">Mitt abonnemang</h2>

                    <form className="form-profile" onSubmit={handleSubmit}>

                        <table className="form-profile-table">
                            <tbody>
                                <RenderDisabledTableRow
                                    title="Abonnemang"
                                    id="subscription_type"
                                    value={subscription["type"]}
                                />
                                <RenderDisabledTableRow
                                    title="Licenstyp"
                                    id="subscription_license"
                                    value={subscription["desc"]}
                                />
                                <RenderDisabledTableRow
                                    title="Månadskostnad"
                                    id="subscription_cost"
                                    value={subscription["price"] + " SEK"}
                                />
                                <RenderDisabledTableRow
                                    title="Aktivt sedan"
                                    id="subscription_date"
                                    value={convertTime(subscriptionDate)}
                                />
                            </tbody>
                        </table>

                        <button className="btn btn-sm btn-green form-btn">
                            Avsluta abonnemang
                        </button>

                        {/*<button className="btn btn-sm btn-green form-btn">
                        Spara
                    </button>
                    {userProfile.isSaved && (
                        <div className="alert alert-success alert-dismissable" style={alwaysVisible}>
                            Dina uppgifter har sparats
                        </div>
                    )}
                    {userProfile.error && (
                        <div className="alert alert-danger alert-dismissable" style={alwaysVisible}>
                            Ett fel inträffade
                        </div>
                    )}*/}
                    </form>

                </div>
            )

        } else {

            return (
                <div className="user-profile-content">

                    <h2 className="user-profile-header">Mitt abonnemang</h2>

                    <div className="subscriptions-list-wrapper">
                        <p>Du har för närvarande inget abonnemang</p>
                        <Link to={"/user/subscription/order/"}>
                            <button className="btn btn-sm btn-green">
                                Välj abonnemang
                            </button>
                        </Link>
                    </div>

                    {/*<h2 className="user-profile-header">Beställ abonnemang</h2>

                    <div className="subscriptions-list-wrapper">
                        <ul className="subscriptions-list">
                            <RenderSubscriptionItem id={1} type={"Standard"} description={"Från 139kr / mån"} />
                        </ul>
                    </div>*/}

                </div>
            )

        }


    }

}


class RenderDisabledTableRow extends React.Component {
    render() {
        let {title, id, value} = this.props;
        return (
            <tr>
                <td>{title}</td>
                <td><input name={id} value={value} className="form-control" disabled /></td>
            </tr>
        )
    }
}


export default UserSubscription;