import * as constants from "../constants";
import api from "../api";

export function actionUserToolConfirmEmail(data) {

    return dispatch => {

        dispatch({
            type: constants.USER_TOOL_CONFIRM_EMAIL_REQUEST
        });

        return api.userToolConfirmEmail(data).then(response => {
            if (response.ok) {
                dispatch({
                    type: constants.USER_TOOL_CONFIRM_EMAIL_SUCCESS,
                    payload: response.data
                });
            } else {
                dispatch({
                    type: constants.USER_TOOL_CONFIRM_EMAIL_FAILURE,
                    payload: response.data
                });
            }
        });

    };

}

export function actionUserToolReset() {

    return dispatch => {

        dispatch({
            type: constants.USER_TOOL_RESET
        });

    };

}
