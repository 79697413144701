import React from "react";
import CustomHeader from "../../containers/header";
import CustomFooter from "../../containers/footer";

class UserToolEmailConfirm extends React.Component {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.props.actionUserToolReset();
    }

    handleSubmit(e) {
        e.preventDefault();
        const formData = {
            key: this.props.match.params.key
        };
        console.log(formData);
        this.props.actionUserToolConfirmEmail(formData);
    }

    renderChapter() {
        let { userTools } = this.props;
        return (
            <div className="page-chapters">
                <div className="wrapper-chapter">
                    <div className="chapter-content">
                        <h2>Bekräfta din email</h2>
                        <form className="form-profile" onSubmit={this.handleSubmit}>
                            <table className="form-profile-table">
                                <tbody>
                                <tr>
                                    <td>Bekräftningsnyckel</td>
                                    <td>
                                        <input name="key"
                                               value={this.props.match.params.key}
                                               className="form-control"
                                               disabled/>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <button className="btn btn-sm btn-green form-btn">
                                Bekräfta
                            </button>
                            {userTools && userTools.isSuccess === true && (
                                <div className="alert alert-success alert-dismissable" style={alwaysVisible}>
                                    Din email har bekräftats!
                                </div>
                            )}
                            {userTools && userTools.isSuccess === false && (
                                <div className="alert alert-danger alert-dismissable" style={alwaysVisible}>
                                    Din email kunde inte bekräftas.
                                </div>
                            )}
                        </form>
                    </div>
                </div>
            </div>
        )
    }

    render() {

        return (
            <div className="wrapper-main">
                <CustomHeader />
                <div className="wrapper-content">
                    {this.renderChapter()}
                </div>
                <CustomFooter />
            </div>
        );
    }

}


const alwaysVisible = {display: "block"};

export default UserToolEmailConfirm;
