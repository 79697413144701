import { connect } from "react-redux";
import * as actions from "../../../controller/actions/accounting";

import AccountingCreate from "../../components/accounting/accounting-create";

const mapStateToProps = state => ({
  companies: state.reducerCompany,
  accountings: state.reducerAccounting
});

const mapDispatchToProps = {
  actionAccountingCreate: actions.actionAccountingCreate,
  actionAccountingReset: actions.actionAccountingReset
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountingCreate);
