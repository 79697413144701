import React from "react";
import CustomHeader from "../../../containers/header";
import CustomFooter from "../../../containers/footer";
import {
    accountingDataParseSign,
    isBetween, numberWithSpaces,
    parseAccountingData,
    parseAccountingPostValue
} from "../../utils";
import $ from "jquery";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import MenuLeft from "../../../containers/menu-left";


class Accounting extends React.Component {

    constructor(props) {

        super(props);

        this.listenerChangeHeader = this.listenerChangeHeader.bind(this);
        this.listenerChangeData = this.listenerChangeData.bind(this);
        this.listenerChangeHeaderDate = this.listenerChangeHeaderDate.bind(this);
        this.accountingPostAdd = this.accountingPostAdd.bind(this);

        this.state = {
            headers: this.getStateHeaders(),
            data: this.getStateData(),
            sumData: {}
        };

    }

    getStateHeaders() {
        let state = {
            name: "",
            periodStart: "",
            periodEnd: "",
        };
        if(this.props.accountingID != null) {
            let accounting = this.props.accountings.payload[this.props.accountingID];
            state = {
                name: accounting.name,
                periodStart: accounting.periodStart,
                periodEnd: accounting.periodEnd
            };
        }
        return state;
    }

    getStateData() {
        let state = {};
        if(this.props.accountingData != null) {

            let accountingData = this.props.accountingData.payload[this.props.accountingID];

            accountingData = accountingDataParseSign(accountingData);

            if(this.props.accountingData.payload[this.props.accountingID]) {
                for (let key in accountingData) {
                    if (accountingData.hasOwnProperty(key)) {
                        state[accountingData[key].pk] = accountingData[key];
                    }
                }
            }
        }
        return state;
    }


    componentDidMount() {
        this.initJQuery();
    }

    initJQuery() {
        $('[data-toggle="tooltip"]').tooltip({
            trigger: "hover"
        });
        $('.form-company-body-t2').hide();
        $('.form-company-body-t3').hide();
        $('.form-company-collapse').on('click', function() {

            let numChildren = $(this)
                .parent()
                .parent()
                .siblings(':first').children().length;

            if(numChildren > 0) {
                $(this)
                    .parent()
                    .parent()
                    .siblings(':first')
                    .toggle();
                $(this).find('img').toggleClass('arrow-rotated');
            }


            console.log(numChildren)
        });
    }

    toggleSlide(id) {

        /*$("#form-company-header-tr-" + id)
            .siblings(':first')
            .toggle();*/

    }


    render() {

        return (
            <div className="wrapper-main">
                <CustomHeader />
                <div className="wrapper-content">
                    <MenuLeft />
                    {this.renderChapter()}
                </div>
                <CustomFooter />
            </div>
        );
    }

    renderChapter() {

        let data = parseAccountingData(this.state.data);

        return (
            <div className="wrapper-chapter">
                <div className="chapter-content">
                    <div className="company-wrapper">
                        <div className="icon-heading">
                            <h2>{this.props.title}</h2>
                        </div>
                        <form className="form-company" onSubmit={(e) => this.props.handleSubmit(e, this.state)}>
                            <div>
                                <div className="form-company-header">
                                    <div>
                                        <h4>Bokslutsuppgifter</h4>
                                    </div>
                                </div>
                                {this.renderHeaders()}
                                <div className="form-company-header">
                                    <div>
                                        <h4>Bokslutsdata</h4>
                                    </div>
                                </div>
                                {this.renderData(data)}
                            </div>
                            <button className="btn btn-sm btn-green form-btn">Spara</button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }

    renderHeaders() {
        return (
            <div className="form-company-header-t">
                <div className="form-company-header-tr">
                    <div className="form-company-body-tr2">
                        <div className="form-company-header-title form-company-th-margin">
                            Bokslutsnamn
                        </div>
                    </div>
                    <div>
                        <input
                            id="accounting_name"
                            name="accounting_name"
                            type="text"
                            className="form-control form-control-300"
                            value={this.state.headers["name"]}
                            onChange={e => this.listenerChangeHeader("name", e)}
                        />
                    </div>
                    <div>&nbsp;</div>
                </div>
                <div className="form-company-header-tr">
                    <div className="form-company-body-tr2">
                        <div className="form-company-header-title form-company-th-margin">
                            Period startdatum
                        </div>
                    </div>
                    <div>
                        <DatePicker
                            id="periodStart"
                            name="periodStart"
                            type="text"
                            className="form-control form-control-300"
                            value={this.state.headers["periodStart"]}
                            onChange={e => this.listenerChangeHeaderDate("periodStart", e)}
                        />
                    </div>
                    <div>&nbsp;</div>
                </div>
                <div className="form-company-header-tr">
                    <div className="form-company-body-tr2">
                        <div className="form-company-header-title form-company-th-margin">
                            Period slutdatum
                        </div>
                    </div>
                    <div>
                        <DatePicker
                            id="periodEnd"
                            name="periodEnd"
                            type="text"
                            className="form-control form-control-300"
                            value={this.state.headers["periodEnd"]}
                            onChange={e => this.listenerChangeHeaderDate("periodEnd", e)}
                        />
                    </div>
                    <div>&nbsp;</div>
                </div>
            </div>
        );
    }


    renderData(parent) {

        let html = [];

        if(parent.hasOwnProperty("children")) {

            for (let i = 0; i < parent.children.length; i++) {

                let child = parent.children[i];

                html.push(
                    <div className="form-company-body-t form-company-tr-margin"
                         key={"edit-static-" + child.id} >
                        {this.renderDataLabel(child, parent)}
                        <div className="form-company-body-t2">
                            {this.renderData(child)}
                        </div>
                    </div>
                );
            }


        } else {
            html.push(this.renderDataLast(parent));
        }

        return html;

    }


    renderDataLast(parent) {

        let html = [];
        let posts = [];
        let dynamicData = this.state.data;
        let self = this;

        for (let key in dynamicData) {
            if (dynamicData.hasOwnProperty(key)) {
                let child = dynamicData[key];
                let isValidEntry = isBetween(child.bsNumber, parent.interval[0], parent.interval[1]);
                if (child.pk != null && isValidEntry) {
                    posts.push(child);
                }
            }
        }

        posts.sort(function(a, b) {
            return a.bsNumber - b.bsNumber;
        });

        posts.forEach(function(child) {
            html.push(
                <div
                    className="form-company-body-t form-company-tr-margin"
                    key={"edit-level3-" + child.pk}
                >
                    {self.renderDataLabelLast(child)}
                </div>
            );
        });

        return html;
    }

    renderDataLabel = (child, parent) => {

        return (
            <div
                className={
                    "form-company-header-tr "
                    +(child.hasOwnProperty("classes") && child.classes)
                }
                id={"form-company-header-tr-" + parent.id}
            >
                <div className="form-company-body-tr2">

                    {(child.hasOwnProperty("children") || child.hasOwnProperty("dynamic_children")) ?  (
                        <div
                            data-toggle="tooltip"
                            data-placement="left"
                            title={
                                "Baskontonummer: [" + child.interval[0] + " - " + child.interval[1] + "]"
                            }
                            className={
                                "form-company-header-title form-company-collapse "
                            }
                            id={"form-company-collapse-" + child.id}
                        >
                            <img src={process.env.PUBLIC_URL + "/images/icons/arrow-black.svg"}
                                 className="form-company-arrow"
                                 alt=""
                            />
                            {/*<div className={"tree-shape"} style={colorDotStyle} />*/}
                            {child.hasOwnProperty("type") && child.type === "title" ? (
                                <span className="form-sum-title">{child.title}</span>
                            ) : (
                                child.title
                            )}
                        </div>
                    ) : (
                        <div
                            data-toggle="tooltip"
                            data-placement="left"
                            title={
                                "Baskontonummer: [" +
                                child.interval[0] +
                                " - " +
                                child.interval[1] +
                                "]"
                            }
                            className={
                                "form-company-header-title form-company-collapse "
                            }
                        >
                            <img
                                src={process.env.PUBLIC_URL + "/images/icons/arrow-black.svg"}
                                className="form-company-arrow hidden-opacity"
                                alt=""
                            />
                            {child.hasOwnProperty("type") && child.type === "title" ? (
                                <span className="form-sum-title">{child.title}</span>
                            ) : (
                                child.title
                            )}
                        </div>
                    )}
                    {child.hasOwnProperty("info") && (
                        <img className="action-icon-accounting"
                             src={process.env.PUBLIC_URL + "/images/icons/info-button.svg"}
                             data-toggle="tooltip"
                             data-placement="top"
                             data-html="true"
                             title={child.info}
                             alt="" />
                    )}
                </div>
                <div>
                    {child.hasOwnProperty("showValue") && child.showValue === false ? (
                        <div />
                    ) : (
                        child.hasOwnProperty("type") && child.type === "title" ? (
                            <input
                                id={"input-" + child.id}
                                name={"input-" + child.id}
                                type="text"
                                className="form-control form-control-150 form-sum-input input-right-align"
                                disabled
                                value={numberWithSpaces(child.value)}
                            />
                        ) : (
                            <input
                                id={"input-" + child.id}
                                name={"input-" + child.id}
                                type="text"
                                className="form-control form-control-150 input-right-align"
                                disabled
                                value={numberWithSpaces(child.value)}
                            />
                        )
                    )}


                </div>

                {
                    (child.hasOwnProperty("type") && child.type === "title") ||
                    (child.hasOwnProperty("showAddPost") && child.showAddPost === false)
                    ? (
                    <div />
                ) : (
                    <div onClick={() => this.listenerAccountingPostAdd(child, parent)}>
                        <img
                            src={process.env.PUBLIC_URL + "/images/icons/add-icon.svg"}
                            className="form-company-remove"
                            alt=""
                        />
                    </div>
                )}
                </div>
        );
    };

    renderDataLabelLast = child => {
        return (
            <div className="form-company-header-tr">
                <div className="form-company-body-tr2">
                    <div>
                        <input
                            id={"input-" + child.pk + "-bsNumber"}
                            name={"input-" + child.pk + "-bsNumber"}
                            type="text"
                            className="form-control form-control-75"
                            value={child.bsNumber}
                            onChange={e => this.listenerChangeData(child.pk, "bsNumber", e)}
                            disabled
                        />
                    </div>
                    <div>
                        <input
                            id={"input-" + child.pk + "-name"}
                            name={"input-" + child.pk + "-name"}
                            type="text"
                            className="form-control"
                            value={this.state.data[child.pk].name}
                            onChange={e => this.listenerChangeData(child.pk, "name", e)}
                        />
                    </div>
                </div>
                <div>
                    <input
                        id={"input-" + child.pk + "-value"}
                        name={"input-" + child.pk + "value"}
                        value={this.state.data[child.pk].value}
                        onChange={e => this.listenerChangeData(child.pk, "value", e)}
                        type="text"
                        className="form-control form-control-150 input-right-align"
                    />
                </div>
                <div onClick={() => this.listenerAccountingPostDelete(child.pk)}>
                    <img
                        src={process.env.PUBLIC_URL + "/images/icons/remove-icon.svg"}
                        className="form-company-remove"
                        id={"img-remove-"}
                        alt=""
                    />
                </div>
            </div>
        );
    };

    listenerAccountingPostAdd(child, parent) {
        let newState = this.accountingPostAdd(this.state, child, parent);
        if(newState != null) {
            this.setState(newState);
        }
        this.toggleSlide(parent.id);
    }

    listenerAccountingPostDelete(id) {
        let newState = this.accountingPostDelete(this.state, id);
        if (newState != null) {
            this.setState(newState);
        }
    }


    listenerChangeHeaderDate(key, e) {
        let value = e;
        let newState = JSON.parse(JSON.stringify(this.state.headers));

        let result = value.toLocaleDateString("sv-SE", { // you can skip the first argument
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });

        newState[key] = result;

        this.setState({
            headers: newState
        });
    }


    listenerChangeHeader(key, e) {
        let value = e.target.value;
        let newState = JSON.parse(JSON.stringify(this.state.headers));
        newState[key] = value;
        this.setState({
            headers: newState
        });
    }

    listenerChangeData(key, type, e) {
        let value = e.target.value;
        let newState = JSON.parse(JSON.stringify(this.state));
        if (type === "value") {
            newState.data[key].value = parseAccountingPostValue(value);
        } else if (type === "name") {
            newState.data[key].name = value;
        } else if (type === "bsNumber") {
            newState.data[key].bsNumber = value;
        }
        this.setState(newState);
    }

    accountingPostAdd(state, child, parent) {

        state = Object.assign({}, state);
        let minValue = child.interval[0];
        let maxValue = child.interval[1];

        while (true) {
            let bsNumberString = window.prompt(
                "Bokslutsnummer [" + minValue + " - " + maxValue + "]: "
            );

            if (!bsNumberString) {
                break;
            } else {

                let bsNumber = parseInt(bsNumberString);

                if (!isBetween(bsNumber, minValue, maxValue)) {
                    alert("Ange ett giltligt bokslutsnummer.");
                } else {

                    let bsNumberExists = false;

                    for (let key in state.data) {
                        if (state.data.hasOwnProperty(key) && state.data[key].bsNumber === bsNumberString) {
                            bsNumberExists = true;
                        }
                    }

                    if (bsNumberExists) {
                        alert("Det här bokslutsnumret existerar redan");
                    } else {
                        state.data[bsNumber] = {
                            pk: bsNumber,
                            bsNumber: bsNumber.toString(),
                            name: "",
                            value: 0,
                            flag: 1
                        };

                        return state;
                    }
                }
            }
        }

        return null;

    };

    accountingPostDelete(state, pk) {

        state = Object.assign({}, state);

        let result = window.confirm(
            "Är du säker på att du vill ta bort denna post?"
        );

        if (result) {

            pk = parseInt(pk);

            if (state.data[pk]["flag"] === 1) {
                delete state.data[pk];
            } else {
                state.data[pk]["flag"] = -1;
                delete state.data[pk]["name"];
                delete state.data[pk]["bsNumber"];
                delete state.data[pk]["value"];
            }

            return state;

        }

        return null;

    }


}

export default Accounting;
